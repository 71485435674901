import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  tableHead: {
    font: "normal normal 600 15px/20px Nunito Sans",
    letterSpacing: "0px",
    color: "#212121",
    borderRightWidth: 1,
    borderColor: "#E8E8E8",
    borderTop: "1px solid #E8E8E8",
    padding: "8px !important",
  },
  container: {
    maxHeight: 440,
  },
  tableContent: {
    letterSpacing: "0px",
    // color: "#212121",
    borderColor: "#E8E8E8",
    borderRightWidth: 1,
    padding: "4px !important",
  },
  buttonOutlined: {
    // border: "1px solid #333333",
    // borderRadius: "20px",
    // opacity: 1,
    width: "200px",
    height: "48px",
  },
  textfield: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#5CB593",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5CB593",
      },
    },
  },
}));
