import React, { useState } from "react";
import {
  OutlinedInput,
  InputAdornment,
  FormControl,
  FormHelperText,
  // Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Checkbox,
  Grid,
  Typography,
  Link,
  Button,
  CircularProgress,
  Box,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { ColorButton, ColorOutlinedButton } from "./PaletteComponent";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OtpInput from "react-otp-input";
// import CircleIcon from "@material-ui/icons/Circle";
import { Autocomplete } from "@mui/material";
// import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import OtpInput from "react-otp-input";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  paper: { minWidth: "35vw", minHeight: "8vh" },
  content: {
    font: "normal normal 500 26px/34px Nunito Sans",

    textAlign: "left",
    marginTop: "10px",
  },
  textField: {
    // background: "#EFEFEF 0% 0% no-repeat padding-box",
    // border: "1px solid #DEDEDF",
    borderRadius: "8px !important",
    opacity: 1,
    font: "normal normal medium 16px/20px poppins !important",
    letterSpacing: "0px !important",

    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5CB593 !important",
      },
    },
  },
  root: {
    "&$focused $notchedOutline": {
      borderColor: "#5CB593 !important",
    },
  },
  notchedOutline: {},
  focused: {},

  label: {
    textAlign: "left",
    font: "normal normal normal 14px/20px Poppins",
    letterSpacing: "0px",
    color: "#707070",
    // paddingBottom: 8,
  },

  linkTextStyle: {
    color: "blue",
  },
  dialogAction: {
    padding: "15px 20px 20px 40px",
  },
  outlineButton: {
    marginRight: "5px",
    width: "200px",
    height: "48px",
  },
  colorButton: {
    width: "200px",
    height: "48px",
  },
  circular: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: "60vh",
    fontSize: "1.2em",
  },
});

export function CustomTextField(props) {
  const classes = useStyles();
  const {
    value,
    onChange,
    placeholder,
    type,
    label,
    onBlur,
    error,
    disableFutureDates,
    disabled,
    name,
    read,
    onKeyPress,
  } = props;
  return (
    <FormControl fullWidth={true}>
      <FormHelperText
        className={classes.label}
        style={{ font: "normal normal 500 13px/20px poppins" }}
      >
        {label}
      </FormHelperText>
      <OutlinedInput
        classes={{
          root: classes.root,
          focused: classes.focused,
          notchedOutline: classes.notchedOutline,
        }}
        value={value}
        type={type}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        onBlur={onBlur}
        disabled={disabled}
        error={error}
        inputProps={{
          readonly: read ? read : false,
        }}
        onKeyPress={onKeyPress}
        endAdornment={
          <InputAdornment position="end">
            {type == "text" || type == "number" ? (
              <EditIcon fontSize="medium" style={{ fill: "grey" }} />
            ) : null}
            {type == "select" ? <ExpandMoreIcon fontSize="medium" /> : null}
            {/* {type == 'datetime-local' ? (
              <CalendarMonthIcon fontSize="medium" />
            ) : null} */}
            {type === "file" ? <CloudUploadIcon fontSize="medium" /> : null}
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

export function CustomTextArea(props) {
  const classes = useStyles();
  const {
    value,
    onChange,
    placeholder,
    type,
    label,
    onBlur,
    error,
    disableFutureDates,
    disabled,
    name,
    read,
    rows,
  } = props;
  return (
    <FormControl fullWidth={true}>
      <FormHelperText
        className={classes.label}
        style={{ font: "normal normal 500 13px/20px poppins" }}
      >
        {label}
      </FormHelperText>
      <OutlinedInput
        classes={{
          root: classes.root,
          focused: classes.focused,
          notchedOutline: classes.notchedOutline,
        }}
        value={value}
        type={type}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        onBlur={onBlur}
        disabled={disabled}
        error={error}
        multiline={true}
        inputProps={{
          readonly: read ? read : false,
        }}
        rows={rows}
      />
    </FormControl>
  );
}

export function CustomSelect(props) {
  const classes = useStyles();

  const {
    id,
    options,
    handleSelectChange,
    label,
    placeholder,
    name,
    onBlur,
    error,
    value,
    getOptionLabel,
    disabled,
    onKeyPress,
    disableClearable,
  } = props;
  // console.log(options, 'options');
  return (
    <>
      <FormControl fullWidth={true}>
        <FormHelperText
          className={classes.label}
          style={{ font: "normal normal 500 13px/20px poppins" }}
        >
          {label}
        </FormHelperText>
        <Autocomplete
          options={options || []}
          name={name}
          popupIcon={<ExpandMoreIcon fontSize="large" />}
          onBlur={onBlur}
          autoHighlight
          id={id}
          value={value}
          getOptionLabel={getOptionLabel}
          onChange={(event, value) => handleSelectChange(event, value)}
          fullWidth={true}
          onKeyPress={onKeyPress}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              variant="outlined"
            />
          )}
          className={classes.textField}
          disabled={disabled}
          disableClearable={disableClearable ? false : true}
        ></Autocomplete>
      </FormControl>
    </>
  );
}

export function CustomCheckbox(props) {
  const { checked, onChange } = props;
  return (
    <Grid item>
      <Checkbox
        checked={checked}
        onChange={onChange}
        // icon={<CircleIcon />}
        checkedIcon={<CheckCircleIcon />}
      />
    </Grid>
  );
}

// export function CustomDateTimePicker(props) {
//   const [open, setOpen] = useState(false);
//   const {
//     value,
//     handleChange,
//     disablePastDates,
//     label,
//     placeholder,
//     error,
//     onBlur,
//   } = props;

//   function handleDateChange(e) {
//     // console.log(e, 'value');
//     let unix_seconds = new Date(e).getTime() / 1000;
//     // console.log(unix_seconds, ' unix_seconds');
//     handleChange(unix_seconds);
//   }
//   return (
//     <LocalizationProvider dateAdapter={AdapterDateFns}>
//       <FormControl fullWidth={true}>
//         <FormHelperText
//           // className={classes.label}
//           style={{ font: "normal normal 500 13px/20px poppins" }}
//         >
//           {label}
//         </FormHelperText>
//         <DateTimePicker
//           inputProps={{
//             style: {
//               background: "#EFEFEF 0% 0% no-repeat padding-box",
//               border: "1px solid #DEDEDF",
//             },
//             placeholder: placeholder,
//           }}
//           placeholderText="Please select a date"
//           open={open}
//           onOpen={() => setOpen(true)}
//           onClose={() => setOpen(false)}
//           value={value ? value * 1000 : null}
//           onChange={handleDateChange}
//           renderInput={(params) => (
//             <TextField
//               fullWidth
//               {...params}
//               onClick={(e) => setOpen(true)}
//               error={error}
//               onBlur={onBlur}
//             />
//           )}
//         />
//       </FormControl>
//     </LocalizationProvider>
//   );
// }

export function CustomDialogBox(props) {
  const classes = useStyles();
  const {
    open,
    content,
    negativeResponseHandler,
    negativeResponseButtonText,
    positiveResponseHandle,
    positiveResponseButtonText,
    largeSize,
    Loading,
  } = props;
  return (
    <Dialog
      open={open}
      maxWidth={largeSize ? "md" : "sm"}
      aria-labelledby="max-width-dialog-title"
    >
      <Grid container justifyContent="flex-end">
        <Grid item xs={1} style={{ marginRight: "20px" }}>
          <Button onClick={negativeResponseHandler}>
            <CloseIcon
              style={{
                fill: "#707070",
              }}
            />
          </Button>
        </Grid>
      </Grid>
      <DialogContent className={classes.content}>{content}</DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="flex-end"
          style={{ padding: "16px" }}
          spacing={3}
        >
          <Grid item xs={largeSize ? 3 : 4}>
            <ColorOutlinedButton
              onClick={negativeResponseHandler}
              style={largeSize ? { padding: "6px 20px" } : null}
            >
              {negativeResponseButtonText}
            </ColorOutlinedButton>
          </Grid>
          <Grid item xs={largeSize ? 3 : 4}>
            {Loading ? (
              <CircularProgress style={{ color: "#57B793" }} />
            ) : (
              <ColorButton
                onClick={positiveResponseHandle}
                style={largeSize ? { padding: "6px 20px" } : null}
              >
                {positiveResponseButtonText}
              </ColorButton>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

// export function CustomValidate(props) {
//   const classes = useStyles();
//   const {
//     open,
//     otpInput,
//     otpPhoneNumber,
//     handleOnChange,
//     handleResendOTP,
//     handleCancel,
//     handleValidateClick,
//   } = props;
//   return (
//     <Dialog open={open}>
//       <DialogContent>
//         <Grid
//           container
//           justifyContent="center"
//           align="center"
//           style={{ padding: "1vw" }}
//           spacing={4}
//         >
//           <Grid item xs={12}>
//             <Typography
//               style={{
//                 font: "normal normal bold 24px/32px Poppins",
//               }}
//             >
//               Please enter the OTP to authenticate
//             </Typography>
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             style={{ font: "normal normal 600 16px/20px Poppins" }}
//           >
//             <Typography>
//               `An OTP has been sent to *******
//               {otpPhoneNumber.slice(7)}`
//             </Typography>
//           </Grid>
//           <Grid item>
//             <OtpInput
//               value={otpInput}
//               onChange={handleOnChange}
//               numInputs={6}
//               separator={<span> </span>}
//               inputStyle={{
//                 margin: "10px",
//                 width: "34px",
//                 height: "50px",
//               }}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Link
//               onClick={handleResendOTP}
//               style={{
//                 color: " #333333",
//                 textDecoration: "none ",
//                 cursor: "pointer",
//               }}
//             >
//               <Typography variant="subtitle1" className={classes.linkTextStyle}>
//                 Resend One-Time Password
//               </Typography>
//             </Link>
//           </Grid>

//           <Grid item xs={12}>
//             <Grid
//               container
//               display="flex-end"
//               justifyContent="space-between"
//               spacing={2}
//             >
//               <Grid item>
//                 <ColorButton
//                   style={{
//                     minWidth: "180px",
//                   }}
//                   onClick={handleCancel}
//                 >
//                   <Typography variant="subtitle1">Cancel</Typography>
//                 </ColorButton>
//               </Grid>
//               <Grid item>
//                 <ColorButton
//                   style={{
//                     minWidth: "180px",
//                   }}
//                   onClick={() => handleValidateClick()}
//                 >
//                   <Typography variant="subtitle1">Verify</Typography>
//                 </ColorButton>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </DialogContent>
//     </Dialog>
//   );
// }
export function ValidateButton(props) {
  const { onClick, title, disabled } = props;
  return (
    <button
      style={{
        boxShadow: "0px 0px 20px #00000029",
        border: "1px solid #777777",
        borderRadius: "6px",
        opacity: 1,
        padding: "0px 20px 0px 20px",
        width: "100%",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
}

export function CustomCircularProgress() {
  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid className={classes.circular} item xs={12}>
        <CircularProgress style={{ color: "#57B793" }} />
      </Grid>
    </Grid>
  );
}

export function NoData() {
  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid className={classes.circular} item xs={12}>
        <Typography variant="h5">
          {" "}
          No Details found!.Please upload the details
        </Typography>{" "}
      </Grid>
    </Grid>
  );
}

export function LoginTextField(props) {
  const { id, value, onChange, placeholder, type, inputProps } = props;
  return (
    <OutlinedInput
      id={id}
      value={value}
      onChange={onChange}
      placeholde={placeholder}
      type={type}
      inputProps={inputProps}
      fullWidth
      startAdornment={
        type == "email" ? (
          <InputAdornment position="start">
            <PersonIcon />
          </InputAdornment>
        ) : type == "password" ? (
          <InputAdornment position="start">
            <LockIcon />{" "}
          </InputAdornment>
        ) : null
      }
      style={{ backgroundColor: "white" }}
    ></OutlinedInput>
  );
}

export function CustomValidate(props) {
  const classes = useStyles();
  const {
    open,
    otpInput,
    otpPhoneNumber,
    handleOnChange,
    handleResendOTP,
    handleCancel,
    handleValidateClick,
    isOtpBeingSent,
    isResendOtpBeingSent,
    isResendOtpSent,
    wrongOtp,
    handleReject,
  } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="max-width-dialog-title"
      maxWidth="md"
      maxHeight="md"
    >
      <DialogContent>
        {isOtpBeingSent ? (
          <Grid item xs={12}>
            <Box sx={{ justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid container justifyContent="center" align="center" spacing={4}>
            <Grid item xs={9}></Grid>
            <Grid item xs={3}>
              <Button style={{ float: "right" }} onClick={handleCancel}>
                <CloseIcon style={{ fill: "#212121" }} />
              </Button>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "3vw" }}>
              <Typography
                style={{
                  font: "normal normal bold 24px/32px Nunito Sans",
                }}
              >
                Enter the OTP to approve/reject the campaign
              </Typography>
            </Grid>
            {/* <Grid
              item
              xs={12}
              style={{ font: "normal normal 600 16px/20px Poppins" }}
            >
              <Typography></Typography>
            </Grid> */}
            <Grid item style={{ paddingBottom: "3vh" }}>
              <OtpInput
                value={otpInput}
                onChange={handleOnChange}
                numInputs={6}
                separator={<span> </span>}
                inputStyle={{
                  margin: "10px",
                  width: "40px",
                  height: "50px",
                  backgroundColor: "#DEDEDF",
                  border: "3px solid #DEDEDF",
                }}
                focusStyle={{
                  outline: "none",
                  border: "2px solid #05DEBA",
                  // borderRadius: "8px",
                }}
              />
              {wrongOtp && (
                <span
                  style={{
                    color: "#DC6556",
                    font: "normal normal medium 13px/20px Poppins;",
                  }}
                >
                  incorrect Otp
                </span>
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {isResendOtpBeingSent ? (
                    <Grid item>
                      <Box sx={{ justifyContent: "center" }}>
                        <CircularProgress />
                      </Box>
                    </Grid>
                  ) : !isResendOtpSent ? (
                    <Link
                      onClick={handleResendOTP}
                      style={{
                        color: "#333333 !important",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        // className={classes.linkTextStyle}
                        style={{
                          color: "#333333",
                          font: "normal normal bold 15px/12px Nunito Sans",
                          marginTop: "22px",
                        }}
                      >
                        Resend One-Time Password
                      </Typography>
                    </Link>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#333333",
                        font: "normal normal bold 15px/12px Nunito Sans",
                        marginTop: "22px",
                      }}
                    >
                      OTP Resent Successfully. Please enter new OTP.
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Grid container display="flex-end" spacing={2}>
                    <Grid item xs={6}>
                      <ColorOutlinedButton
                        onClick={handleReject}
                        disabled={otpInput == null}
                      >
                        <Typography variant="subtitle1">Reject</Typography>
                      </ColorOutlinedButton>
                    </Grid>
                    <Grid item xs={6}>
                      <ColorButton
                        onClick={() => handleValidateClick(otpInput)}
                        disabled={otpInput == null}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            paddingLeft: "35px",
                            paddingRight: "36px",
                          }}
                        >
                          Approve
                        </Typography>
                      </ColorButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
