import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { CustomSelect, CustomTextField } from "../../hoc/CustomComponents";
import {
  gql,
  useLazyQuery,
  useApolloClient,
  useMutation,
  useQuery,
  Query,
} from "@apollo/client";

const priorityType = ["HIGH", "MEDIUM", "LOW", "NONE"];
// const categoryType = ["VIN", "KYC"];
const categoryType = ["Incorrect Location and other info", "Recharge/ Activation related", "AIS 140 Activation/ Certificate", "Hardware Not working", "Login Credentials/Account Management", "KYC issues", "Others"];
export default function AddTicket({
  open,
  handleClose,
  category,
  oemVin,
  vin,
  customerName,
  customerEmail,
  subject,
  issue,
  attached_filename_url,
  attached_screenshot_url,
  priority,
  setCategory,
  setVin,
  setcustomerName,
  setcustomerEmail,
  setSubject,
  setIssue,
  setAttachedfilenameurl,
  setAttachedscreenshoturl,
  setpriority,
  onUploadFile,
  onUploadScreenshot,
  addTickets,
  vinLoading,
  snackbar,

}) {
  const classes = useStyles();

  /**
   * @summary This sets the value of Category
   */
  const handleCategory = (e, value) => {
    if (!value) {
      setCategory(null);
    } else {
      setCategory(value);
    }
  };

  /**
   * @summary This sets the value of Vin
   */
  const handleVin = (e, value) => {
    if (!value) {
      setVin(null);
    } else {
      setVin(value.vin);
    }
  };

  /**
   * @summary This sets the value of customer name
   */
  const handleCustomerName = (e) => {
    setcustomerName(e);
  };

  /**
   * @summary This sets the value of customer email
   */
  const handleCustomerEmail = (e) => {
    setcustomerEmail(e);
  };

  /**
   * @summary This sets the value of customer email
   */
  const handleSubject = (e) => {
    setSubject(e);
  };

  /**
   * @summary This sets the value of customer email
   */
  const handleIssue = (e) => {
    setIssue(e);
  };

  /**
   * @summary This sets the value of customer email
   */
  const handlepriority = (e, priority) => {
    setpriority(priority);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogContent>
          <form>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9}>
                    <Typography variant="h4">Raise New Ticket</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      style={{ float: "right" }}
                      onClick={() => handleClose()}
                    >
                      <CloseIcon />
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <CustomSelect
                      label="Category*"
                      placeholder="Select Category"
                      options={categoryType}
                      handleSelectChange={handleCategory}
                      getOptionLabel={(option) => option}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {vinLoading ? (
                      <Grid item style={{ margin: "25px" }}>
                        <CircularProgress style={{ color: "#57B793" }} />
                      </Grid>
                    ) : (
                      <CustomSelect
                        id="oemVin"
                        options={oemVin}
                        label="VIN*"
                        getOptionLabel={(option) => option.vin}
                        handleSelectChange={handleVin}
                        style={{ width: 400 }}
                        placeholder="Please select Vin"
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      label="Customer Name*"
                      placeholder="Customer Name"
                      onChange={(e) => handleCustomerName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      label="Customer Email*"
                      placeholder="Customer Email"
                      onChange={(e) => handleCustomerEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      label="Subject*"
                      placeholder="Subject"
                      onChange={(e) => handleSubject(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      label="Issue*"
                      placeholder="Issue"
                      onChange={(e) => handleIssue(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      variant="contained"
                      placeholder="Upload File"
                      label="Upload File"
                      // disabled={true}
                      type="file"
                      onChange={onUploadFile}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      variant="contained"
                      placeholder="Upload Screenshot"
                      label="Upload Screenshot"
                      // disabled={true}
                      type="file"
                      onChange={onUploadScreenshot}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSelect
                      label="Priority*"
                      placeholder="Select Priority"
                      options={priorityType}
                      handleSelectChange={handlepriority}
                      getOptionLabel={(option) => option}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ paddingTop: "16px" }}
                >
                  <Grid
                    item
                    style={{ paddingRight: "24px", paddingBottom: "15px" }}
                  >
                    <ColorOutlinedButton
                      className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={handleClose}
                    >
                      Cancel
                    </ColorOutlinedButton>
                  </Grid>
                  <Grid item>
                    <ColorButton
                      className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={addTickets}
                      disabled={
                        customerName == null ||
                        customerEmail == null ||
                        subject == null ||
                        issue == null ||
                        priority == null ||
                        customerName == "" ||
                        customerEmail == "" ||
                        subject == "" ||
                        issue == "" ||
                        priority == "" || snackbar ||
                        category == null || vin == null
                      }
                    >
                      Submit
                    </ColorButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
