import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";

import CloseIcon from "@mui/icons-material/Close";

// styles
import useStyles from "./styles";
import { CustomTextField } from "../../hoc/CustomComponents";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";

export default function CertificateUploadDialog({
  isModelOpen,
  handleModelClose,
  certificateId,
  onUpload,
  // onUploadCommon,
  onSubmit,
  // Vahaan,
  // Common,
  setVahaan,
  setCommon,
  snackbarOpen,
  fileType,
  fileUpload,
}) {
  const [isUploading, setIsUploading] = useState(false);
  const classes = useStyles();
  const handleClose = () => {
    handleModelClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isModelOpen}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={10}>
                    <Typography variant="h4">Upload Certificate</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Button style={{ float: "right" }} onClick={handleClose}>
                      <CloseIcon
                        style={{
                          fill: "#2b2924",
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {isUploading ? (
                      <CircularProgress
                        size={20}
                        style={{ color: "#57B793" }}
                      />
                    ) : (
                      <CustomTextField
                        variant="contained"
                        placeholder={`Upload ${
                          fileType == "common" ? "Common Layer" : "Fitment"
                        } Certificate`}
                        label={`Upload ${
                          fileType == "common" ? "Common Layer" : "Fitment"
                        } Certificate*`}
                        type="file"
                        onChange={onUpload}
                      />
                    )}
                  </Grid>

                  {/* <Grid item xs={6}>
                    {isUploading ? (
                      <CircularProgress
                        size={20}
                        style={{ color: "#57B793" }}
                      />
                    ) : (
                      <CustomTextField
                        variant="contained"
                        placeholder="Upload Common Layer Certificate"
                        label="Upload Common Layer Certificate*"
                        type="file"
                        onChange={onUploadCommon}
                      />
                    )}
                  </Grid> */}
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ paddingTop: "20px" }}
                >
                  <Grid item style={{ paddingRight: "24px" }}>
                    <ColorOutlinedButton
                      className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={handleClose}
                    >
                      Cancel
                    </ColorOutlinedButton>
                  </Grid>
                  <Grid item>
                    <ColorButton
                      className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={onSubmit}
                      disabled={fileUpload == null}
                    >
                      Upload File
                    </ColorButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
