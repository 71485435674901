import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";

import CloseIcon from "@mui/icons-material/Close";

// styles
import useStyles from "./styles";
import { CustomTextField } from "../../hoc/CustomComponents";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";

const getOEMVendorECUs = gql`
  query($oemId: Int!) {
    getOEMVendorECUs(oemId: $oemId) {
      id
      ecuName
      ecuDomain
      bankType
      ownerId
    }
  }
`;

export default function SimBulkUploadDialog({
  oemId,
  upload,
  isModelOpen,
  handleSimModelClose,
  setEcuId,
  ecuId,
  handleAddSimClick,
  simBulkUploadResponse,
  snackbarOpen,
  excelLoading,
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [vendorEcus, setVendorEcus] = useState([]);

  let ecuArray = [];
  const classes = useStyles();

  useEffect(() => {
    getOEMVendorECUsData({
      variables: {
        oemId: 1,
      },
    });
  }, []);

  const [getOEMVendorECUsData, { loading, error, data }] = useLazyQuery(
    getOEMVendorECUs,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        result.getOEMVendorECUs.map((item) => {
          ecuArray.push({ id: item.id, ecuName: item.ecuName });
          setVendorEcus(ecuArray);
        });
      },
    }
  );

  const handleClose = () => {
    handleSimModelClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleEcuSelect = (event, value) => {
    if (!value) {
      setEcuId(null);
    } else {
      setEcuId(value.id);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isModelOpen}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9}>
                    <Typography variant="h4">Add New Sim</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button style={{ float: "right" }} onClick={handleClose}>
                      <CloseIcon
                        style={{
                          fill: "#2b2924",
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Grid container>
                  {/* <Grid item xs={6}>
                    <CustomSelect
                      id="vendorEcus"
                      options={vendorEcus}
                      label="ECU*"
                      getOptionLabel={(option) => option.ecuName}
                      handleSelectChange={handleEcuSelect}
                      style={{ width: 400 }}
                      placeholder="Please select ECU"
                      variant="outlined"
                    />
                  </Grid> */}

                  <Grid item xs={10}>
                    {isUploading ? (
                      <CircularProgress
                        size={20}
                        style={{ color: "#57B793" }}
                      />
                    ) : (
                      <CustomTextField
                        variant="contained"
                        placeholder="Upload template"
                        label="Upload File*"
                        type="file"
                        onChange={upload}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ paddingTop: "20px" }}
                  spacing={3}
                >
                  <Grid item xs={4}>
                    <ColorOutlinedButton
                      // className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={handleClose}
                    >
                      Cancel
                    </ColorOutlinedButton>
                  </Grid>
                  <Grid item xs={4}>
                    {excelLoading ? (
                      <CircularProgress style={{ color: "#57B793" }} />
                    ) : (
                      <ColorButton
                        // className={classes.buttonOutlined}
                        variant="outlined"
                        onClick={handleAddSimClick}
                        disabled={
                          simBulkUploadResponse == null ||
                          simBulkUploadResponse == "" ||
                          snackbarOpen
                        }
                      >
                        Add Sim
                      </ColorButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
