import React, { Component } from "react";
import AlertDialog from "../Dialog/DialogAlert";
import {
  CircularProgress,
  Button,
  LinearProgress,
  Grid,
  Typography,
  Snackbar,
} from "@mui/material";

const diff = Math.random() * 10;
const diff2 = Math.random() * 10;
export const SnackbarContext = React.createContext();

export class SnackbarContextProvider extends Component {
  state = {
    open: false,
    message: "Uploading",
    isUploaded: false,
    isNetworkError: false,
    isDialogVisible: false,
    excelData: null,
    excelError: "",
    columns: null,
    title: "",
  };

  setSnackbar = (open) => {
    this.setState({ open });
  };

  setMessage = (message) => {
    console.log("Message----", message);
    this.setState({ message });
  };

  setUpload = (isUploaded) => {
    console.log("Is_Uploaded----", isUploaded);
    this.setState({ isUploaded });
  };

  setNetworkError = (isNetworkError) => {
    console.log("isNetworkError----", isNetworkError);
    this.setState({ isNetworkError });
  };

  setExcelError = (excelError) => {
    this.setState({ excelError });
  };

  setExcelData = (excelData) => {
    this.setState({ excelData });
  };

  setColumns = (columns) => {
    this.setState({ columns });
  };

  setTitle = (title) => {
    this.setState({ title });
  };

  closeDialog = (isDialogVisible) => this.setState({ isDialogVisible });

  render() {
    const { children } = this.props;
    const {
      message,
      open,
      isUploaded,
      isNetworkError,
      excelError,
      excelData,
      isDialogVisible,
      columns,
      title,
    } = this.state;

    return (
      <SnackbarContext.Provider
        value={{
          message: message,
          open: open,
          isDialogVisible: isDialogVisible,
          isUploaded: isUploaded,
          isNetworkError: isNetworkError,
          setMessage: this.setMessage,
          setUpload: this.setUpload,
          setNetworkError: this.setNetworkError,
          setSnackbar: this.setSnackbar,
          closeDialog: this.closeDialog,
          setExcelError: this.setExcelError,
          setExcelData: this.setExcelData,
          setColumns: this.setColumns,
          setTitle: this.setTitle,
        }}
      >
        {open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
            message={message}
            action={
              isNetworkError ? (
                <Button
                  style={{ float: "right", color: "white" }}
                  color="inherit"
                  variant="text"
                  onClick={() => {
                    this.setSnackbar(false);
                  }}
                >
                  Close
                </Button>
              ) : isUploaded ? (
                <Button
                  style={{ float: "right", color: "white" }}
                  color="inherit"
                  variant="text"
                  onClick={() => {
                    this.closeDialog(true);
                    this.setSnackbar(false);
                  }}
                >
                  {columns ? "View" : "Done"}
                </Button>
              ) : (
                <CircularProgress style={{ color: "#57B793" }} size={25} />
              )
            }
          />
        )}
        {/* {open && (
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              right: "25px",
              width: "30%",
              zIndex: "1400",
              border: "2px solid #F3F3F3",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                padding: "5px",
                backgroundColor: "#212121",
                boxShadow: "1px 1px 1px 1px #F3F3F3",
                borderRadius: "10px",
                color: "white",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="body1">{message}</Typography>
              </Grid>
              <Grid item xs={12}>
                {!isUploaded ? (
                  <CircularProgress />
                ) : (
                  <Button
                    style={{ float: "right", color: "white" }}
                    variant="text"
                    color="inherit"
                    onClick={() => this.closeDialog(true)}
                  >
                    Done
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        )} */}
        {isDialogVisible && (
          <AlertDialog
            isDialogOpened={isDialogVisible}
            handleCloseDialog={() => {
              this.setState({ open: false });
              this.closeDialog(false);
            }}
            errorData={excelError}
            excelData={excelData}
            columns={columns}
            title={title}
          />
        )}
        {children}
      </SnackbarContext.Provider>
    );
  }
}

export const SnackbarConsumer = SnackbarContext.Consumer;
