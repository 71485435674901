import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink(
  {
    uri: process.env.REACT_APP_AQUILA_UPLOAD,
  },
  onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
  })
);

export const minioClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});
