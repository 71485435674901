import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  CircularProgress,
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Button,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";

// styles
import useStyles from "./styles";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";
import { CustomTextField, CustomSelect } from "../../hoc/CustomComponents";

const addTcuGroup = gql`
  mutation(
    $id: Int
    $oemId: Int!
    $tcuId: Int!
    $groupName: String!
    $tcuInventoryId: String!
  ) {
    addTcuGroup(
      id: $id
      oemId: $oemId
      tcuId: $tcuId
      groupName: $groupName
      tcuInventoryId: $tcuInventoryId
    )
  }
`;

const getTcu = gql`
  query($oemId: Int!) {
    getOEMVendorTCUs(oemId: $oemId) {
      id
      vendorId
      tcuName
      ownerId
      tcu_code
    }
  }
`;

const getTcuGroupData = gql`
  query($oemId: Int!, $id: Int!) {
    getTcuGroup(oemId: $oemId, id: $id) {
      id
      oemId {
        id
        oemName
        oemCode
        oemEmail
        phoneNumber
      }
      tcuId {
        id
        vendorId
        vendorCode
        tcuName
        ownerId
        tcu_code
      }
      groupName
      ownerId
    }
  }
`;

const getTcuInventory = gql`
  query(
    $OEMId: Int!
    $limit: Int!
    $offset: Int!
    $status: String!
    $searchInput: SearchInput
  ) {
    getTCUInventory(
      OEMId: $OEMId
      limit: $limit
      offset: $offset
      status: $status
      search: $searchInput
    ) {
      totalCount
      rows {
        id
        oem {
          id
          oemName
          oemCode
          oemEmail
          phoneNumber
          logo
        }
        tcu {
          id
          vendorId
          vendorCode
          tcuName
          ownerId
          tcu_code
        }
        imei
        serialNumber
        owner
      }
    }
  }
`;

export default function GroupMaxWidthDialog({
  isDialogOpened,
  handleCloseDialog,
  oemId,
  editId,
}) {
  console.log("oemId---", oemId);
  //const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [checked, setChecked] = useState(false);
  const [tcu, setTcu] = useState(1);
  const [tcuInventoryData, setTcuInventoryData] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [tcuType, setTcuType] = useState();
  const [formSubmit, setFormSubmit] = useState(false);
  const [message, setMessage] = useState();
  const [alertType, setAlertType] = useState();
  const [selected, setSelected] = useState([]);
  const [deviceCodeModels, setDeviceCodeModels] = useState();
  const [tcuName, setTcuName] = useState("");
  const classes = useStyles();

  const [getTcuGroup, { loading, error, data: tData }] = useLazyQuery(
    getTcuGroupData,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        if (result) {
          result.getTcuGroup.map((val) => {
            setGroupName(val.groupName);
            // setTcuName(JSON.stringify({ id: val.tcuId.id, tcuName: val.tcuId.tcuName }))
            // setTcu(val.tcuId.id);
          });
        }
      },
    }
  );

  const [
    getTCU,
    { loading: tcuLoading, error: tcuError, data: tcuData },
  ] = useLazyQuery(getTcu, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      let allModels = result.getOEMVendorTCUs.map((item) => {
        return { id: item.id, tcuName: item.tcuName };
      });

      setDeviceCodeModels(allModels);
    },
  });

  const [
    addTcuGroupData,
    { loading: tcuGroupLoading, error: tcuGroupError, data: tcuGroupData },
  ] = useMutation(addTcuGroup, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      setMaxWidth("sm");
      if (networkError) {
        setAlertType("error");
        setMessage(`[Network error]: ${networkError}`);
        console.log(`[Network error]: ${networkError}`);
      }

      if (graphQLErrors) {
        setAlertType("error");
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(`[graphQLErrors error]: ${networkError}`);
          setMessage(`Message: ${message}`);
        });
      }
    },
    onCompleted: (result) => {
      console.log("result---", result);
      if (result.addTcuGroup) {
        setAlertType("success");
        setMessage(result.addTcuGroup);
      }
    },
  });

  const [getTcuInventoryData, { loading: invLoading, ...data }] = useLazyQuery(
    getTcuInventory,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data) {
          data.getTCUInventory.rows.checked = false;
          setTcuInventoryData(data.getTCUInventory);
        }
      },
    }
  );

  const handleClose = () => {
    //setIsOpen(false);
    handleCloseDialog(false);
  };

  const handleTcuData = (id, select) => {
    if (select) {
      setTcu(select.id);
      setTcuName(select);
      getTcuInventoryData({
        variables: {
          OEMId: oemId,
          limit: rowsPerPage,
          offset: page,
          status: "1",
          searchInput: {
            tcuId: select.id,
          },
        },
      });
    } else {
      setTcu("");
      setTcuName("");
    }
  };

  const handleSearch = (event) => {
    //setTcu(event.target.value);
    getTcuInventoryData({
      variables: {
        OEMId: oemId,
        limit: rowsPerPage,
        offset: page,
        status: "1",
        searchInput: {
          imei: event.target.value,
          tcuId: tcu,
        },
      },
    });
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getTcuInventoryData({
      variables: {
        OEMId: oemId,
        limit: rowsPerPage,
        offset: page,
        status: "1",
        searchInput: {
          tcuId: tcu,
        },
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getTcuInventoryData({
      variables: {
        OEMId: oemId,
        limit: rowsPerPage,
        offset: page,
        status: 1,
        searchInput: {
          tcuId: tcu,
        },
      },
    });
  };

  console.log("groupName---", groupName);
  const handleSubmit = () => {
    setFormSubmit(true);
    let selectedArray = tcuInventoryData.rows.filter((ele) => ele.selected);

    let selectedIdArray = selectedArray.map((ele) => ele.id);

    let ids = selectedIdArray.join();
    console.log(groupName, ids);
    console.log("type----", tcu);
    if (editId) {
      addTcuGroupData({
        variables: {
          id: editId,
          oemId: oemId,
          tcuId: tcu,
          groupName: groupName,
          tcuInventoryId: ids,
        },
      });
    } else {
      addTcuGroupData({
        variables: {
          oemId: oemId,
          tcuId: tcuType,
          groupName: groupName,
          tcuInventoryId: ids,
        },
      });
    }
  };

  useEffect(() => {
    getTcuGroup({
      variables: {
        oemId: oemId,
        id: editId,
      },
    });

    getTCU({
      variables: {
        oemId: oemId,
      },
    });

    getTcuInventoryData({
      variables: {
        OEMId: oemId,
        limit: rowsPerPage,
        offset: page,
        status: "1",
        searchInput: {
          tcuId: tcu,
        },
      },
    });
  }, []);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isDialogOpened}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="max-width-dialog-title"></DialogTitle>
        <DialogContent>
          {formSubmit ? (
            <Grid container spacing={2}>
              {tcuGroupLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Grid item xs={8}>
                    <Alert severity={alertType}>{message}</Alert>
                  </Grid>
                  <Grid item xs={4}>
                    <Button onClick={handleClose} style={{ float: "right" }}>
                      <CloseIcon />
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography variant="h5">
                        Add/Edit Device Group{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Button onClick={handleClose} style={{ float: "right" }}>
                        <CloseIcon />
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTextField
                        id="group_name"
                        label="Asset Group"
                        maxRows={4}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        value={groupName}
                        onChange={(event) => setGroupName(event.target.value)}
                        className={classes.textfield}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {/* <FormControl
                        variant="outlined"
                        fullWidth="true"
                        size="small"
                        className={classes.textfield}
                      >
                        <InputLabel id="deviceModelCode">
                          Device Model Code
                        </InputLabel> */}
                      <CustomSelect
                        value={tcuName}
                        labelId="deviceModelCode"
                        id="deviceModelCode"
                        label="Device Model Code"
                        placeholder="Device Model Code"
                        options={deviceCodeModels}
                        getOptionLabel={(option) => option.tcuName}
                        handleSelectChange={
                          // ((event) => setTcu(event.target.value),
                          handleTcuData
                          // )
                        }
                        // key={tcuName}
                      />
                      {/* {tcuData
                            ? tcuData.getOEMVendorTCUs.map((item) => (
                                <MenuItem value={item.id}>
                                  {item.tcuName}
                                </MenuItem>
                              ))
                            : null} */}
                      {/* </Select> */}
                      {/* </FormControl> */}
                    </Grid>
                    <Grid item xs={12} style={{ borderRadius: "10px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            className={classes.textfield}
                            id="search_vehicle"
                            style={{ width: "60%" }}
                            placeholder="search for vehicle"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            size="small"
                            onChange={handleSearch}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Chip
                            variant="outlined"
                            size="medium"
                            label={`Total selected: ${
                              selected.length > 0 ? selected.length : 0
                            }`}
                            color="#5CB593"
                            style={{ marginTop: "15px", color: "#5CB593" }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {invLoading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        <Checkbox
                                          color="default"
                                          inputProps={{
                                            "aria-label":
                                              "checkbox with default color",
                                          }}
                                          checked={checked}
                                          // onChange={}
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        Device Model
                                      </TableCell>
                                      <TableCell align="center">
                                        IMEI No.
                                      </TableCell>
                                      <TableCell align="center">
                                        Serial No.
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {tcuInventoryData ? (
                                      tcuInventoryData.rows.map(
                                        (row, index) => {
                                          return (
                                            <TableRow
                                              hover
                                              role="checkbox"
                                              tabIndex={-1}
                                            >
                                              <TableCell>
                                                <Checkbox
                                                  color="default"
                                                  inputProps={{
                                                    "aria-label":
                                                      "checkbox with default color",
                                                  }}
                                                  checked={row.selected}
                                                  onChange={(event) => {
                                                    // let newObject = {
                                                    //   rows: [
                                                    //     ...tcuInventoryData.rows,
                                                    //   ],
                                                    //   totalCount:
                                                    //     tcuInventoryData.totalCount,
                                                    // };

                                                    // newObject.rows.forEach(
                                                    //   (item) => {
                                                    //     newObject.rows[
                                                    //       index
                                                    //     ].selected = !item.selected;
                                                    //   },
                                                    // );
                                                    // setTcuInventoryData({
                                                    //   rows: newObject.rows,
                                                    //   totalCount:
                                                    //     newObject.totalCount,
                                                    // });

                                                    if (event.target.checked) {
                                                      setSelected([
                                                        ...selected,
                                                        row,
                                                      ]);
                                                    } else {
                                                      setSelected((prev) =>
                                                        prev.filter(
                                                          (currItem) =>
                                                            currItem.id !==
                                                            row.id
                                                        )
                                                      );
                                                    }
                                                  }}
                                                  value={row.id}
                                                  className="imeiCheck"
                                                />
                                              </TableCell>
                                              <TableCell align="center">
                                                {row.tcu.tcuName}
                                              </TableCell>
                                              <TableCell align="center">
                                                {row.imei}
                                              </TableCell>
                                              <TableCell align="center">
                                                {row.serialNumber}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        }
                                      )
                                    ) : (
                                      <>No Records found!</>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component="div"
                                count={
                                  tcuInventoryData
                                    ? tcuInventoryData.totalCount
                                    : 0
                                }
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item justifyContent="flex-end" xs={12}>
                      <div style={{ float: "right" }}>
                        <ColorOutlinedButton
                          variant="outlined"
                          style={{ marginRight: "30px" }}
                          className={classes.buttonStyle}
                          onClick={handleClose}
                        >
                          Cancel
                        </ColorOutlinedButton>
                        <ColorButton
                          type="submit"
                          variant="outlined"
                          onClick={handleSubmit}
                          className={classes.buttonStyle}
                        >
                          Confirm
                        </ColorButton>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
