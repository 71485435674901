import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
// import Grid from "@material-ui/core/Grid";
import { gql, useLazyQuery, useMutation } from "@apollo/client";

import CloseIcon from "@mui/icons-material/Close";
// import Button from "@material-ui/core/Button";
import { CustomSelect } from "../../hoc/CustomComponents";

// styles
import useStyles from "./styles";
import { CustomTextField } from "../../hoc/CustomComponents";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";

const getOemBillingPlan = gql`
  query($oemId: Int!) {
    getOEMBillingPlan(oemId: $oemId) {
      id
      planName
      planType
      planExpiry
      warrantyPeriod
      status
      billingPlanCategory {
        id
        isRecurring
        isTCUWise
        currency
        totalTCUCount
        assignedTCUCount
        tcuCriteria {
          id
          billingMode
          billingPeriod
          nextBillingDate
          chargesForPeriod
          tax
          minPacketThresholdPerDay
          minTrackDaysForPeriod
          freePeriod
          expiryPeriod
          creditPeriod
        }
        simCriteria {
          id
          billingMode
          billingPeriod
          nextBillingDate
          chargesForPeriod
          tax
          minPacketThresholdPerDay
          minTrackDaysForPeriod
          freePeriod
          expiryPeriod
          creditPeriod
        }
      }
    }
  }
`;

const getOEMTcu = gql`
  query($oemId: Int!) {
    getOEMVendorTCUs(oemId: $oemId) {
      id
      vendorId
      vendorCode
      tcuName
      ownerId
      tcu_code
    }
  }
`;

export default function BulkUploadDialog({
  isDialogOpened,
  handleCloseDialog,
  oemId,
  upload,
  bulkUploadDevice,
  setBillingPlanId,
  setTcuId,
  tcuId,
  billingPlanId,
  snackbarOpen,
  bulkDeviceResponse,
  excelLoading,
}) {
  //const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  const [allTcu, setAllTcu] = useState([]);
  const [allPlanTypes, setAllPlanTypes] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    getOemBillingPlanData({
      variables: {
        oemId: oemId,
      },
    });

    getOemTcuData({
      variables: {
        oemId: oemId,
      },
    });
  }, [oemId]);
  let billingPlanArray = [];

  const [getOemBillingPlanData, { loading, error, data }] = useLazyQuery(
    getOemBillingPlan,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        // console.log("billingResult---", result);
        result.getOEMBillingPlan.map((item) => {
          billingPlanArray.push({ id: item.id, planName: item.planName });
          setAllPlanTypes(billingPlanArray);
        });
      },
    }
  );

  // console.log(allPlanTypes, "allPlanTypes");

  let tcuArr = [];
  const [
    getOemTcuData,
    { loading: tcuLoading, error: tcuError, data: tcuData },
  ] = useLazyQuery(getOEMTcu, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      // console.log(result, "resultTcu");
      if (result.getOEMVendorTCUs.length) {
        result.getOEMVendorTCUs.map((item) => {
          tcuArr.push({ id: item.id, tcuName: item.tcuName });
          setAllTcu(tcuArr);
        });
      }
    },
  });
  // console.log(allTcu, "allTcu");

  const handleClose = () => {
    //setIsOpen(false);
    handleCloseDialog(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleTcu = (e, tcu) => {
    if (!tcu) {
      setTcuId("");
    } else {
      setTcuId(tcu.id);
    }
  };

  const handlePlanTypeChange = (e, billingPlan) => {
    console.log(billingPlan, "billingPlan");
    if (!billingPlan) {
      setBillingPlanId("");
    } else {
      setBillingPlanId(billingPlan.id);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isDialogOpened}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container style={{ paddingBottom: "10px" }}>
                  <Grid item xs={9}>
                    <Typography variant="h4">Add New Device</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button style={{ float: "right" }} onClick={handleClose}>
                      <CloseIcon
                        style={{
                          fill: "#2b2924",
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <CustomSelect
                      id="tcuId"
                      label="TCU"
                      placeholder="Select TCU"
                      options={allTcu}
                      handleSelectChange={handleTcu}
                      getOptionLabel={(option) => option.tcuName}
                      name="tcu"
                      setTcuId={setTcuId}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomSelect
                      id="billingPlan"
                      label="Billing Plan"
                      name="billingPlan"
                      placeholder="Select Billing Plan"
                      options={allPlanTypes}
                      getOptionLabel={(option) => option.planName}
                      handleSelectChange={handlePlanTypeChange}
                      style={{ width: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {isUploading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <CustomTextField
                        variant="contained"
                        placeholder="Upload template"
                        label="Upload File*"
                        // disabled={true}
                        type="file"
                        onChange={upload}
                      />
                    )}
                    {/* </label> */}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ paddingTop: "16px" }}
                  spacing={3}
                >
                  <Grid item xs={3}>
                    <ColorOutlinedButton
                      className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={handleClose}
                    >
                      Cancel
                    </ColorOutlinedButton>
                  </Grid>
                  <Grid item xs={3}>
                    {excelLoading ? (
                      <CircularProgress style={{ color: "#57B793" }} />
                    ) : (
                      <ColorButton
                        className={classes.buttonOutlined}
                        variant="outlined"
                        onClick={bulkUploadDevice}
                        disabled={
                          tcuId == "" ||
                          tcuId == null ||
                          billingPlanId == "" ||
                          billingPlanId == null ||
                          bulkDeviceResponse == "" ||
                          bulkDeviceResponse == null ||
                          snackbarOpen
                        }
                      >
                        Add Device
                      </ColorButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
