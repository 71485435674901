import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { CustomSelect, CustomTextField } from "../../hoc/CustomComponents";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
export function AddCampaign({
  open,
  handleCampaignName,
  handleIMEI,
  handleContoller,
  handleFile,
  fileList,
  allController,
  handleCampaignCancel,
  addCampaign,
  controller,
  enableFileList,
  campaignName,
  imei,
  file,
}) {
  console.log(controller, "controller");
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="max-width-dialog-title"
        // aria-labelledby="form-dialog-title"
        disableEscapeKeyDown={true}
        // style={{ width: "auto", height: "auto" }}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container>
            <Grid item xs={9}>
              <Typography
                style={{ font: " normal normal bold 24px/32px Nunito Sans" }}
              >
                Add FOTA Campaign
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button style={{ float: "right" }} onClick={handleCampaignCancel}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CustomTextField
                    name="Campaign Name"
                    label="Campaign Name"
                    placeholder="Campaign Name"
                    onChange={(e) => handleCampaignName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    name="Imei"
                    label="IMEI"
                    placeholder="IMEI"
                    onChange={(e) => handleIMEI(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomSelect
                    label="Controller"
                    name="Controller"
                    placeholder="Select Controller"
                    options={allController}
                    handleSelectChange={handleContoller}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomSelect
                    label="File Name"
                    name="FTPFile"
                    placeholder="Select File"
                    options={fileList}
                    handleSelectChange={handleFile}
                    disabled={enableFileList}
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ padding: "16px" }}
                  spacing={3}
                >
                  <Grid item>
                    <ColorOutlinedButton
                      className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={handleCampaignCancel}
                    >
                      Cancel
                    </ColorOutlinedButton>
                  </Grid>
                  <Grid item>
                    <ColorButton
                      className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={addCampaign}
                      disabled={
                        campaignName == "" ||
                        campaignName == null ||
                        imei == "" ||
                        imei == null ||
                        controller == "" ||
                        controller == null ||
                        file == "" ||
                        file == null
                      }
                    >
                      Submit
                    </ColorButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export function UploadFTPFile({
  open,
  allController,
  handleContoller,
  handleCampaignCancel,
  uploadFile,
  handleUpload,
  enableFileList,
  controller,
  upload,
  uploadLoading,
}) {
  console.log(uploadLoading, "uploadLoading");
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="max-width-dialog-title"
      // aria-labelledby="form-dialog-title"
      disableEscapeKeyDown={true}
      //   style={{ width: "auto", height: "auto" }}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        <Grid container>
          <Grid item xs={9}>
            <Typography
              style={{ font: " normal normal bold 24px/32px Nunito Sans" }}
            >
              Add FTP File
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Button style={{ float: "right" }} onClick={handleCampaignCancel}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomSelect
                  name="Controller"
                  placeholder="Select Controller"
                  options={allController}
                  label="Controller"
                  handleSelectChange={handleContoller}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  variant="contained"
                  placeholder="Upload template"
                  label="Upload File*"
                  type="file"
                  onChange={uploadFile}
                  disabled={enableFileList}
                />
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                style={{ padding: "16px" }}
                spacing={3}
              >
                <Grid item xs={3}>
                  <ColorOutlinedButton
                    className={classes.buttonOutlined}
                    variant="outlined"
                    onClick={handleCampaignCancel}
                  >
                    Cancel
                  </ColorOutlinedButton>
                </Grid>
                <Grid item xs={3}>
                  {uploadLoading ? (
                    <CircularProgress />
                  ) : (
                    <ColorButton
                      className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={handleCampaignCancel}
                      disabled={
                        controller == null || controller == "" || !upload
                      }
                    >
                      Done
                    </ColorButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
