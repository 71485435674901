import { Tab } from "@mui/base";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableHead,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { gql, useLazyQuery } from "@apollo/client";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";
const GET_KYC_DETAILS = gql`
  query($oemId: Int!, $limit: Int, $offset: Int, $vin: String!) {
    getKycLogsByVin(oemId: $oemId, limit: $limit, offset: $offset, vin: $vin) {
      code
      message
      totalCount
      kycLogRows {
        imei
        serialNumber
        iccid
        vin
        engineNumber
        yearOfmg
        vehicleMake
        vehicleModel
        fuelType
        customerID
        customerName
        customerMobile1
        retailInvoiceNumber
        customerAddress
        customerEmail
        proofOfAddress
        dealerCode
        dealerName
        dealerAddress
        dealerTown
        dealerContactName
        dealerContactNumber
        kyccount
        kycApprovedDate
        kycRejectedDate
        lastKycReceivedDate
        vehicleActivationDate
        kycMode
        ticketCreated
        status
        rejectionReason
        simPlanType
        simSubscriptionStartDate
        simSubscriptionEndDate
        createdOn
        __typename
      }
      __typename
    }
  }
`;
const KycLogsByVin = ({
  open,
  columns,
  allLogs,
  handleVinLogs,
  selectedVin,
  oem,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  let [next, setNext] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [logs, setLogs] = useState();
  const [
    KycLogsDetails,
    { error: error, loading: loading, data: Details },
  ] = useLazyQuery(GET_KYC_DETAILS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    // onError: ({ graphQLErrors, networkError }) => {
    //   if (networkError) {
    //     props.setMessage(`Message: ${networkError}`);
    //     props.setNetworkError(true);
    //     props.setSnackbar(true);
    //   }

    //   if (graphQLErrors) {
    //     graphQLErrors.forEach(({ message, locations, path }) => {
    //       props.setMessage(`Message: ${message}`);
    //       props.setNetworkError(true);
    //       props.setSnackbar(true);
    //     });
    //   }
    //   handleClose();
    // },
    onCompleted: async (result) => {
      setLogs(result.getKycLogsByVin?.kycLogRows);
      //   setTotalCount(result.getKycLogs.totalCount);
    },
  });
  useEffect(() => {
    KycLogsDetails({
      variables: { oemId: oem, vin: selectedVin },
    });
  }, [oem]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                Kyc Logs
              </Grid>
              <Grid item xs={3}>
                <Button style={{ float: "right" }} onClick={handleVinLogs}>
                  <CloseIcon
                    style={{
                      fill: "#2b2924",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {loading ? (
              <CustomCircularProgress />
            ) : logs?.length > 0 ? (
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          align="center"
                          style={{
                            minWidth: 200,
                            font: "normal normal 600 15px/20px Nunito Sans",
                            backgroundColor: "#faf8f7",
                          }}
                          className={classes.tableHead}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logs.map((row, index) => {
                      let kycApprovedDate = row.kycApprovedDate
                        ? moment
                            .unix(row.kycApprovedDate)
                            .format("DD-MM-YYYY HH:mm:ss")
                        : null;
                      let kycRejectedDate = row.kycRejectedDate
                        ? moment
                            .unix(row.kycRejectedDate)
                            .format("DD-MM-YYYY HH:mm:ss")
                        : null;
                      let lastKycReceivedDate = row.lastKycReceivedDate
                        ? moment
                            .unix(row.lastKycReceivedDate)
                            .format("DD-MM-YYYY HH:mm:ss")
                        : null;
                      let vehicleActivationDate = row.vehicleActivationDate
                        ? moment
                            .unix(row.vehicleActivationDate * 1000)
                            .format("DD-MM-YYYY HH:mm:ss")
                        : null;

                      let logDate = row.createdOn
                        ? moment
                            .unix(row.createdOn)
                            .format("DD-MM-YYYY HH:mm:ss")
                        : null;
                      return (
                        <TableRow>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {next + (index + 1)}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                            style={{ cursor: "pointer" }}
                          >
                            {row.vin}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.imei ? row.imei : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.engineNumber}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.yearOfmg ? row.yearOfmg : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.vehicleMake ? row.vehicleMake : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.vehicleModel ? row.vehicleModel : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.fuelType ? row.fuelType : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.customerID}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.customerName ? row.customerName : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.customerMobile1 ? row.customerMobile1 : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.retailInvoiceNumber
                              ? row.retailInvoiceNumber
                              : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.customerAddress ? row.customerAddress : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.customerEmail ? row.customerEmail : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.dealerCode ? row.dealerCode : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.dealerName ? row.dealerName : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.dealerAddress ? row.dealerAddress : "NA"}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.dealerTown}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.dealerContactName
                              ? row.dealerContactName
                              : "NA"}
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.proofOfAddress}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.proofOfIdentity}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.dealerContactNumber}
                          </TableCell> */}
                          {/* <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.dealerContactNumber}
                          </TableCell> */}
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.kyccount}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {kycRejectedDate ? kycRejectedDate : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {lastKycReceivedDate ? lastKycReceivedDate : "NA"}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.kycMode}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.ticketCreated}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.status}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.rejectionReason ? row.rejectionReason : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {logDate}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <NoData />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default KycLogsByVin;
