import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  tableHead: {
    font: "normal normal 600 15px/20px Nunito Sans",
    letterSpacing: "0px",
    color: "#212121",
    borderRightWidth: 1,
    borderColor: "#E8E8E8",
    borderTop: "1px solid #E8E8E8",
    padding:'8px !important'
  },
  container: {
    maxHeight: 440,
  },
  tableContent: {
    letterSpacing: "0px",
    borderColor: "#E8E8E8",
    borderRightWidth: 1,
    padding:'4px !important'

  },
}));
