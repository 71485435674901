import React, { useState, useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import useStyles from "./styles";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import { gql, useQuery, Query, useLazyQuery } from "@apollo/client";
import CreateIcon from "@mui/icons-material/Create";
import { CustomSelect, CustomTextField } from "../../hoc/CustomComponents";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";

const getTCU = gql`
  query(
    $id: Int
    $oemId: Int!
    $limit: Int!
    $offset: Int!
    $search: campaignSearchInput
  ) {
    getTCUCampaign(
      id: $id
      oemId: $oemId
      limit: $limit
      offset: $offset
      search: $search
    ) {
      id
      oem {
        id
        oemName
        oemCode
        oemEmail
        logo
      }
      campaignName
      campaignCategory
      campaignType
      fileUrl
      tcuGroup {
        id
        tcuId {
          id
          vendorId
          tcuName
          tcu_code
        }
        groupName
        ownerId
      }
      campaignCommand {
        id
        imei
        command
      }
      status
      campaignProgress
      campaignCondition {
        id
        igntionStatus
        daysOfWeek
        fromTime
        toTime
        terminateOnBreach
      }
    }
  }
`;

export default function AddEditCampaign(props) {
  const {
    openDialogue,
    campaignName,
    handlecampaignName,
    ecuName,
    handleEcuName,
    handleSerialNumber,
    serialNumber,
    isUploading,
    upload,
    handleCancel,
    handleFlashCampaign,
    snackbarOpen,
    uploadResponse,
    allImei,
    allEcu,
  } = props;
  const classes = useStyles();
  // const [campaignName, setcampaignName] = useState();

  const stepper = ["", "", ""];
  // const useStyles = makeStyles(() => ({
  //   root: {
  //     "& .MuiStepIcon-active": { color: "#E0FFF3" },
  //     "& .MuiStepIcon-completed": { color: "#5CB593" },
  //     "& .Mui-disabled .MuiStepIcon-root": { color: "#E0FFF3" },
  //   },
  // }));

  // const c = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <Dialog
        open={openDialogue}
        // aria-labelledby="max-width-dialog-title"
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown={true}
        // style={{ width: "auto", height: "auto" }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <Typography
            style={{ font: " normal normal bold 24px/32px Nunito Sans" }}
          >
            New Ecu Flashing
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6} style={{ paddingRight: "8px" }}>
                    <CustomTextField
                      id="campaignName"
                      name="campaignName"
                      label="Campaign Name*"
                      placeholder="Campaign Name"
                      type="text"
                      onChange={(e) => {
                        handlecampaignName(e.target.value);
                      }}
                      value={campaignName}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: "8px", paddingBottom: "8px" }}
                  >
                    {/* <CustomTextField
                      id="ecuName"
                      name="ecuName"
                      label="ECU Name*"
                      placeholder="ECU Name"
                      type="text"
                      onChange={(e) => {
                        handleEcuName(e.target.value);
                      }}
                      value={ecuName}
                    /> */}
                    <CustomSelect
                      value={ecuName}
                      label="ECU Name*"
                      placeholder="ECU Name"
                      options={allEcu}
                      handleSelectChange={handleEcuName}
                      getOptionLabel={(option) => option}
                      // disabled={edit}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{ paddingRight: "14px", paddingTop: "8px" }}
                  >
                    <CustomSelect
                      value={serialNumber}
                      label="IMEI Number*"
                      placeholder="IMEI Number"
                      options={allImei}
                      handleSelectChange={handleSerialNumber}
                      getOptionLabel={(option) => option}
                      // disabled={edit}
                    />
                    {/* <CustomTextField
                      id="imeiNumber"
                      name="imeiNumber"
                      label="IMEI Number*"
                      placeholder="IMEI Number"
                      type="text"
                      onChange={(e) => {
                        handleSerialNumber(e.target.value);
                      }}
                      value={serialNumber}
                      className={classes.textfield}
                    /> */}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: "8px", paddingTop: "8px" }}
                  >
                    {isUploading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <CustomTextField
                        variant="contained"
                        placeholder="Upload tar file"
                        label="Upload File*"
                        type="file"
                        onChange={upload}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>

          {/* <Grid container spacing={2}>
          <Grid item xs={7}>
            <Typography
              style={{ font: " normal normal bold 24px/32px Nunito Sans" }}
            >
              Add/Edit campaign
            </Typography>
          </Grid>

          <Grid item xs={4} style={{ paddingLeft: "90px" }}>
            <Stepper
              activeStep={props.currentStep}
              className={classes.stepper}
              // className={c.root}
              alternativeLabel
            >
              {stepper.map((item) => (
                <Step key={item}>
                  <StepLabel>{""}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item style={{ margin: "10px" }}>
            {props.currentStep == 0 ? (
              <Form1
                handleCancel={props.handleCancel}
                handleNext={props.handleNext}
                handlecampaignName={props.handlecampaignName}
                handlecampaignType={props.handlecampaignType}
                handleSingleCategory={props.handleSingleCategory}
                handleGroupCategory={props.handleGroupCategory}
                deviceGroup={props.deviceGroup}
                handleGroup={props.handleGroup}
                groupId={props.groupId}
                disabled={props.disabled}
                campaignName={props.campaignName}
                campaignType={props.campaignType}
              />
            ) : props.currentStep == 1 ? (
              <Form2
                handleCancel={props.handleCancel}
                handleNext={props.handleNext}
                handleTerminate={props.handleTerminate}
                handleSpeed={props.handleSpeed}
                handleMinSpeed={props.handleMinSpeed}
                handleMaxSpeed={props.handleMaxSpeed}
                handleIgnition={props.handleIgnition}
                handleToTime={props.handleToTime}
                handleFromTime={props.handleFromTime}
                handleTime={props.handleTime}
                handleDay={props.handleDay}
                handleMon={props.handleMon}
                handleTue={props.handleTue}
                handleWed={props.handleWed}
                handleThu={props.handleThu}
                handleFri={props.handleFri}
                handleSat={props.handleSat}
                handleSun={props.handleSun}
                speed={props.speed}
                ignition={props.ignition}
                timeOfDay={props.timeOfDay}
                dayOfWeek={props.dayOfWeek}
                toTime={props.toTime}
                fromTime={props.fromTime}
                disabled={props.disabledForm2}
                ignitionOn={props.ignitionOn}
                edit={props.edit}
              />
            ) : props.currentStep == 2 ? (
              <Form3
                handleCancel={props.handleCancel}
                handleSubmit={props.handleSubmit}
                handleUpload={props.handleUpload}
                enableButton={props.enableButton}
                handleUploadUrl={props.handleUploadUrl}
                disabled={props.disabledForm3}
                // handleCommand={props.handleCommand}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid> */}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent="flex-end"
            style={{ padding: "16px" }}
            spacing={3}
          >
            <Grid item xs={3}>
              <ColorOutlinedButton
                component="span"
                onClick={handleCancel}
                // style={{ width: "10vw" }}
              >
                cancel
              </ColorOutlinedButton>
            </Grid>
            <Grid item xs={3}>
              <ColorButton
                component="span"
                onClick={handleFlashCampaign}
                disabled={
                  campaignName == "" ||
                  campaignName == null ||
                  ecuName == "" ||
                  ecuName == null ||
                  serialNumber == "" ||
                  serialNumber == null ||
                  snackbarOpen ||
                  uploadResponse == false
                }
                // style={{ width: "10vw" }}
              >
                Create
              </ColorButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
