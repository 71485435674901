import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Button,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomSelect, CustomTextField } from "../../hoc/CustomComponents";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";
const AddEditDealer = ({
  open,
  username,
  handleUsername,
  password,
  handlePassword,
  phoneNo,
  handlePhoneNo,
  dealerCode,
  handleDealerCode,
  handleAddressL1,
  addressL1,
  addressL2,
  handleAddressL2,
  bank,
  handleBank,
  accPerson,
  handleAccPerson,
  accNo,
  handleAccNo,
  handleIfsc,
  ifsc,
  gst,
  handleGst,
  pan,
  handlePan,
  country,
  allCountry,
  hanldeCountry,
  state,
  allState,
  handleState,
  city,
  allCity,
  handleCity,
  pinCode,
  handlePinCode,
  allAmOems,
  handleAmOemId,
  amOemId,
  handleEmail,
  email,
  AddDealer,
  closeModal,
  edit,
  editDealerDetails,
  isValidEmail,
  checkEmailValidity,
  bankValidity,
  CheckBankValidity,
  checkAddL1Valid,
  checkAddL2Valid,
  checkPersonValid,
  isPersonValid,
  isAddL1Valid,
  isAddL2Valid,
  checkPhoneValidity,
  isPhoneValid,
  checkIfscValidity,
  isIFSCValid,
  checkGstValidity,
  isGstValid,
  checkPanNumberValidity,
  isPanValid,
  checkAccountNumberValidity,
  isAccNoValid,
  checkPinCodeValid,
  pinCodeValid,
  checkDealerCodeValid,
  isDealerCodeValid
}) => {

  const handleKeyPress = (event) => {

    if (edit) {
      if (email == "" ||
        email == null ||
        !isValidEmail ||
        !bankValidity ||
        !isAddL2Valid ||
        !isAddL1Valid ||
        !isPersonValid ||
        !isPhoneValid ||
        !isAccNoValid ||
        !isIFSCValid ||
        !isGstValid ||
        !isPanValid ||
        !pinCodeValid) {
        return;
      }
      else {
        if (event.key === 'Enter') {
          console.log("key pressed in EditDealer..")
          editDealerDetails();
        }
      }
    }
    else {
      if (username == null ||
        password == "" ||
        username == "" ||
        password == null ||
        phoneNo == null ||
        phoneNo == "" ||
        amOemId == "" ||
        amOemId == null ||
        email == "" ||
        email == null ||
        !isValidEmail ||
        !bankValidity ||
        !isAddL2Valid ||
        !isAddL1Valid ||
        !isPersonValid ||
        !isPhoneValid ||
        !isAccNoValid ||
        !isIFSCValid ||
        !isGstValid ||
        !isPanValid ||
        !pinCodeValid ||
        !isDealerCodeValid) {
        return;
      }
      else if (event.key === 'Enter') {
        console.log("key pressed in AddDealer...")
        AddDealer(); // Call AddDealer function when Enter key is pressed
      }
    }

  };

  return (
    <Dialog
      open={open}
      maxWidth={"md"}
      fullWidth={true}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
      onKeyDown={handleKeyPress}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="h5">
                  {edit ? "Edit Dealer Details" : "Add Dealer Details"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Button style={{ float: "right" }} onClick={closeModal}>
                  <CloseIcon
                    style={{
                      fill: "#2b2924",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <CustomTextField
                  disabled={edit}
                  value={username}
                  label="Username*"
                  required={true}
                  placeholder="Enter Username"
                  onChange={(e) => handleUsername(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  disabled={edit}
                  value={edit ? "*********" : password}
                  label="Password*"
                  placeholder="Enter Password"
                  onChange={(e) => handlePassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomSelect
                  disabled={edit}
                  value={amOemId}
                  label="After Market Oem*"
                  options={allAmOems}
                  getOptionLabel={(options) => options.label}
                  placeholder="Select After Market Oem"
                  handleSelectChange={handleAmOemId}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  disabled={edit}
                  value={phoneNo}
                  label="Phone No*"
                  placeholder="Enter Phone No"
                  onChange={(e) => handlePhoneNo(e.target.value)}
                  onBlur={checkPhoneValidity}
                />
                <FormHelperText
                  style={{
                    color: "Red",
                    height: "2px",
                    font: "normal normal 500 10px/16px Poppins",
                    letterSpacing: "0px",
                    opacity: "1",
                    paddingTop: "0px",
                    paddingLeft: "16px",
                  }}
                >
                  {!isPhoneValid ? "Invalid Phone No." : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  value={email}
                  label="Email*"
                  type={edit ? "text" : ""}
                  placeholder="Enter Email"
                  onBlur={checkEmailValidity}
                  onChange={(e) => handleEmail(e.target.value)}
                />
                <FormHelperText
                  style={{
                    color: "Red",
                    height: "2px",
                    font: "normal normal 500 10px/16px Poppins",
                    letterSpacing: "0px",
                    opacity: "1",
                    paddingTop: "0px",
                    paddingLeft: "16px",
                  }}
                >
                  {!isValidEmail ? "Invalid Email Id" : ""}
                </FormHelperText>
              </Grid>

              <Grid item xs={4}>
                <CustomTextField
                  value={dealerCode}
                  disabled={edit}
                  required={true}
                  label="Dealer Code*"
                  type={edit ? "text" : ""}
                  placeholder="Enter Dealer Code"
                  onChange={(e) => handleDealerCode(e.target.value)}
                  onBlur={checkDealerCodeValid}
                />
                <FormHelperText
                  style={{
                    color: "Red",
                    height: "2px",
                    font: "normal normal 500 10px/16px Poppins",
                    letterSpacing: "0px",
                    opacity: "1",
                    paddingTop: "0px",
                    paddingLeft: "16px",
                  }}
                >
                  {!isDealerCodeValid ? "Only number is allowed" : ""}
                </FormHelperText>


              </Grid>

              <Grid item xs={4}>
                <CustomTextField
                  value={addressL1}
                  label="Address Line 1"
                  type={edit ? "text" : ""}
                  placeholder="Enter Address"
                  onChange={(e) => handleAddressL1(e.target.value)}
                  onBlur={checkAddL1Valid}
                />
                <FormHelperText
                  style={{
                    color: "Red",
                    height: "2px",
                    font: "normal normal 500 10px/16px Poppins",
                    letterSpacing: "0px",
                    opacity: "1",
                    paddingTop: "0px",
                    paddingLeft: "16px",
                  }}
                >
                  {!isAddL1Valid ? "Invalid address" : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  value={addressL2}
                  label="Address Line 2"
                  type={edit ? "text" : ""}
                  placeholder="Enter Address"
                  onChange={(e) => handleAddressL2(e.target.value)}
                  onBlur={checkAddL2Valid}
                />
                <FormHelperText
                  style={{
                    color: "Red",
                    height: "2px",
                    font: "normal normal 500 10px/16px Poppins",
                    letterSpacing: "0px",
                    opacity: "1",
                    paddingTop: "0px",
                    paddingLeft: "16px",
                  }}
                >
                  {!isAddL2Valid ? "Invalid address" : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  value={bank}
                  label="Bank"
                  type={edit ? "text" : ""}
                  placeholder="Enter Bank Name"
                  onBlur={CheckBankValidity}
                  onChange={(e) => handleBank(e.target.value)}
                />
                <FormHelperText
                  style={{
                    color: "Red",
                    height: "2px",
                    font: "normal normal 500 10px/16px Poppins",
                    letterSpacing: "0px",
                    opacity: "1",
                    paddingTop: "0px",
                    paddingLeft: "16px",
                  }}
                >
                  {!bankValidity ? "Invalid Bank name" : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  value={accPerson}
                  label="Account Holder Name"
                  type={edit ? "text" : ""}
                  placeholder="Enter Account Holder Name"
                  onChange={(e) => handleAccPerson(e.target.value)}
                  onBlur={checkPersonValid}
                />
                <FormHelperText
                  style={{
                    color: "Red",
                    height: "2px",
                    font: "normal normal 500 10px/16px Poppins",
                    letterSpacing: "0px",
                    opacity: "1",
                    paddingTop: "0px",
                    paddingLeft: "16px",
                  }}
                >
                  {!isPersonValid ? "Invalid name" : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  value={accNo}
                  label="Account No"
                  type={edit ? "text" : ""}
                  placeholder="Enter Account No"
                  onChange={(e) => handleAccNo(e.target.value)}
                  onBlur={checkAccountNumberValidity}
                />
                <FormHelperText
                  style={{
                    color: "Red",
                    height: "2px",
                    font: "normal normal 500 10px/16px Poppins",
                    letterSpacing: "0px",
                    opacity: "1",
                    paddingTop: "0px",
                    paddingLeft: "16px",
                  }}
                >
                  {!isAccNoValid ? "Invalid Account Number" : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  value={ifsc}
                  label="IFSC"
                  type={edit ? "text" : ""}
                  placeholder="Enter IFSC"
                  onChange={(e) => handleIfsc(e.target.value)}
                  onBlur={checkIfscValidity}
                />
                <FormHelperText
                  style={{
                    color: "Red",
                    height: "2px",
                    font: "normal normal 500 10px/16px Poppins",
                    letterSpacing: "0px",
                    opacity: "1",
                    paddingTop: "0px",
                    paddingLeft: "16px",
                  }}
                >
                  {!isIFSCValid ? "Invalid IFSC" : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  value={gst}
                  label="GST"
                  type={edit ? "text" : ""}
                  placeholder="Enter GST"
                  onChange={(e) => handleGst(e.target.value)}
                  onBlur={checkGstValidity}
                />
                <FormHelperText
                  style={{
                    color: "Red",
                    height: "2px",
                    font: "normal normal 500 10px/16px Poppins",
                    letterSpacing: "0px",
                    opacity: "1",
                    paddingTop: "0px",
                    paddingLeft: "16px",
                  }}
                >
                  {!isGstValid ? "Invalid GST" : ""}
                </FormHelperText>
              </Grid>

              <Grid item xs={4}>
                <CustomTextField
                  value={pan}
                  label="PAN"
                  type={edit ? "text" : ""}
                  placeholder="Enter PAN"
                  onChange={(e) => handlePan(e.target.value)}
                  onBlur={checkPanNumberValidity}
                />
                <FormHelperText
                  style={{
                    color: "Red",
                    height: "2px",
                    font: "normal normal 500 10px/16px Poppins",
                    letterSpacing: "0px",
                    opacity: "1",
                    paddingTop: "0px",
                    paddingLeft: "16px",
                  }}
                >
                  {!isPanValid ? "Invalid PAN" : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={4}>
                <CustomSelect
                  value={country}
                  label="Country"
                  options={allCountry}
                  getOptionLabel={(options) => options.label}
                  placeholder="Select Country"
                  handleSelectChange={hanldeCountry}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomSelect
                  value={state}
                  label="State"
                  options={allState}
                  getOptionLabel={(options) => options.label}
                  placeholder="Select State"
                  handleSelectChange={handleState}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomSelect
                  value={city}
                  label="City"
                  options={allCity}
                  getOptionLabel={(options) => options.label}
                  placeholder="Select City"
                  handleSelectChange={handleCity}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  value={pinCode}
                  label="Pincode"
                  type={edit ? "number" : ""}
                  placeholder="Enter Pincode"
                  onChange={(e) => handlePinCode(e.target.value)}
                  onBlur={checkPinCodeValid}
                />
                <FormHelperText
                  style={{
                    color: "Red",
                    height: "2px",
                    font: "normal normal 500 10px/16px Poppins",
                    letterSpacing: "0px",
                    opacity: "1",
                    paddingTop: "0px",
                    paddingLeft: "16px",
                  }}
                >
                  {!pinCodeValid ? "Invalid Pincode" : ""}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              // style={{ flexDirection: "row-reverse" }}
              style={{ paddingTop: "30px" }}
            >
              <Grid
                item
                style={{ paddingRight: "24px", paddingBottom: "15px" }}
              >
                <ColorOutlinedButton
                  // className={classes.buttonOutlined}
                  variant="outlined"
                  onClick={closeModal}
                >
                  Cancel
                </ColorOutlinedButton>
              </Grid>
              <Grid item>
                {edit ? (
                  <ColorButton
                    // className={classes.buttonOutlined}
                    variant="outlined"
                    onClick={editDealerDetails}
                    disabled={
                      email == "" ||
                      email == null ||
                      !isValidEmail ||
                      !bankValidity ||
                      !isAddL2Valid ||
                      !isAddL1Valid ||
                      !isPersonValid ||
                      !isPhoneValid ||
                      !isAccNoValid ||
                      !isIFSCValid ||
                      !isGstValid ||
                      !isPanValid ||
                      !pinCodeValid
                    }
                  >
                    Submit
                  </ColorButton>
                ) : (
                  <ColorButton
                    // className={classes.buttonOutlined}
                    variant="outlined"
                    onClick={AddDealer}
                    disabled={
                      username == null ||
                      password == "" ||
                      username == "" ||
                      password == null ||
                      phoneNo == null ||
                      phoneNo == "" ||
                      amOemId == "" ||
                      amOemId == null ||
                      email == "" ||
                      email == null ||
                      dealerCode == null ||
                      dealerCode == "" ||
                      !isValidEmail ||
                      !bankValidity ||
                      !isAddL2Valid ||
                      !isAddL1Valid ||
                      !isPersonValid ||
                      !isPhoneValid ||
                      !isAccNoValid ||
                      !isIFSCValid ||
                      !isGstValid ||
                      !isPanValid ||
                      !pinCodeValid ||
                      !isDealerCodeValid
                    }
                  >
                    Submit
                  </ColorButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog >
  );
};

export default AddEditDealer;
