import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  CustomCircularProgress,
  CustomSelect,
  CustomTextField,
} from "../../hoc/CustomComponents";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";

function UploadECUFiles({
  open,
  handleClose,
  handleDone,
  allScheduleTypes,
  allEcuGroups,
  allEcuNames,
  allFileTypes,
  handleScheduleType,
  handleEcuGroup,
  handleEcuName,
  handleFileType,
  scheduleType,
  ecuGroup,
  ecuName,
  fileType,
  upload,
  isUpload,
  fileName,
  edit,
}) {
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container style={{ paddingBottom: "10px" }}>
              <Grid item xs={9}>
                <Typography variant="h4">
                  {edit ? "Edit File" : "Upload a New ECU File"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  style={{ float: "right" }}
                  onClick={() => handleClose()}
                >
                  <CloseIcon
                    style={{
                      fill: "#2b2924",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomSelect
                  label="Schedule Type"
                  value={scheduleType}
                  options={allScheduleTypes}
                  placeholder="Select Schedule Type"
                  getOptionLabel={(option) => option.scheduleName}
                  handleSelectChange={handleScheduleType}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomSelect
                  label="ECU Group"
                  value={ecuGroup}
                  placeholder="Select ECU Group"
                  options={allEcuGroups}
                  getOptionLabel={(option) => option.ecu_group_name}
                  handleSelectChange={handleEcuGroup}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomSelect
                  label="ECU Name"
                  placeholder="Select ECU Name"
                  value={ecuName}
                  options={allEcuNames}
                  getOptionLabel={(option) => option.ecu_name}
                  handleSelectChange={handleEcuName}
                  disabled={!ecuGroup}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomSelect
                  label="File Type"
                  placeholder="Select File Type"
                  value={fileType}
                  options={allFileTypes}
                  getOptionLabel={(option) => option.fileTypeName}
                  handleSelectChange={handleFileType}
                />
              </Grid>
              <Grid item xs={12}>
                {edit ? (
                  <CustomTextField
                    variant="contained"
                    placeholder="Upload template"
                    label="Upload File*"
                    value={fileName}
                    disabled={edit}
                  />
                ) : (
                  <CustomTextField
                    variant="contained"
                    placeholder="Upload template"
                    label="Upload File*"
                    type="file"
                    onChange={upload}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              style={{ paddingTop: "26px" }}
              spacing={3}
            >
              <Grid item xs={3}>
                <ColorOutlinedButton variant="outlined" onClick={handleClose}>
                  Cancel
                </ColorOutlinedButton>
              </Grid>
              <Grid item xs={3}>
                {isUpload ? (
                  <CircularProgress />
                ) : edit ? (
                  <ColorButton
                    variant="outlined"
                    onClick={handleDone}
                    disabled={
                      scheduleType == null ||
                      ecuGroup == null ||
                      ecuName == null ||
                      fileType == null
                    }
                  >
                    Edit File
                  </ColorButton>
                ) : (
                  <ColorButton
                    variant="outlined"
                    onClick={handleDone}
                    disabled={
                      scheduleType == null ||
                      ecuGroup == null ||
                      ecuName == null ||
                      fileType == null ||
                      fileName == null
                    }
                  >
                    Upload File
                  </ColorButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default UploadECUFiles;
