import React, { useEffect, useState, useMemo } from "react";
import { Grid, Typography, Tabs, Tab } from "@mui/material";
import Files from "./Files";
import useStyles from "./styles";
import Campaigns from "./Campaigns";

function ECU(props) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(
    props.props.location.activeTab ? props.props.location.activeTab : 0
  );

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              ECU OTA Manager
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "2vh" }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#57B793" } }}
              className={classes.root}
            >
              <Tab label="Files" className={classes.tab} />
              <Tab label="Campaign" className={classes.tab} />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {activeTab === 0 ? (
              <Files
                oem={props.oem}
                token={props.token}
                featureId={props.featureId}
              ></Files>
            ) : activeTab === 1 ? (
              <Campaigns
                oem={props.oem}
                token={props.token}
                featureId={props.featureId}
              ></Campaigns>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default ECU;
