import React from "react";

import { SnackbarConsumer } from "./Snackbar.Context";

export default (Component) => (props) => (
  <SnackbarConsumer>
    {({
      message,

      open,

      isUploaded,
      isNetworkError,

      setUpload,
      setNetworkError,

      setMessage,

      isDialogVisible,

      closeDialog,

      setSnackbar,
      setExcelError,
      setExcelData,
      setColumns,
      setTitle,
    }) => (
      <Component
        message={message}
        open={open}
        isUploaded={isUploaded}
        isNetworkError={isNetworkError}
        setSnackbar={setSnackbar}
        setUpload={setUpload}
        setNetworkError={setNetworkError}
        setMessage={setMessage}
        isDialogVisible={isDialogVisible}
        closeDialog={closeDialog}
        setExcelError={setExcelError}
        setExcelData={setExcelData}
        setColumns={setColumns}
        setTitle={setTitle}
        {...props}
      />
    )}
  </SnackbarConsumer>
);
