export const fetchSearchResultsNumber = (searchValue, data) => {
  //assuming data to be array of arrays
  console.log("search value and data is", searchValue, data);
  let result = [];
  console.log(data.length, "data.length");
  if (data && data.length) {
    for (let dataEntries = 0; dataEntries < data.length; dataEntries++) {
      console.log(data[dataEntries], "data[dataEntries]");
      if (data[dataEntries] && data[dataEntries].length) {
        console.log(data[dataEntries].length);
        for (let rowData = 0; rowData < data[dataEntries].length; rowData++) {
          if (data[dataEntries][rowData]) {
            if (data[dataEntries][rowData]) {
              // console.log(
              //   data[dataEntries][rowData],
              //   'data[dataEntries][rowData]'
              // )
              if (
                data[dataEntries][rowData] !== "" ||
                data[dataEntries][rowData] !== null ||
                data[dataEntries][rowData] !== undefined
              ) {
                if (typeof data[dataEntries][rowData] == "number") {
                  // console.log('inside number')
                  let value = data[dataEntries][rowData].toString();
                  // console.log('type of value', value)
                  let found = value
                    .toLowerCase()
                    .includes(`${searchValue.toLowerCase().trim()}`);
                  // console.log('found number', found)
                  if (!found) {
                    continue;
                  } else {
                    // console.log('found data is ', data[dataEntries])
                    result.push(data[dataEntries]);
                    break;
                  }
                } else {
                  // console.log('inside not number')
                  let found = data[dataEntries][rowData]
                    .toLowerCase()
                    .includes(`${searchValue.toLowerCase().trim()}`);
                  // console.log('found str', found)
                  if (!found) {
                    continue;
                  } else {
                    // console.log('found data is ', data[dataEntries])
                    result.push(data[dataEntries]);
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return result;
};

export const fetchSearchResultsNumber1 = (searchValue, data) => {
  //assuming data to be array of objects
  console.log("search value and data is", searchValue, data);
  let result = [];
  // console.log(data.length, "data.length");
  if (data && data.length) {
    var respo = data.find((item) => {
      // console.log(item, "item");
      let values = Object.values(item);
      console.log(values.includes(searchValue), "values");
      if (values.includes(searchValue)) {
        console.log(item, "item");
        result.push(item);
      }
    });
  }
  return result;
};
