import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import {
  CustomTextField,
  CustomSelect,
  CustomDialogBox,
} from "../../hoc/CustomComponents";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";

// styles
import useStyles from "./styles";
import withSnackbar from "../../components/Snackbar/withSnackbar";

const getSIMData = gql`
  query($OEMId: Int!, $id: Int, $limit: Int!, $offset: Int!) {
    getSIMInventory(OEMId: $OEMId, id: $id, limit: $limit, offset: $offset) {
      totalCount
      rows {
        id
        tcuInventoryId {
          id
          oem {
            id
            oemName
            oemCode
            oemEmail
            phoneNumber
            logo
          }
          tcu {
            id
            vendorCode
            tcuName
            ownerId
            tcu_code
          }

          imei
          serialNumber
          owner
        }
        simVendorId {
          id
          oemId {
            id
            oemName
            oemCode
            oemEmail
            phoneNumber
            logo
          }
          vendorName
          vendorCode
          contactName
          contactNumber
          vendorType
          simApiId
          ownerId
        }
        iccid
        profileCount
        simDetails {
          id
          simInventoryId
          primaryProvider
          secondaryProvider
          msisdn1
          msisdn2
          activatedOn
          validity
          plan
          whitelistedIps
          whitelistedNumbers
          aadharNo
        }
        ownerId
        status
        simApi {
          id
          apiProviderName
          apiProviderUrl
        }
      }
    }
  }
`;

const SIM_UPDATE = gql`
  mutation(
    $id: Int!
    $simInventoryId: Int!
    $primaryProvider: String!
    $secondaryProvider: String!
    $msisdn1: String!
    $msisdn2: String!
    $aadharNo: String
    $activatedOn: String!
    $validity: String!
    $plan: String!
    $whitelistedIps: String!
    $whitelistedNumbers: String!
  ) {
    addEditSimInventory(
      id: $id
      simInventoryId: $simInventoryId
      primaryProvider: $primaryProvider
      secondaryProvider: $secondaryProvider
      msisdn1: $msisdn1
      msisdn2: $msisdn2
      aadharNo: $aadharNo
      activatedOn: $activatedOn
      validity: $validity
      plan: $plan
      whitelistedIps: $whitelistedIps
      whitelistedNumbers: $whitelistedNumbers
    )
  }
`;
const DELETE_SIM = gql`
  mutation($id: Int!) {
    deleteSimInventory(id: $id) {
      code
      status
      message
    }
  }
`;

const activationTypes = ["COMMERCIALLY_ACTIVATED", "BOOTSTRAP"];

function SimMaxWidthDialog({
  isDialogOpened,
  handleCloseDialog,
  simInventoryId,
  oemTcuInventoryId,
  isDelete,
  setSnackbar,
  setMessage,
  setNetworkError,
  setUpload,
}) {
  // console.log("tcuInventoryId---", simInventoryId);

  //const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth] = useState("lg");
  const [deviceManuf, setdeviceManuf] = useState("");
  const [deviceCode, setdeviceCode] = useState("");
  const [deviceModel, setdeviceModel] = useState("");
  const [imeiNumber, setimeiNumber] = useState("");
  const [serialNumber, setserialNumber] = useState("");
  const [simProvider, setsimProvider] = useState("");
  const [iccid, seticcid] = useState("");
  const [kycDetails, setkycDetails] = useState("");
  const [tsp1, setTsp1] = useState("");
  const [tsp2, setTsp2] = useState("");
  const [msisdn1, setMsisdn1] = useState("");
  const [msisdn2, setMsisdn2] = useState("");
  const [activationType, setactivationType] = useState("");
  const [activatedOn, setactivatedOn] = useState("");
  const [validity, setvalidity] = useState("");
  const [whitelistedIps, setWhitelistedIps] = useState("");
  const [whitelistedPhone, setWhitelistedPhone] = useState("");
  const [simStatus, setSimStatus] = useState("");
  const [simStatusName, setSimStatusName] = useState("");
  const [id, setId] = useState("");
  const [isdeleted, setIsDeleted] = useState(false);
  const [isActivationValid, setisActivationValid] = useState(true);

  const classes = useStyles();

  //Fetch the imei details using the id passed through props
  const [getSIMInventory, { loading, error, data }] = useLazyQuery(getSIMData, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      if (result) {
        result.getSIMInventory.rows.map((item) => {
          console.log("TCUDATAModal-----", item);

          const actDate = moment
            .unix(item.simDetails.activatedOn / 1000)
            .format("yyyy-MM-DD");
          console.log("acct---", actDate);
          setdeviceManuf(item.tcuInventoryId.tcu.vendorCode);
          setdeviceCode(item.tcuInventoryId.tcu.tcu_code);
          setdeviceModel(item.tcuInventoryId.tcu.tcuName);
          setimeiNumber(item.tcuInventoryId.imei);
          setserialNumber(item.tcuInventoryId.serialNumber);
          setsimProvider(item.simVendorId.vendorCode);
          setId(item.simDetails.id);
          seticcid(item.iccid);
          setkycDetails(item.simDetails.aadharNo);
          setTsp1(item.simDetails.primaryProvider);
          setTsp2(item.simDetails.secondaryProvider);
          setMsisdn1(item.simDetails.msisdn1);
          setMsisdn2(item.simDetails.msisdn2);
          setactivationType(item.simDetails.plan);
          setactivatedOn(actDate);
          setvalidity(item.simDetails.validity);
          setWhitelistedIps(item.simDetails.whitelistedIps);
          setWhitelistedPhone(item.simDetails.whitelistedNumbers);
          setSimStatus(item.status);
        });
      }
    },
  });

  //Delete device using device id
  //   //Fetch the imei details using the id passed through props

  const [
    updateSim,

    { data: simData, loading: simLoading, error: simError },
  ] = useMutation(SIM_UPDATE);

  const onChange = (e) => {
    console.log(e.target.name, "e");

    console.log(typeof e.target.name);

    let name;

    name = e.target.name;

    name(e.target.value);
  };

  //Delete device using device id
  const [
    deleteSim,
    {
      data: deleteDeviceData,
      loading: deleteDeviceLoading,
      error: deleteDeviceError,
    },
  ] = useMutation(DELETE_SIM, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        setMessage(`Message: ${networkError}`);
        setNetworkError(true);
        setSnackbar(true);
        handleClose();
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          setMessage(`Message: ${message}`);
          setNetworkError(true);
          setSnackbar(true);
          handleClose();
        });
      }
    },
    onCompleted: (result) => {
      setMessage(result.deleteSimInventory.message);
      setNetworkError(true);
      setSnackbar(true);
      handleClose();
    },
  });

  const handleActivationTypeChange = (e, select) => {
    if (!select) {
      setactivationType("");
    } else {
      setactivationType(select);
    }
  };

  const handleClose = () => {
    setTimeout(() => {
      setSnackbar(false);
    }, 2000);
    handleCloseDialog(false);
  };

  const handleSave = async () => {
    const momentObj = moment(activatedOn);
    let epochActivationDate = momentObj.valueOf();
    const updatedSim = await updateSim({
      variables: {
        id: id,
        simInventoryId: simInventoryId,
        primaryProvider: tsp1,
        secondaryProvider: tsp2,
        msisdn1: msisdn1,
        msisdn2: msisdn2,
        aadharNo: kycDetails,
        activatedOn: epochActivationDate.toString(),
        validity: validity.toString(),
        plan: activationType,
        whitelistedIps: whitelistedIps,
        whitelistedNumbers: whitelistedPhone,
      },
    });
    console.log(updatedSim.data.addEditSimInventory, "updatedSimData");
    if (updatedSim.data.addEditSimInventory) {
      setMessage("Sim updated successfully!");

      setNetworkError(true);
      setSnackbar(true);
      handleClose(false);
      setIsDeleted(true);
    } else {
      setMessage("Something went wrong");
      setSnackbar(true);
      handleClose(false);
    }
  };

  /* const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  }; */

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleRowDelete = async () => {
    await deleteSim({
      variables: { id: simInventoryId },
    });
    // console.log(deletedSimData, deletedSimData);
    // setMessage(deletedSimData.data.deleteSimInventory.message);
    // setSnackbar(true);
    // handleClose();
  };

  const checkActivationOn = () => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    const isValid = regex.test(activatedOn);
    setisActivationValid(isValid);
  };

  useEffect(() => {
    getSIMInventory({
      variables: {
        OEMId: oemTcuInventoryId,
        id: simInventoryId,
        limit: 1,
        offset: 0,
      },
    });
  }, []);

  return (
    <React.Fragment>
      {!isDelete ? (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={isDialogOpened}
          aria-labelledby="max-width-dialog-title"
          disableEscapeKeyDown={true}
        >
          <DialogTitle id="max-width-dialog-title"></DialogTitle>
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={9}>
                <Typography variant="h4">Edit SIM Details</Typography>
              </Grid>
              <Grid item xs={3}>
                <Button onClick={handleClose} style={{ float: "right" }}>
                  <CloseIcon />
                </Button>
              </Grid>
              {loading ? (
                <Grid item xs={12}>
                  <CircularProgress
                    color="inherit"
                    size="40"
                    thickness="3.6"
                    style={{ width: "100px", marginLeft: "50%" }}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={3}>
                    <CustomTextField
                      id="device_manuf_code"
                      label="Device Manuf Code"
                      value={deviceManuf}
                      // type="text"
                      read="true"
                      disabled="true"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <CustomTextField
                      id="device_model_code"
                      label="Device Model Code"
                      value={deviceCode}
                      // type="text"
                      read="true"
                      disabled="true"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <CustomTextField
                      id="device_model"
                      label="Device Model"
                      value={deviceModel}
                      read="true"
                      disabled="true"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      id="device_imei"
                      label="Device IMEI"
                      value={imeiNumber}
                      read="true"
                      disabled="true"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      id="device_serial"
                      label="Device Serial"
                      value={serialNumber}
                      read="true"
                      disabled="true"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      id="sim_provider"
                      label="SIM Provider"
                      value={simProvider}
                      read="true"
                      disabled="true"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      id="iccid"
                      label="ICCID"
                      value={iccid}
                      read="true"
                      disabled="true"
                    />
                  </Grid>
                  {/* <Grid item xs={3}>
                    <CustomTextField
                      id="kyc_details"
                      name="kyc_details"
                      label="KYC Details"
                      value={kycDetails}
                      onChange={(e) => setkycDetails(e.target.value)}
                    />
                  </Grid> */}
                  <Grid item xs={3}>
                    <CustomTextField
                      id="tsp1"
                      label="TSP 1"
                      value={tsp1}
                      read="true"
                      disabled="true"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      id="msisdn1"
                      label="MSISDN 1"
                      name="msisdn1"
                      value={msisdn1}
                      onChange={(e) => setMsisdn1(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      id="tsp2"
                      label="TSP 2"
                      value={tsp2}
                      read="true"
                      disabled="true"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      id="msisdn2"
                      name="msisdn2"
                      label="MSISDN 2"
                      value={msisdn2}
                      onChange={(e) => setMsisdn2(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      value={simStatus}
                      label="SIM Status"
                      read="true"
                      disabled="true"
                      name="simStatusName"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <CustomTextField
                      id="activated_on"
                      label="Activated On(yyyy-MM-DD)"
                      value={activatedOn}
                      read="true"
                      disabled="true"
                      onBlur={checkActivationOn}
                      error={!isActivationValid}
                      onChange={(e) => setactivatedOn(e.target.value)}
                    />
                    {isActivationValid ? (
                      ""
                    ) : (
                      <Typography style={{ color: "red" }}>
                        Invalid Date
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <CustomTextField
                      id="validity"
                      label="Validity(In Months)"
                      type="number"
                      value={validity}
                      read="true"
                      disabled="true"
                      onChange={(e) => setvalidity(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      id="whitelisted_ips"
                      label="WhiteListed IPS"
                      value={whitelistedIps}
                      read="true"
                      disabled="true"
                      onChange={(e) => setWhitelistedIps(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      id="whitelisted_phone"
                      label="WhiteListed Phone"
                      value={whitelistedPhone}
                      read="true"
                      disabled="true"
                      onChange={(e) => setWhitelistedPhone(e.target.value)}
                    />
                  </Grid>

                  {/* <div style={{ float: "right" }}> */}
                  <Grid
                    container
                    justifyContent="flex-end"
                    // className={classes.footerButtonGrid}

                    style={{ padding: "16px" }}
                    spacing={3}
                  >
                    <Grid item xs={2}>
                      <ColorOutlinedButton
                        variant="outlined"
                        className={classes.buttonOutlined}
                        onClick={handleClose}
                      >
                        Cancel
                      </ColorOutlinedButton>
                    </Grid>
                    <Grid item xs={2}>
                      <ColorButton
                        // variant="outlined"
                        className={classes.buttonOutlined}
                        onClick={handleSave}
                        disabled={!isActivationValid}
                      >
                        Save
                      </ColorButton>
                    </Grid>
                  </Grid>
                  {/* </div> */}
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      ) : (
        // <Dialog
        //   fullWidth="false"
        //   maxWidth="sm"
        //   open={isDialogOpened}
        //   aria-labelledby="max-width-dialog-title"
        //   disableEscapeKeyDown={true}
        // >
        //   {/* <DialogTitle
        //   style={{ backgroundColor: "#c1c1c1", color: "#333" }}
        //   id="alert-dialog-slide-title"
        // >
        //   DELETE ALERT
        // </DialogTitle> */}
        //   <DialogContent className={classes.deleteMessage}>
        //     {/* <DialogContentText className={classes.deleteMessage}> */}
        //       {/* <Typography  className={classes.deleteMessage}> */}
        //         Are you sure you want to delete this Sim(IMEI:{simInventoryId})?
        //       {/* </Typography> */}
        //     {/* </DialogContentText> */}
        //   </DialogContent>
        //   {!isdeleted ? (
        //     <DialogActions style={{ paddingBottom: "20px",font: "normal normal 500 26px/34px poppins"}}>
        //       <ColorOutlinedButton
        //         className={classes.buttonOutlined}
        //         variant="outlined"
        //         onClick={handleClose}
        //       >
        //         Cancel
        //       </ColorOutlinedButton>
        //       <ColorButton
        //         className={classes.buttonOutlined}
        //         variant="contained"
        //         onClick={handleRowDelete}
        //       >
        //         Delete
        //       </ColorButton>
        //     </DialogActions>
        //   ) : null}
        // </Dialog>
        <CustomDialogBox
          open={isDialogOpened}
          content={` Are you sure you want to delete this sim?`}
          negativeResponseHandler={handleClose}
          negativeResponseButtonText="Cancel"
          positiveResponseHandle={handleRowDelete}
          positiveResponseButtonText="Delete"
          Loading={deleteDeviceLoading}
        />
      )}
    </React.Fragment>
  );
}

export default withSnackbar(SimMaxWidthDialog);
