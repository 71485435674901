// import React, { useState } from "react";
// import {
//   Grid,
//   CircularProgress,
//   Typography,
//   Button,
//   TextField,
//   Fade,
//   InputAdornment,
//   OutlinedInput,
// } from "@mui/material";
// import { withRouter } from "react-router-dom";
// import { useApolloClient } from "@apollo/client";
// //import classnames from "classnames";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import PersonIcon from "@mui/icons-material/Person";
// import LockIcon from "@mui/icons-material/Lock";
// import { LoginButton } from "../../hoc/PaletteComponent";

// // styles
// import useStyles from "./styles";

// // logo
// import logo from "./logo.svg";
// //import google from "../../images/google.svg";

// // context
// import { useUserDispatch, loginUser } from "../../context/UserContext";

// function Login(props) {
//   var classes = useStyles();
//   const settings = {
//     // dots: true,
//     arrows: false,
//     infinite: true,
//     speed: 500,
//     autoplay: true,
//     autoplaySpeed: 4000,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     accessibility: true,
//   };
//   // global
//   var userDispatch = useUserDispatch();

//   // local
//   var [isLoading, setIsLoading] = useState(false);
//   var [error, setError] = useState(false);
//   var [activeTabId, setActiveTabId] = useState(0);
//   var [nameValue, setNameValue] = useState("");
//   var [loginValue, setLoginValue] = useState("ZeliotAdmin");
//   var [passwordValue, setPasswordValue] = useState("zeliot123");
//   const client = useApolloClient();

//   return (
//     <Grid container className={classes.container}>
//       <Grid item xs={6}>
//         {/* <Grid
//           item
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",

//           }}
//         >
//           <Typography variant="h4" style={{ color: "#57B793" }}>
//             HEY! Welcome Back
//           </Typography>
//         </Grid>
//         <Grid
//           item
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Typography variant="h6" style={{ color: "#57B793" }}>
//             Please enter your details
//           </Typography>
//         </Grid> */}
//         <Grid item className={classes.formContainer}>
//           <Grid item>
//             {activeTabId === 0 && (
//               <React.Fragment>
//                 <Fade in={error}>
//                   <Typography
//                     color="secondary"
//                     className={classes.errorMessage}
//                   >
//                     Invalid Username or Password
//                   </Typography>
//                 </Fade>
//                 <Grid item xs={12} className={classes.Form}>
//                   <OutlinedInput
//                     id="email"
//                     classes={{
//                       root: classes.root,
//                       focused: classes.focused,
//                       notchedOutline: classes.notchedOutline,
//                     }}
//                     startAdornment={
//                       <InputAdornment position="start">
//                         <PersonIcon />
//                       </InputAdornment>
//                     }
//                     // InputProps={{
//                     //   // classes: {
//                     //   //   underline: classes.textFieldUnderline,
//                     //   //   input: classes.textField,
//                     //   // },
//                     //   // classes: classes.Form,
//                     //   startAdornment: (
//                     //     <InputAdornment position="start">
//                     //       <PersonIcon />
//                     //     </InputAdornment>
//                     //   ),
//                     // }}
//                     value={loginValue}
//                     onChange={(e) => setLoginValue(e.target.value)}
//                     margin="normal"
//                     placeholder="Email Adress"
//                     type="email"
//                     variant="standard"
//                     fullWidth
//                   />
//                 </Grid>
//                 <Grid className={classes.Form} style={{ marginTop: "20px" }}>
//                   <OutlinedInput
//                     id="password"
//                     classes={{
//                       root: classes.root,
//                       focused: classes.focused,
//                       notchedOutline: classes.notchedOutline,
//                     }}
//                     startAdornment={
//                       <InputAdornment position="start">
//                         <LockIcon />
//                       </InputAdornment>
//                     }
//                     // InputProps={{
//                     //   // classes: {
//                     //   //   underline: classes.textFieldUnderline,
//                     //   //   input: classes.textField,
//                     //   // },
//                     //   startAdornment: (
//                     //     <InputAdornment position="start">
//                     //       <LockIcon />
//                     //     </InputAdornment>
//                     //   ),
//                     // }}
//                     value={passwordValue}
//                     onChange={(e) => setPasswordValue(e.target.value)}
//                     margin="normal"
//                     placeholder="Password"
//                     type="password"
//                     variant="standard"
//                     fullWidth
//                   />
//                 </Grid>
//                 <div className={classes.formButtons}>
//                   {isLoading ? (
//                     <CircularProgress
//                       size={26}
//                       className={classes.loginLoader}
//                     />
//                   ) : (
//                     <LoginButton
//                       disabled={
//                         loginValue.length === 0 || passwordValue.length === 0
//                       }
//                       onClick={() =>
//                         loginUser(
//                           userDispatch,
//                           loginValue,
//                           passwordValue,
//                           props.history,
//                           setIsLoading,
//                           setError,
//                           client
//                         )
//                       }
//                       size="medium"
//                       fullWidth
//                       variant="contained"
//                     >
//                       Login
//                     </LoginButton>
//                   )}
//                 </div>
//               </React.Fragment>
//             )}
//           </Grid>
//           <Typography className={classes.copyright}>
//             © 2018-{new Date().getFullYear()}{" "}
//             <a
//               style={{ textDecoration: "none", color: "inherit" }}
//               href="https://flatlogic.com"
//               rel="noopener noreferrer"
//               target="_blank"
//             >
//               Zeliot
//             </a>
//             , All rights reserved.
//           </Typography>
//         </Grid>
//       </Grid>
//       <Grid
//         item
//         xs={6}
//         className={classes.logotypeContainer}
//         style={{
//           display: "inline-block",
//           backgroundColor: "black",
//           overflowY: "hidden",
//         }}
//       >
//         {" "}
//         <img src="./LoginImage.png" alt="logo" />
//         {/* <Slider {...settings}>
//           <img src="./LoginImage.png" alt="logo" />
//           <img src="./MicrosoftTeams-image2.png" alt="logo" />
//           <img src="./MicrosoftTeams-image3.png" alt="logo" />
//         </Slider> */}
//       </Grid>
//     </Grid>
//   );
// }

// export default withRouter(Login);
import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
//import classnames from "classnames";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';

import { GreenTextField, LoginButton } from "../../hoc/PaletteComponent";


// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
//import google from "../../images/google.svg";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

function Login(props) {
  var classes = useStyles();
  const settings = {
    // dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
  };
  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [invalid, setInvalid] = useState(false);
  const client = useApolloClient();

  const [showPassword, setShowPassword] = useState(false);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      loginUser(
        userDispatch,
        loginValue,
        passwordValue,
        props.history,
        setIsLoading,
        setError,
        client
      );
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Grid
              item
              style={{
                margin: "20vh 10vw",
                // height: "60vh",
                border: "solid 1px black",
                boxShadow: "0 0 2px black",
              }}
            >
              <Grid item>
                <Grid container className={classes.innerContainer}>
                  <Grid item style={{ padding: "5vh 4vw" }}>
                    <Grid item>
                      <Typography variant="h4">Hey! Welcome Back</Typography>
                    </Grid>
                    <Grid item variant="h6" style={{ marginTop: "0.6vh" }}>
                      Please enter the details
                    </Grid>
                    <Grid item style={{ marginTop: "10vh" }}>
                      <Grid item xs={12}>
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon />
                              </InputAdornment>
                            ),
                          }}
                          autoComplete="true"
                          value={loginValue}
                          fullWidth="true"
                          id="userName"
                          placeholder="username"
                          variant="standard"
                          onChange={(e) => setLoginValue(e.target.value)}
                          sx={{
                            "& .MuiInput-underline::after": {
                              borderBottomColor: "#57B793",
                            },
                          }}
                          onKeyPress={handleKeyPress}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "4vh" }}>
                        <TextField
                          type={showPassword ? "text" : "password"}
                          autoComplete="true"
                          fullWidth
                          id="password"
                          placeholder="Password"
                          variant="standard"
                          value={passwordValue}
                          onChange={(e) => setPasswordValue(e.target.value)}
                          sx={{
                            "& .MuiInput-underline::after": {
                              borderBottomColor: "#57B793",
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {showPassword ? (
                                    <Tooltip title="Hide" placement="right">
                                      <VisibilityOutlinedIcon />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Show" placement="right">
                                      <VisibilityOffOutlinedIcon />
                                    </Tooltip>
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onKeyPress={handleKeyPress}
                        />
                      </Grid>

                      <Fade in={error}>
                        <Typography
                          color="error"
                          // className={classes.errorMessage}
                        >
                          {invalid
                            ? "Please fill the details"
                            : "Invalid Username or Password"}
                        </Typography>
                      </Fade>

                      <Grid item xs={12} style={{ marginTop: 50 }}>
                        <LoginButton
                          size="medium"
                          // disabled={passwordValue == "" || loginValue == ""}
                          fullWidth
                          variant="contained"
                          disabled={passwordValue == "" || loginValue == ""}
                          onClick={() => {
                            if (passwordValue == "" || loginValue == "") {
                              setInvalid(true);
                            }
                            loginUser(
                              userDispatch,
                              loginValue,
                              passwordValue,
                              props.history,
                              setIsLoading,
                              setError,
                              client
                            );
                          }}
                        >
                          login
                        </LoginButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <img
                src="./LoginImage.png"
                alt="logo"
                style={{ maxWidth: "auto", width: "100%" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(Login);
