import {
  Button,
  Table,
  TableBody,
  TableCell,
  TablePagination,
} from "@mui/material";
import {
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CustomCircularProgress,
  CustomDialogBox,
  CustomValidate,
  NoData,
} from "../../hoc/CustomComponents";
import { ColorButton, CustomButton } from "../../hoc/PaletteComponent";
import useStyles from "./styles";
import AddNewCampaign from "./AddNewCampaign";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { CProgress, CProgressBar } from "@coreui/react";
import { debounce } from "throttle-debounce";

const GET_ALL_CAMPAIGN = gql`
  query($oemId: Int!, $offset: Int!, $limit: Int!) {
    getAllEcuCampaign(oemId: $oemId, offset: $offset, limit: $limit) {
      code
      message
      totalCount
      campaigns {
        id
        status
        progress
        createdOn
        campaignName
        scheduleType
        ecuGroup
        ecuName
        fileType
      }
    }
  }
`;

const columns = [
  {
    name: "Sr.No",
    minWidth: 150,
  },
  {
    name: "Campaign Name",
    minWidth: 250,
  },
  {
    name: "Type",
    minWidth: 250,
  },
  {
    name: "Campaign Progress",
    minWidth: 200,
  },
  {
    name: "Created On",
    minWidth: 200,
  },
  {
    name: "Status",
    minWidth: 230,
  },
  {
    name: "OTP Validation",
    minWidth: 150,
  },
  // {
  //   name: "Action",
  //   minWidth: 150,
  // },
];

const getFeatureAccess = gql`
  query($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const GET_SCHEDULE_TYPE = gql`
  query {
    getScheduleType {
      code
      message
      data {
        id
        scheduleName
        createdOn
      }
    }
  }
`;

const GET_FILE_TYPES = gql`
  query {
    getFileType {
      code
      message
      data {
        id
        fileTypeName
        createdOn
      }
    }
  }
`;

const GET_ECU_GROUPS = gql`
  query($oemId: Int!) {
    getOemEcuGroup(oemId: $oemId) {
      code
      message
      data {
        id
        oemId
        ecu_group_name
        createdOn
      }
    }
  }
`;

const GET_ECU_NAME = gql`
  query($groupId: Int!) {
    getOemEcuName(groupId: $groupId) {
      code
      message
      data {
        id
        ecu_group_id
        ecu_name
        createdOn
      }
    }
  }
`;

const GET_ALL_VIN_IMEI_BY_OEM = gql`
  query($oemId: Int!) {
    getAllVinImeiByOem(oemId: $oemId) {
      code
      message
      data {
        id
        imei
        vin
      }
    }
  }
`;

const GET_FILES = gql`
  query GetEcuCampaignFile(
    $oemId: Int!
    $fileTypeId: Int
    $scheduleTypeId: Int
    $ecuGroupId: Int
    $ecuId: Int
  ) {
    getEcuCampaignFile(
      oemId: $oemId
      fileTypeId: $fileTypeId
      scheduleTypeId: $scheduleTypeId
      ecuGroupId: $ecuGroupId
      ecuId: $ecuId
    ) {
      code
      message
      data {
        id
        filename
        fileTypeId
        scheduleTypeId
      }
    }
  }
`;

const OTP_VALIDATION = gql`
  mutation($campaignId: Int!, $oemId: Int!, $Otp: Int!) {
    approveEcuOtaCampaign(campaignId: $campaignId, oemId: $oemId, Otp: $Otp) {
      code
      status
      remarks
    }
  }
`;

const OTP_RESEND = gql`
  mutation($oemId: Int) {
    resendOEMOtp(oemId: $oemId) {
      code
      status
      remarks
    }
  }
`;

const REJECT_CAMAPIGN = gql`
  mutation($campaignId: Int!, $oemId: Int, $Otp: Int) {
    rejectEcuOtaCampaign(campaignId: $campaignId, oemId: $oemId, Otp: $Otp) {
      code
      status
      remarks
    }
  }
`;
const CREATE_CAMPAIGN = gql`
  mutation(
    $oemId: Int!
    $fileId: Int!
    $expiry: Int!
    $description: String
    $vinImei: [VinObj]
  ) {
    createEcuOtaCampaign(
      oemId: $oemId
      fileId: $fileId
      expiry: $expiry
      description: $description
      vinImei: $vinImei
    ) {
      code
      message
    }
  }
`;
function Files(props) {
  const [openUpload, setOpenUpload] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [scheduleType, setScheduleType] = useState(null);
  const [ecuGroup, setEcuGroup] = useState(null);
  const [ecuName, setEcuName] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [description, setDescription] = useState(null);
  const [allScheduleTypes, setAllScheduleTypes] = useState(null);
  const [allEcuGroups, setAllEcuGroups] = useState([]);
  const [allEcuNames, setAllEcuNames] = useState([]);
  const [allFileTypes, setAllFileTypes] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [readPermission, setReadPermission] = useState(false);
  const [upload, setUpload] = useState(null);
  const [allCampaign, setAllCampaign] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [allData, setAlldata] = useState([]);
  const [validate, setValidate] = useState(false);
  const [otpInput, setOtpInput] = useState();
  const [isResendOtpSent, setisResendOtpSent] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [wrongOtp, setwrongOtp] = useState(false);
  const history = useHistory();
  const [allVINs, setAllVINs] = useState([]);
  const [selectedVin, setSelectedVin] = useState([]);
  const [isExpiryValid, setIsExpiryValid] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [bulkConfirm, setBulkConfirm] = useState(false);
  const [filteredRows, setFilteredRows] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [bulkRows, setBulkRows] = useState([]);
  const [openSelectVin, setOpenSelectVin] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("");
  const [fileUploadName, setFileUploadName] = useState("FileName");
  const [dragActive, setDragActive] = React.useState(false);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [uploadInterval, setUploadInterval] = useState(null);
  const [progressNumber, setProgressNumber] = useState(0);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const inputRef = React.useRef(null);

  const onButtonClick = () => {
    inputRef.current.click();
  };

  /**
   * @summary This redirect to all Vin campaign page
   */
  const ViewVinCampaign = (row, createdDate) => {
    localStorage.setItem("campaignId", row.id);
    localStorage.setItem("campaignName", row.campaignName);
    localStorage.setItem("scheduleType", row.scheduleType);
    localStorage.setItem("ecuGroup", row.ecuGroup);
    localStorage.setItem("ecuName", row.ecuName);
    localStorage.setItem("fileType", row.fileType);
    localStorage.setItem("createdOn", createdDate);
    history.push({
      pathname: "/app/vinCampaign",
      campaignId: row.id,
      campaignName: row.campaignName,
      scheduleType: row.scheduleType,
      ecuGroup: row.ecuGroup,
      ecuName: row.ecuName,
      fileType: row.fileType,
      createdOn: createdDate,
    });
  };

  let [next, setNext] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const classes = useStyles();
  let id;
  if (typeof props.featureId != "undefined") {
    localStorage.setItem("featureId", props.featureId);
    id = props.featureId;
    id = 15;
  } else {
    id = 15;
  }

  console.log(id, "id");

  /**
   * @summary handles Search value
   */
  const handleSearchChange = (event) => {
    // setSearchText(event.target.value);
    setTimeout(debounceSearchRender(event.target.value), 2000);
  };
  const debounceSearchRender = debounce(1000, async (event) => {
    if (event) {
      const filteredRows = allData.filter((row) => {
        const searchText = event.toLowerCase();
        let createdDate = row.createdOn
          ? moment.unix(row.createdOn).format("DD-MM-YYYY HH:mm:ss")
          : null;
        return (
          (row.campaignName &&
            row.campaignName.toLowerCase().includes(searchText)) ||
          (row.scheduleType &&
            row.scheduleType.toLowerCase().includes(searchText)) ||
          (row.status && row.status.toLowerCase().includes(searchText)) ||
          (createdDate && createdDate.toLowerCase().includes(searchText))
        );
      });
      console.log(filteredRows, "filteredRows");
      setAllCampaign(filteredRows);
    } else {
      getAllCamapaign({
        variables: {
          oemId: props.oem,
          limit: 5,
          offset: 0,
        },
      });
    }
  });

  const [getAccess, { data: access }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });

  /**
   * @summary This handles the page change in pagination
   */
  const handleChangePage = async (event, newPage) => {
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    await getAllCamapaign({
      variables: {
        oemId: props.oem,
        limit: rowsPerPage,
        offset: newPage,
      },
    });
  };

  /**
   * @summary This handles the row change in pagination
   */
  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
    await getAllCamapaign({
      variables: {
        oemId: props.oem,
        limit: event.target.value,
        offset: 0,
      },
    });
  };

  /**
   * @summary This opens the modal to upload file
   */
  const handleUpload = () => {
    setOpenUpload(true);
  };

  /**
   * @summary This closes the modal and clear the states
   */
  const handleClose = () => {
    setOpenUpload(false);
    setScheduleType(null);
    setEcuGroup(null);
    setEcuName(null);
    setFileType(null);
    setFileName(null);
    setDescription(null);
    setUpload(false);
    setIsExpiryValid(true);
    setExpiryDate(null);
    setSelectedVin(null);
    setActiveTab(0);
    setProgressNumber(0);
    setActiveStep(0);
    setConfirm(false);
    setErrorCount(0);
    setIsFileUploaded(false);
  };

  /**
   * @summary This opens the Confirm Modal
   */
  const handleDone = () => {
    setOpenConfirm(true);
  };

  /**
   * @summary This handle the schedulType state
   */
  const handleScheduleType = (e, select) => {
    if (select) {
      setScheduleType(select);
    } else {
      setScheduleType(null);
    }
  };

  /**
   * @summary This handles the ecuGroup state
   */
  const handleEcuGroup = (e, select) => {
    if (select) {
      setEcuGroup(select);
      setEcuName(null);
      getAllEcuName({
        variables: {
          groupId: select.id,
        },
      });
    } else {
      setEcuName(null);
      setEcuGroup(null);
    }
  };

  /**
   * @summary This handles the ecu Name state
   */
  const handleEcuName = (e, select) => {
    if (select) {
      setEcuName(select);
    } else {
      setEcuName(null);
    }
  };

  const handleOnBlur = () => {
    getAllFiles({
      variables: {
        oemId: props.oem,
        fileTypeId: fileType ? fileType.id : fileType,
        scheduleTypeId: scheduleType ? scheduleType.id : scheduleType,
        ecuGroupId: ecuGroup ? ecuGroup.id : ecuGroup,
        ecuId: ecuName ? ecuName.id : ecuName,
      },
    });
  };

  /**
   * @summary This handle the FileType state
   */
  const handleFileType = (e, select) => {
    if (select) {
      setFileType(select);
      setFileName(null);
      console.log(scheduleType, ecuGroup, ecuName);
    } else {
      setFileName(null);
      setFileType(null);
    }
  };

  /**
   * @summary This handle the File state
   */
  const handleFile = (e, select) => {
    if (select) {
      setFileName(select);
    } else {
      setFileName(null);
    }
  };

  /**
   * @summary This handle the Description state
   */
  const handleDescription = (e) => {
    setDescription(e);
  };

  /**
   * @summary This handle the next button state
   */
  const handleNext = (e) => {
    setOpenSelectVin(true);
    setActiveStep(activeStep + 1);
    handleDone(e.target.value);
  };

  /**
   * @summary This handle the back button state in manual selection
   */
  const handleBack = (e) => {
    setOpenSelectVin(false);
    setActiveStep(activeStep - 1);
    handleDone(e.target.value);
    setSelectedVin([]);
    setBulkRows([]);
    setUploadProgress(0);
    setFileUploadName("FileName");
    setTotalRecordsCount(0);
    setSuccessCount(0);
    setErrorCount(0);
    clearInterval(uploadInterval);
    setProgressNumber(0);
    setActiveTab(0);
    setTimeout(() => {
      setUploadStatus("");
    }, 0);
  };

  /**
   * @summary handles the drag file upload
   */
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  /**
   * @summary handle the drop file
   */
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setFileUploadName(file.name);
      handleUploadbar(e.dataTransfer.files);
    }
  };

  /**
   * @summary handles the bulk back button state
   */
  const handleBulkBack = () => {
    setActiveTab(0);
    setSelectedVin([]);
  };

  /**
   * @summary handle the TabChange state
   */
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedVin([]);
    setBulkRows([]);
    setUploadProgress(0);
    setUploadStatus("");
    setFileUploadName("FileName");
    setTotalRecordsCount(0);
    setSuccessCount(0);
    setErrorCount(0);
    clearInterval(uploadInterval);
    setProgressNumber(0);
    setIsFileUploaded(false);
  };

  /**
   * @summary handle the Fileupload state
   */
  const handleFileUpload = async (e) => {
    console.log("callfunction");
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const fileInput = e.target;
      const file = fileInput.files[0];
      setFileUploadName(file.name);
      fileInput.value = "";

      // Reset the uploaded data states
      setBulkRows([]);
      setTotalRecordsCount(0);
      setSuccessCount(0);
      setErrorCount(0);

      setUploadProgress(0);
      handleUploadbar([file]);
      setIsFileUploaded(true);
    }
  };

  useEffect(() => {
    console.log("uploadProgress=", uploadProgress);
    if (uploadProgress >= 100) {
      setUploadStatus("Uploaded");
    } else {
      setUploadStatus("Uploading...");
    }
  }, [uploadProgress]);

  const handleUploadbar = (files) => {
    const formData = new FormData();
    formData.append("file", files[0]);

    const updateProgress = (progress) => {
      setUploadProgress(progress);
      // setProgressNumber(progress);
    };
    let uploadedSize = 0;
    const interval = setInterval(() => {
      updateProgress((prevProgress) => {
        const newProgress = prevProgress + 20;
        return newProgress > 80 ? 80 : newProgress;
      });

      uploadedSize += 20;
    }, 100);

    setUploadInterval(interval);

    try {
      fetch("https://microdev-oem-rest.aquilatrack.com/api/v1/uploadFile", {
        method: "POST",
        body: formData,
        headers: {
          "Cache-Control": "no-store",
          Pragma: "no-cache",
          "If-Modified-Since": "0",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "BAD_REQUEST") {
            const { totalRecords, success, error } = data.count;
            setTotalRecordsCount(totalRecords);
            setSuccessCount(success);
            setErrorCount(error);
            console.log(data.error, "errorerror");
            let successfulVINs = data.error
              .filter((item) => item.errorMsg === "")
              .map((item, index) => ({
                id: index,
                vin: item.vin,
                error: "No error",
              }));
            console.log(successfulVINs, "successsss");
            let vins = successfulVINs.map((item) => ({ vin: item.vin }));
            setSelectedVin(vins);
            if (error === 0) {
              setBulkRows(successfulVINs);
            } else {
              let errorRows = data.error
                .filter((item) => item.errorMsg !== "")
                .map((item, index) => ({
                  id: index,
                  vin: item.vin,
                  error: item.errorMsg,
                }));
              console.log(errorRows, "errorRowsss");
              setBulkRows(errorRows);
            }
          } else {
            alert("File uploaded successfully");
          }
        })
        .finally(() => {
          clearInterval(interval);
          setUploadProgress(100);
        });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  /**
   * @summary handles the api call to get all schedule types
   */
  const [getAllScheduleTypes] = useLazyQuery(GET_SCHEDULE_TYPE, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getScheduleType?.code == 200) {
        let allTypes = [];
        allTypes =
          res.getScheduleType?.data?.length > 0 &&
          res.getScheduleType.data.map((type) => type);
        setAllScheduleTypes(allTypes);
      }
    },
  });

  /**
   * @summary handles the api call to get all file types
   */
  const [getFileType] = useLazyQuery(GET_FILE_TYPES, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getFileType.code == 200) {
        let allFileTypes = [];
        allFileTypes =
          res.getFileType.data?.length > 0 &&
          res.getFileType.data?.map((file) => file);
        setAllFileTypes(allFileTypes);
      }
    },
  });

  /**
   * @summary handles the api call to get all ecu groups
   */
  const [getAllEcuGroups] = useLazyQuery(GET_ECU_GROUPS, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getOemEcuGroup.code == 200) {
        let allGroups = [];
        allGroups =
          res.getOemEcuGroup.data.length > 0 &&
          res.getOemEcuGroup.data.map((group) => group);
        console.log(allGroups);
        setAllEcuGroups(allGroups);
      }
    },
  });

  /**
   * @summary handles the api call to get all ecu names
   */
  const [getAllEcuName] = useLazyQuery(GET_ECU_NAME, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getOemEcuName.code == 200) {
        let allNames = [];
        allNames =
          res.getOemEcuName.data.length > 0 &&
          res.getOemEcuName.data.map((name) => name);
        setAllEcuNames(allNames);
      }
    },
  });

  /**
   * @summary handles the api call to get all files
   */
  const [getAllFiles] = useLazyQuery(GET_FILES, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getEcuCampaignFile.code == 200) {
        let allFiles = [];
        allFiles =
          res.getEcuCampaignFile.data.length > 0 &&
          res.getEcuCampaignFile.data.map((filename) => filename);
        setAllFiles(allFiles);
      } else {
        setAllFiles([]);
      }
    },
  });

  /**
   * @summary api call to fetch all campaigns
   */
  const [fetchAllCamapaign] = useLazyQuery(GET_ALL_CAMPAIGN, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      console.log("ress");
      if (res.getAllEcuCampaign?.campaigns) {
        setAlldata(res.getAllEcuCampaign?.campaigns);
      } else {
        setAlldata([]);
      }
    },
  });

  /**
   * @summary api call to fetch all campaigns
   */
  const [getAllCamapaign, { loading }] = useLazyQuery(GET_ALL_CAMPAIGN, {
    fetchPolicy: "network-only",
    onCompleted: async (res) => {
      console.log("ress");
      if (res.getAllEcuCampaign?.campaigns) {
        setAllCampaign(res.getAllEcuCampaign?.campaigns);
        setTotalCount(res.getAllEcuCampaign?.totalCount);
        await fetchAllCamapaign({
          variables: {
            oemId: props.oem,
            limit: res.getAllEcuCampaign?.totalCount
              ? res.getAllEcuCampaign?.totalCount
              : 0,
            offset: 0,
          },
        });
      } else {
        setAllCampaign([]);
        setTotalCount(0);
      }
    },
  });

  /**
   * @summary api call to fetch all campaigns
   */
  const [getAllVINs] = useLazyQuery(GET_ALL_VIN_IMEI_BY_OEM, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getAllVinImeiByOem?.data) {
        setAllVINs(res.getAllVinImeiByOem.data);
      } else {
        setAllVINs([]);
      }
    },
  });

  /**
   * @summary This closes the snackbar
   */
  const handleSnackbarClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  /**
   * @summary api call to validate/approve otp
   */
  const [otValidation] = useMutation(OTP_VALIDATION, {
    errorPolicy: "all",
    onCompleted: (res) => {
      console.log(res, "res");
      if (res.approveEcuOtaCampaign.code == 200) {
        props.setMessage("Approved Successfully");
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleSnackbarClose();
        setValidate(false);
        handleCancel();
        getAllCamapaign({
          variables: {
            oemId: props.oem,
            limit: 5,
            offset: 0,
          },
        });
      } else {
        setwrongOtp(true);
      }
    },
  });

  const [sendOTPToOEM] = useMutation(OTP_RESEND, {
    errorPolicy: "all",
    onCompleted: (res) => {
      if (res.resendOEMOtp.code == 200) {
        setValidate(true);
      } else {
        props.setMessage("Failed to send  OTP");
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    },
  });

  /**
   * @summary open the validate model
   */
  const handleValidate = async (id) => {
    setCampaignId(id);
    await sendOTPToOEM({
      variables: {
        oemId: props.oem,
      },
    });
  };

  /**
   * @summary handles the validation of otp
   */
  const handleApproveOTP = () => {
    console.log(otpInput, "otp");
    otValidation({
      variables: {
        oemId: props.oem,
        Otp: parseInt(otpInput),
        campaignId: campaignId,
      },
    });
  };

  /**
   * @summary closes the validate model
   */
  const handleCancel = () => {
    setValidate(false);
    setOtpInput(null);
    setwrongOtp(false);
    setisResendOtpSent(false);
  };

  /**
   * @summary api call to resend atp
   */
  const [otpResend] = useMutation(OTP_RESEND, {
    errorPolicy: "all",
    onCompleted: (res) => {
      if (res.resendOEMOtp.code == 200) {
        setisResendOtpSent(true);
      }
    },
  });

  /**
   * @summary handles the resend otp
   */
  const handleResendOTP = () => {
    setOtpInput(null);
    setwrongOtp(false);
    otpResend({
      variables: {
        oemId: props.oem,
      },
    });
  };

  /**
   * @summary handles the  otp value
   */
  const handleOnChange = (e) => {
    setOtpInput(e);
  };

  const [rejectCampaign] = useMutation(REJECT_CAMAPIGN, {
    errorPolicy: "all",
    onCompleted: (res) => {
      if (res.rejectEcuOtaCampaign?.code == 200) {
        props.setMessage("Rejected Successfully");
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleSnackbarClose();
        setValidate(false);
        handleCancel();
        getAllCamapaign({
          variables: {
            oemId: props.oem,
            limit: 5,
            offset: 0,
          },
        });
        getAllVINs({
          variables: {
            oemId: props.oem,
          },
        });
      } else {
        setwrongOtp(true);
      }
    },
  });

  /**
   * @summary handles the  rejection of campaign
   */
  const handleReject = () => {
    rejectCampaign({
      variables: {
        campaignId: campaignId,
        oemId: props.oem,
        Otp: parseInt(otpInput),
      },
    });
  };

  const [createCampaign] = useMutation(CREATE_CAMPAIGN, {
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleClose();
        handleSnackbarClose();
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleClose();
          handleSnackbarClose();
        });
      }
    },
    onCompleted: (res) => {
      console.log(res, "res");
      if (res?.createEcuOtaCampaign?.code == 200) {
        props.setMessage("Successfully created the campaign");
        getAllVINs({
          variables: {
            oemId: props.oem,
          },
        });
      } else {
        props.setMessage("Failed to create the campaign");
      }
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
      handleClose();
      getAllCamapaign({
        variables: {
          oemId: props.oem,
          limit: 5,
          offset: 0,
        },
      });
    },
  });

  /**
   * @summary This handle the expiry date state
   */
  const handleExpiryDate = (e) => {
    const val = parseInt(e.target.value);
    if (val > 0) {
      setExpiryDate(val);
      setIsExpiryValid(true);
    } else {
      setExpiryDate("");
      setIsExpiryValid(false);
    }
  };

  const checKExpiryLength = () => {
    if (expiryDate && expiryDate.toString().length > 10) {
      setIsExpiryValid(false);
    } else {
      setIsExpiryValid(true);
    }
  };

  const handleConfirm = async () => {
    setConfirm(true);
  };

  const handleAddCampaign = async () => {
    let allVin = selectedVin.map((vin) => {
      return {
        vin: vin.vin,
      };
    });
    console.log(allVin, "allVin");

    let variables = {
      oemId: props.oem,
      expiry: parseInt(expiryDate),
      fileId: fileName.id,
      vinImei: allVin,
    };
    console.log(variables, "variables");
    await createCampaign({
      variables: {
        oemId: props.oem,
        expiry: parseInt(expiryDate),
        fileId: fileName.id,
        vinImei: allVin,
        description: description,
      },
    });
  };

  const handleBackConfirm = () => {
    setConfirm(false);
  };

  const handleBulkConfirm = () => {
    setBulkConfirm(true);
  };
  useEffect(() => {
    getAllScheduleTypes();
    getFileType();
    getAllEcuGroups({
      variables: {
        oemId: props.oem,
      },
    });
    getAllCamapaign({
      variables: {
        oemId: props.oem,
        limit: 5,
        offset: 0,
      },
    });
    getAccess({
      variables: {
        featureId: id,
      },
    });
    getAllVINs({
      variables: {
        oemId: props.oem,
      },
    });
  }, [props.oem]);
  return (
    <Grid container>
      <CustomValidate
        open={validate}
        handleValidateClick={handleApproveOTP}
        otpInput={otpInput}
        handleOnChange={handleOnChange}
        handleCancel={handleCancel}
        handleResendOTP={handleResendOTP}
        isResendOtpSent={isResendOtpSent}
        wrongOtp={wrongOtp}
        handleReject={handleReject}
      />
      <AddNewCampaign
        open={openUpload}
        handleClose={handleClose}
        allScheduleTypes={allScheduleTypes}
        allEcuGroups={allEcuGroups}
        allEcuNames={allEcuNames}
        allFileTypes={allFileTypes}
        allFiles={allFiles}
        handleDone={handleDone}
        handleScheduleType={handleScheduleType}
        handleEcuGroup={handleEcuGroup}
        handleEcuName={handleEcuName}
        handleFileType={handleFileType}
        handleFile={handleFile}
        handleDescription={handleDescription}
        handleExpiryDate={handleExpiryDate}
        scheduleType={scheduleType}
        ecuGroup={ecuGroup}
        ecuName={ecuName}
        fileType={fileType}
        isUpload={upload}
        fileName={fileName}
        description={description}
        expiryDate={expiryDate}
        allVINs={allVINs}
        selectedVin={selectedVin}
        setSelectedVin={setSelectedVin}
        handleConfirm={handleConfirm}
        handleOnBlur={handleOnBlur}
        checKExpiryLength={checKExpiryLength}
        isExpiryValid={isExpiryValid}
        handleBulkConfirm={handleBulkConfirm}
        filteredRows={filteredRows}
        setFilteredRows={setFilteredRows}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        bulkRows={bulkRows}
        setBulkRows={setBulkRows}
        openSelectVin={openSelectVin}
        setOpenSelectVin={setOpenSelectVin}
        uploadProgress={uploadProgress}
        setUploadProgress={setUploadProgress}
        uploadStatus={uploadStatus}
        setUploadStatus={setUploadStatus}
        fileUploadName={fileUploadName}
        setFileUploadName={setFileUploadName}
        dragActive={dragActive}
        setDragActive={setDragActive}
        handleNext={handleNext}
        handleBack={handleBack}
        handleBulkBack={handleBulkBack}
        handleTabChange={handleTabChange}
        handleFileUpload={handleFileUpload}
        handleUploadbar={handleUploadbar}
        handleDrag={handleDrag}
        handleDrop={handleDrop}
        inputRef={inputRef}
        onButtonClick={onButtonClick}
        totalRecordsCount={totalRecordsCount}
        successCount={successCount}
        errorCount={errorCount}
        progressNumber={progressNumber}
        isFileUploaded={isFileUploaded}
      />
      <CustomDialogBox
        open={confirm}
        content={`${
          errorCount == 0
            ? "Are you sure you want to create Campaign "
            : `There are errors in your file upload. Are you sure you want to proceed with ${successCount} records`
        }?`}
        negativeResponseHandler={handleBackConfirm}
        negativeResponseButtonText="Back"
        positiveResponseHandle={handleAddCampaign}
        positiveResponseButtonText="Done"
        largeSize={errorCount != 0 ? true : false}
      />
      <Grid item xs={12} style={{ backgroundColor: "#FFFFFF", marginTop: 20 }}>
        <Grid container>
          <Grid item xs={12} style={{ paddingTop: "4vh" }}>
            <Grid container>
              <Grid item xs={12} sm={6} style={{ paddingLeft: "2vw" }}>
                <TextField
                  placeholder="Search Here"
                  variant="outlined"
                  style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                  size="small"
                  className={classes.textfield}
                  onChange={(e) => handleSearchChange(e)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ paddingLeft: "3px" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6} sm={6}>
                {createPermission ? (
                  <Grid container justifyContent="flex-end">
                    <Grid
                      item
                      xs={4}
                      md={4}
                      sm={6}
                      style={{ paddingRight: "2vw" }}
                    >
                      <ColorButton onClick={handleUpload}>
                        <Typography className={classes.buttonText}>
                          New Campaign
                        </Typography>
                      </ColorButton>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "4vh" }}>
            {loading ? (
              <CustomCircularProgress />
            ) : totalCount > 0 ? (
              <>
                <TableContainer
                  style={{
                    maxHeight: 440,
                  }}
                >
                  <Table stickHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <>
                            <TableCell
                              key={column.id}
                              align="center"
                              style={{
                                minWidth: column.minWidth,
                                font: "normal normal 600 15px/20px Nunito Sans",
                                letterSpacing: "0px",
                                color: "#212121",
                                borderRightWidth: 1,
                                borderColor: "#E8E8E8",
                                borderTop: "1px solid #E8E8E8",
                                backgroundColor: "#faf8f7",
                              }}
                            >
                              {column.name}
                            </TableCell>
                          </>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allCampaign &&
                        allCampaign.map((row, index) => {
                          let createdDate = row.createdOn
                            ? moment
                                .unix(row.createdOn)
                                .format("DD-MM-YYYY HH:mm:ss")
                            : null;
                          return (
                            <TableRow>
                              <TableCell
                                align="center"
                                className={classes.tableContent}
                              >
                                {next + (index + 1)}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableContent}
                                onClick={() => {
                                  if (row.status !== "PENDING FOR APPROVAL") {
                                    ViewVinCampaign(row, createdDate);
                                  }
                                }}
                                style={{
                                  cursor: "pointer",
                                  color:
                                    row.status !== "PENDING FOR APPROVAL"
                                      ? "#5CB593"
                                      : "none",
                                  textDecoration:
                                    row.status !== "PENDING FOR APPROVAL"
                                      ? "underline"
                                      : "none",
                                }}
                              >
                                {row.campaignName}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableContent}
                              >
                                {row.scheduleType}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableContent}
                              >
                                <Grid item style={{ height: "1px" }}>
                                  <CProgress className="mb-3">
                                    <CProgressBar
                                      color="success"
                                      variant="striped"
                                      animated
                                      value={row.progress}
                                    >
                                      {row.progress}%
                                    </CProgressBar>
                                  </CProgress>
                                </Grid>
                              </TableCell>
                              <TableCell
                                align="center"
                                // className={classes.tableContent}
                              >
                                {createdDate}
                              </TableCell>
                              <TableCell
                                align="center"
                                // className={classes.tableContent}
                                style={{
                                  backgroundColor:
                                    row.status == "REJECTED" ||
                                    row.status == "EXPIRED"
                                      ? "#FFEDF0"
                                      : row.status == "COMPLETED"
                                      ? "#E0FFF3"
                                      : "#FDF6E0",
                                  color:
                                    row.status == "REJECTED" ||
                                    row.status == "EXPIRED"
                                      ? "#FF7E80"
                                      : row.status == "COMPLETED"
                                      ? "#5DBA97"
                                      : "#F5C84E",
                                  borderColor:
                                    row.status == "REJECTED" ||
                                    row.status == "EXPIRED"
                                      ? "#FF7E80"
                                      : row.status == "COMPLETED"
                                      ? "#5DBA97"
                                      : "#F5C84E",
                                  // borderLeftWidth: 1,
                                  border: "1.5px solid",

                                  // opacity: 0.5,
                                }}
                              >
                                {row.status}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableContent}
                              >
                                <CustomButton
                                  disabled={
                                    row.status !== "PENDING FOR APPROVAL"
                                  }
                                  onClick={() => handleValidate(row.id)}
                                >
                                  <span
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    validate
                                  </span>
                                </CustomButton>
                              </TableCell>
                              {/* <TableCell
                                align="center"
                                className={classes.tableContent}
                              >
                                <Grid
                                  container
                                  alignContent="center"
                                  justifyContent="center"
                                  style={{
                                    height: "1px",
                                  }}
                                >
                                  <Grid item>
                                    <Button>
                                      <DeleteIcon
                                        style={{
                                          fill: "#2b2924",
                                        }}
                                      />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={totalCount ? totalCount : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <NoData />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default withSnackbar(Files);
