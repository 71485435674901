import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Button,
  Dialog,
} from "@mui/material";

import { useEffect, useState } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ColorButton, ColorSampleButton } from "../../hoc/PaletteComponent";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@material-ui/core";
const GET_MAPPED_VIN = gql`
  query ($oemId: Int!) {
    getMappedVins(oemId: $oemId) {
      code
      message
      rows {
        vin
        imei
      }
    }
  }
`;

const GET_LATEST_DETAILS = gql`
  query ($Vin: String!) {
    getDeviceDetailsByVin(Vin: $Vin) {
      code
      message
      data {
        vin
        imei
        networkOperator
        boxCoverStatus
        address
        tcuBatlevel
        firmwareVersion
        serverTS

        latitude
        longitude
        gsmSignal
        ticketId
        ignitionTS
        vehBatVol
        fuelTemp
        adblueLevel
        odoMeter
        hourMeter
        dtc
        warningLight
        cleanerChokeIndicator
        lowOilPressure
        oilPassFilter
        dpfLampIndicator
        dpfActiveRegeneration
        dpfInhibitRegeneration
        massPercentage
        waterInFuelLevel
        torqueReduction
        speedReduction
        ecoMode
        engineRmp
        coolantTemp
        oilTemp
        fuelTemp
        gearIndicator
        latitude
        longitude
        createdByName
        createdById
        fileName
        helpButtonStatus
      }
    }
  }
`;

const ADD_EOLT_Report = gql`
  mutation (
    $oemId: Int!
    $vin: String!
    $imei: String!
    $networkOperator: String
    $boxCoverStatus: Boolean
    $gsmSignal: String
    $address: String
    $tcuBatlevel: String
    $firmwareVersion: String
    $serverTS: String
    $ignitionTS: String
    $vehBatVol: String
    $fuelLevel: String
    $adblueLevel: String
    $odoMeter: String
    $hourMeter: String
    $createdById: Int
    $dtc: String
    $warningLight: String
    $cleanerChokeIndicator: String
    $lowOilPressure: String
    $oilPassFilter: String
    $dpfLampIndicator: String
    $dpfActiveRegeneration: String
    $dpfInhibitRegeneration: String
    $massPercentage: String
    $waterInFuelLevel: String
    $torqueReduction: String
    $speedReduction: String
    $ecoMode: String
    $engineRmp: String
    $coolantTemp: String
    $oilTemp: String
    $fuelTemp: String
    $gearIndicator: String
    $helpButtonStatus: Boolean
    $scope: String
  ) {
    addEoltReport(
      oemId: $oemId
      vin: $vin
      imei: $imei
      networkOperator: $networkOperator
      boxCoverStatus: $boxCoverStatus
      gsmSignal: $gsmSignal
      address: $address
      tcuBatlevel: $tcuBatlevel
      firmwareVersion: $firmwareVersion
      serverTS: $serverTS
      ignitionTS: $ignitionTS
      vehBatVol: $vehBatVol
      fuelLevel: $fuelLevel
      adblueLevel: $adblueLevel
      odoMeter: $odoMeter
      hourMeter: $hourMeter
      createdById: $createdById
      dtc: $dtc
      warningLight: $warningLight
      cleanerChokeIndicator: $cleanerChokeIndicator
      lowOilPressure: $lowOilPressure
      oilPassFilter: $oilPassFilter
      dpfLampIndicator: $dpfLampIndicator
      dpfActiveRegeneration: $dpfActiveRegeneration
      dpfInhibitRegeneration: $dpfInhibitRegeneration
      massPercentage: $massPercentage
      waterInFuelLevel: $waterInFuelLevel
      torqueReduction: $torqueReduction
      speedReduction: $speedReduction
      ecoMode: $ecoMode
      engineRmp: $engineRmp
      coolantTemp: $coolantTemp
      oilTemp: $oilTemp
      fuelTemp: $fuelTemp
      helpButtonStatus: $helpButtonStatus
      gearIndicator: $gearIndicator
      scope: $scope
    ) {
      code
      message
    }
  }
`;

const TcuHealthParameters = (props) => {
  const [columns, setColumn] = useState([
    {
      name: "TCU IMEI mapping",

      value: null,
    },
    {
      name: "Network operator",

      value: null,
    },
    {
      name: "Box cover status",
      value: null,
    },
    {
      name: "GSM Signal",
      value: null,
    },
    {
      name: "Location",
      value: null,
    },
    {
      name: "TCU battery level",
      value: null,
    },
    {
      name: "TCU Firmware version",
      value: null,
    },
    {
      name: "Recent DATA timestamp",
      value: null,
    },
    {
      name: "Emergency Button Status",
      value: null,
    },
  ]);

  const [declaration, setDeclaration] = useState(false);

  const [health, setHealth] = useState([
    {
      name: "Recent ignition on time",
      value: null,
    },
    {
      name: "Vehicle Battery voltage",
      value: null,
    },
    {
      name: "Fuel level",
      value: null,
    },
    {
      name: "Ad-blue leve",
      value: null,
    },
    {
      name: "Odometer reading in kms",
      value: null,
    },
    {
      name: "Hourmeter reading",
      value: null,
    },
    {
      name: "Active DTC",
      value: null,
    },
    {
      name: "Active Warning lights",
      value: null,
    },
    {
      name: "Air cleaner choke indicator",
      value: null,
    },
    {
      name: "low oil pressure",
      value: null,
    },
    {
      name: "Oil by pass filter",
      value: null,
    },
    {
      name: "DPF LAMP INDICATOR",
      value: null,
    },
    {
      name: "Recent ignition on time",
      value: null,
    },
    {
      name: "DPF active regeneration status",
      value: null,
    },
    {
      name: "Dpf regeneration inhibit status",
      value: null,
    },
  ]);

  let [getLatestpacket, { data, loading }] = useLazyQuery(GET_LATEST_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      console.log(declaration, "decl");

      if (res.getDeviceDetailsByVin.data) {
        let eoltDet = data?.getDeviceDetailsByVin?.data;

        if (eoltDet) {
          props.setSnackbar(false);
          let latestDate = eoltDet.serverTS
            ? moment.unix(eoltDet.serverTS).format("DD-MM-YYYY HH:mm:ss")
            : "";
          let dataoBj = [
            {
              name: "TCU IMEI mapping",
              value: eoltDet.imei,
            },
            {
              name: "Network operator",

              value: eoltDet.networkOperator,
            },
            {
              name: "Box cover status",
              value: eoltDet.boxCoverStatus ? "Open" : "Closed",
            },
            {
              name: "GSM Signal",
              value: eoltDet.gsmSignal,
            },
            {
              name: "Location",
              value: eoltDet.address || "NA",
            },
            {
              name: "TCU battery level",
              value: eoltDet.tcuBatlevel + "%",
            },
            {
              name: "TCU Firmware version",
              value: eoltDet.firmwareVersion,
            },
            {
              name: "Recent DATA timestamp",
              value: latestDate,
            },
            {
              name: "Emergency Button Status",
              value: eoltDet.helpButtonStatus ? "ON" : "OFF",
            },
          ];
          setColumn([...dataoBj]);
        } else {
          setColumn([
            {
              name: "TCU IMEI mapping",

              value: null,
            },
            {
              name: "Network operator",

              value: null,
            },
            {
              name: "Box cover status",
              value: null,
            },
            {
              name: "GSM Signal",
              value: null,
            },
            {
              name: "Location",
              value: null,
            },
            {
              name: "TCU battery level",
              value: null,
            },
            {
              name: "TCU Firmware version",
              value: null,
            },
            {
              name: "Recent DATA timestamp",
              value: null,
            },
            {
              name: "Emergency Button Status",
              value: null,
            },
          ]);
        }
      } else {
        setColumn([
          {
            name: "TCU IMEI mapping",

            value: null,
          },
          {
            name: "Network operator",

            value: null,
          },
          {
            name: "Box cover status",
            value: null,
          },
          {
            name: "GSM Signal",
            value: null,
          },
          {
            name: "Location",
            value: null,
          },
          {
            name: "TCU battery level",
            value: null,
          },
          {
            name: "TCU Firmware version",
            value: null,
          },
          {
            name: "Recent DATA timestamp",
            value: null,
          },
          {
            name: "Emergency Button Status",
            value: null,
          },
        ]);
      }
    },
  });

  const [addEOLTReport, { data: ReportData }] = useMutation(ADD_EOLT_Report, {
    errorPolicy: "all",
    onCompleted: (res) => {
      if (res.addEoltReport.code == 200) {
        props.setMessage("EOLT report submitted successfully");
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleSnackbarClose();
        props.getDropDownVins({
          variables: {
            oemId: props.oem,
            eoltCheck: true,
          },
        });
        props.getEoltDetails({
          variables: {
            oemId: props.oem,
          },
        });
        props.closeModal();
      } else {
        props.setMessage(res?.addEoltReport.message || "Failed to add details");
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    },
  });

  useEffect(() => {
    getLatestpacket({
      variables: {
        Vin: props.vin,
      },
    });
    setDeclaration(false);
  }, [props.vin]);

  const handleSnackbarClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  const handleDeclaration = () => {
    if (declaration) {
      setDeclaration(false);
    } else {
      setDeclaration(true);
    }
  };

  const handleSubmit = (scope) => {
    let eoltDet = data.getDeviceDetailsByVin.data;
    addEOLTReport({
      variables: {
        scope: scope,
        oemId: props.oem,
        vin: eoltDet.vin,
        imei: eoltDet.imei,
        networkOperator: eoltDet.networkOperator,
        boxCoverStatus: eoltDet.boxCoverStatus,
        gsmSignal: eoltDet.gsmSignal,
        address: eoltDet.address,
        tcuBatlevel: eoltDet.tcuBatlevel,
        firmwareVersion: eoltDet.firmwareVersion,
        serverTS: eoltDet.serverTS,
        ignitionTS: eoltDet.ignitionTS,
        vehBatVol: eoltDet.vehBatVol,
        fuelLevel: eoltDet.fuelLevel,
        adblueLevel: eoltDet.adblueLevel,
        odoMeter: eoltDet.odoMeter,
        hourMeter: eoltDet.hourMeter,
        createdById: props.token.id,
        dtc: eoltDet.dtc,
        warningLight: eoltDet.warningLight,
        cleanerChokeIndicator: eoltDet.cleanerChokeIndicator,
        lowOilPressure: eoltDet.lowOilPressure,
        oilPassFilter: eoltDet.oilPassFilter,
        dpfLampIndicator: eoltDet.dpfLampIndicator,
        dpfActiveRegeneration: eoltDet.dpfActiveRegeneration,
        dpfInhibitRegeneration: eoltDet.dpfInhibitRegeneration,
        massPercentage: eoltDet.massPercentage,
        waterInFuelLevel: eoltDet.waterInFuelLevel,
        torqueReduction: eoltDet.torqueReduction,
        speedReduction: eoltDet.speedReduction,
        ecoMode: eoltDet.ecoMode,
        engineRmp: eoltDet.engineRmp,
        coolantTemp: eoltDet.coolantTemp,
        oilTemp: eoltDet.oilTemp,
        fuelTemp: eoltDet.fuelTemp,
        helpButtonStatus: eoltDet.helpButtonStatus,
        gearIndicator: eoltDet.gearIndicator,
      },
    });
  };

  return (
    <Dialog
      open={props.open}
      maxWidth={"md"}
      fullWidth={true}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
    >
      <Grid container>
        <Grid item xs={12} style={{ padding: "10px" }}>
          <Grid container>
            <Grid item xs={9}>
              <Typography variant="h4">TCU Health Parameters</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button style={{ float: "right" }} onClick={props.closeModal}>
                <CloseIcon
                  style={{
                    fill: "#2b2924",
                  }}
                />
              </Button>
            </Grid>
            <Grid item xs={12} style={{ margin: "10px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container style={{ display: "flex" }}>
                    {columns &&
                      columns.map((val) => (
                        <Grid item xs={6} mt={2}>
                          <Grid item>
                            <Typography style={{ fontSize: "1.4rem" }}>
                              {val.name}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              style={{ fontWeight: "25px", color: "grey" }}
                            >
                              {loading ? (
                                <CircularProgress
                                  style={{ color: "#57B793" }}
                                  size={20}
                                />
                              ) : (
                                val.value || "NA"
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    paddingLeft: "2rem",
                    paddingTop: "1rem",
                  }}
                ></Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    display={"flex"}
                    justifyContent="space-between"
                    xs={12}
                    mt={2}
                  >
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={declaration}
                            onChange={handleDeclaration}
                          />
                        }
                        label=" I hereby confirm that the EOLT information provided in
        this statement is true and accurate to the best of my
        knowledge."
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Grid
                        container
                        spacing={1}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Grid item xs={2}>
                          <ColorSampleButton
                            onClick={(e) => handleSubmit(null)}
                            disabled={declaration || !columns[0].value}
                          >
                            <Typography>Reject</Typography>
                          </ColorSampleButton>
                        </Grid>
                        <Grid item xs={2}>
                          <ColorButton
                            onClick={(e) => handleSubmit("EOLT_COMPLETED")}
                            disabled={!declaration || !columns[0].value}
                          >
                            <Typography>Submit</Typography>
                          </ColorButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default withSnackbar(TcuHealthParameters);
