import { Button, Dialog, DialogContent, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import useStyles from "./styles";
import { PDFReader } from "reactjs-pdf-reader";
export default function ImagePreview({
  previewOpen,
  handlePreviewClose,
  url,
  previewType,
  fileType,
}) {
  const classes = useStyles();
  console.log(
    "url",
    url,
    previewOpen,
    handlePreviewClose,
    fileType,
    previewType
  );
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={previewOpen}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogContent style={{ overflow: "auto", maxHeight: "60vh" }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={10}>
                  {previewType}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => handlePreviewClose()}
                    style={{ float: "right", position: "absolute" }}
                  >
                    <CloseIcon
                      style={{
                        fill: "#2b2924",
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {fileType == "pdf" ? (
                  <PDFReader showAllPage={true} url={url} />
                ) : (
                  <img
                    style={{ width: "90%" }}
                    src={url ? url : null}
                    alt={url ? url : null}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
