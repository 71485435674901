import { useMutation, gql, useLazyQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import CloseIcon from "@mui/icons-material/Close";
import { TableHead } from "mui-datatables";

function EOLTDetails({
  open,
  vin,
  imei,
  postTS,
  deviceTS,
  serverTS,
  lat,
  long,
  plusCode,
  packetType,
  vinAssignedOn,
  handleEoltFistTS,
  status,
}) {
  const columns = [
    {
      name: "VIN",

      value: vin,
    },
    {
      name: "IMEI",

      value: imei,
    },
    {
      name: "VIN Assigned Time",

      value: vinAssignedOn,
    },
    {
      name: "Post VIN Feed",
      value: postTS ? "YES" : "NO",
    },
    {
      name: "Post VIN Feed Time",

      value: postTS || "NA",
    },
    {
      name: "Status",
      value: status,
    },
    {
      name: "Device TS",
      value: deviceTS || "NA",
    },
    {
      name: "Server TS",
      value: serverTS || "NA",
    },
    {
      name: "Latitude",
      value: lat || "NA",
    },
    {
      name: "Longitude",
      value: long || "NA",
    },
    {
      name: "Location",
      value: plusCode || "NA",
    },
    {
      name: "PacketType",
      value: packetType || "NA",
    },
  ];
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12} style={{ padding: "10px" }}>
            <Grid container>
              <Grid item xs={9} style={{ padding: "0px 10px 10px 10px" }}>
                <Typography variant="h4"> EOLT Details</Typography>
              </Grid>
              <Grid item xs={3}>
                <Button style={{ float: "right" }} onClick={handleEoltFistTS}>
                  <CloseIcon
                    style={{
                      fill: "#2b2924",
                    }}
                  />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    {columns.map((column) => {
                      return (
                        <TableRow>
                          <TableCell
                            // align="center"
                            style={{
                              borderRightWidth: 1,
                              borderLeftWidth: 1,

                              borderColor: "#E8E8E8",
                              borderTop: "1px solid #E8E8E8",
                            }}
                          >
                            {column.name}
                          </TableCell>

                          <TableCell
                            // align="center"
                            style={
                              column.value == "YES"
                                ? {
                                    backgroundColor: "#57B793",
                                    borderRightWidth: 1,
                                    borderLeftWidth: 1,
                                    borderTop: "1px solid #E8E8E8",
                                    borderColor: "#E8E8E8",
                                  }
                                : column.name == "Post VIN Feed"
                                ? {
                                    backgroundColor: "red",
                                    borderRightWidth: 1,
                                    borderLeftWidth: 1,
                                    borderTop: "1px solid #E8E8E8",
                                    borderColor: "#E8E8E8",
                                  }
                                : {
                                    borderRightWidth: 1,
                                    borderLeftWidth: 1,
                                    borderTop: "1px solid #E8E8E8",
                                    borderColor: "#E8E8E8",
                                  }
                            }
                          >
                            {column.value}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default EOLTDetails;
