import {
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";
import { CustomSelect, CustomTextField } from "../../hoc/CustomComponents";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";

const allFuelType = ["DIESEL", "CNG"];

function CreateKyc({
  open,
  show,
  handleKycClose,
  oemId,
  imei,
  vin,
  engineNumber,
  yearOfmg,
  vehicleMake,
  vehicleModel,
  fuelType,
  customerID,
  customerName,
  customerMobile1,
  retailInvoiceNumber,
  customerAddress,
  customerEmail,
  proofOfAddress,
  POA,
  setPOA,
  proofOfIdentity,
  POI,
  setPOI,
  dealerCode,
  dealerName,
  dealerAddress,
  dealerTown,
  dealerContactName,
  dealerContactNumber,
  setImei,
  setVin,
  setEngineNumber,
  setYearOfmg,
  setVehicleMake,
  setVehicleModel,
  setFuelType,
  setCustomerID,
  setCustomerName,
  setCustomerMobile1,
  setRetailInvoiceNumber,
  setCustomerAddress,
  setCustomerEmail,
  setProofOfAddress,
  setProofOfIdentity,
  setDealerCode,
  setDealerName,
  setDealerAddress,
  setDealerTown,
  setDealerContactName,
  setDealerContactNumber,
  onUploadProofOfAddress,
  onUploadProofOfIdentity,
  handleKycSubmit,
  createloading,
  snackbarOpen,
}) {
  const classes = useStyles();
  /**
   * @summary This handle the Imei value
   */
  const handleImei = (e) => {
    setImei(e);
  };

  /**
   * @summary This handle the VIN value
   */
  const handleVIN = (e) => {
    setVin(e);
  };

  /**
   * @summary This handle the EngineNumber value
   */
  const handleEngineNumber = (e) => {
    setEngineNumber(e);
  };

  /**
   * @summary This handle the YearOfmg value
   */
  const handleYearOfmg = (e) => {
    setYearOfmg(e);
  };

  /**
   * @summary This handle the VehicleMake value
   */
  const handleVehicleMake = (e) => {
    setVehicleMake(e);
  };

  /**
   * @summary This handle the VehicleModel value
   */
  const handleVehicleModel = (e) => {
    setVehicleModel(e);
  };

  /**
   * @summary This handle the fuelType value
   */
  const handleFuelType = (e, fuelType) => {
    setFuelType(fuelType);
  };

  /**
   * @summary This handle the CustomerID value
   */
  const handleCustomerID = (e) => {
    setCustomerID(e);
  };

  /**
   * @summary This handle the CustomerName value
   */
  const handleCustomerName = (e) => {
    setCustomerName(e);
  };

  /**
   * @summary This handle the CustomerMobile1 value
   */
  const handleCustomerMobile1 = (e) => {
    setCustomerMobile1(e);
  };

  /**
   * @summary This handle the RetailInvoiceNumber value
   */
  const handleRetailInvoiceNumber = (e) => {
    setRetailInvoiceNumber(e);
  };

  /**
   * @summary This handle the CustomerAddress value
   */
  const handleCustomerAddress = (e) => {
    setCustomerAddress(e);
  };

  /**
   * @summary This handle the CustomerEmail value
   */
  const handleCustomerEmail = (e) => {
    setCustomerEmail(e);
  };

  /**
   * @summary This handle the ProofOfAddress value
   */
  const handleProofOfAddress = (e) => {
    setProofOfAddress(e);
  };

  /**
   * @summary This handle the ProofOfIdentity value
   */
  const handleProofOfIdentity = (e) => {
    setProofOfIdentity(e);
  };

  /**
   * @summary This handle the DealerCode value
   */
  const handleDealerCode = (e) => {
    setDealerCode(e);
  };

  /**
   * @summary This handle the DealerName value
   */
  const handleDealerName = (e) => {
    setDealerName(e);
  };

  /**
   * @summary This handle the DealerAddress value
   */
  const handleDealerAddress = (e) => {
    setDealerAddress(e);
  };

  /**
   * @summary This handle the DealerTown value
   */
  const handleDealerTown = (e) => {
    setDealerTown(e);
  };

  /**
   * @summary This handle the DealerContactName value
   */
  const handleDealerContactName = (e) => {
    setDealerContactName(e);
  };

  /**
   * @summary This handle the DealerContactNumber value
   */
  const handleDealerContactNumber = (e) => {
    setDealerContactNumber(e);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      handleKycSubmit();
    }
  };

  const fileInputRef = useRef(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const addInputRef = useRef(null);
  const handleClickAdd = () => {
    if (addInputRef.current) {
      addInputRef.current.click();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={9}>
                  <Typography variant="h4">Create Kyc Details</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    style={{ float: "right" }}
                    onClick={() => handleKycClose()}
                  >
                    <CloseIcon
                      style={{
                        fill: "#2b2924",
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <CustomTextField
                    label="VIN*"
                    placeholder="Vin"
                    onChange={(e) => handleVIN(e.target.value)}
                    value={vin ? vin : ""}
                    read={show}
                    disabled={show}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="IMEI*"
                    placeholder="IMEI"
                    onChange={(e) => handleImei(e.target.value)}
                    value={imei ? imei : ""}
                    read={show}
                    disabled={show}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Engine Number*"
                    placeholder="Engine Number"
                    onChange={(e) => handleEngineNumber(e.target.value)}
                    value={engineNumber ? engineNumber : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Year Of Manufacture*"
                    placeholder="Year Of Manufacture"
                    onChange={(e) => handleYearOfmg(e.target.value)}
                    value={yearOfmg ? yearOfmg : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Vehicle Make*"
                    placeholder="Vehicle Make"
                    onChange={(e) => handleVehicleMake(e.target.value)}
                    value={vehicleMake ? vehicleMake : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Vehicle Model*"
                    placeholder="Vehicle Model"
                    onChange={(e) => handleVehicleModel(e.target.value)}
                    value={vehicleModel ? vehicleModel : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomSelect
                    label="Fuel Type*"
                    placeholder="Select Fuel Type"
                    value={fuelType ? fuelType : ""}
                    options={allFuelType}
                    getOptionLabel={(option) => option}
                    handleSelectChange={handleFuelType}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Customer Id*"
                    placeholder="Customer Id"
                    onChange={(e) => handleCustomerID(e.target.value)}
                    value={customerID ? customerID : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Customer Name*"
                    placeholder="Customer Name"
                    onChange={(e) => handleCustomerName(e.target.value)}
                    value={customerName ? customerName : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Customer Mobile*"
                    placeholder="Customer Mobile"
                    onChange={(e) => handleCustomerMobile1(e.target.value)}
                    value={customerMobile1 ? customerMobile1 : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Customer Address*"
                    placeholder="Customer Address"
                    onChange={(e) => handleCustomerAddress(e.target.value)}
                    value={customerAddress ? customerAddress : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Customer Email*"
                    placeholder="Customer Email"
                    onChange={(e) => handleCustomerEmail(e.target.value)}
                    value={customerEmail ? customerEmail : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  {proofOfAddress ? (
                    <>
                      <FormHelperText
                        // className={classes.label}
                        style={{
                          font: "normal normal 500 13px/20px poppins",
                          textAlign: "left",
                          letterSpacing: "0px",
                          color: "#707070",
                          paddingBottom: 8,
                          marginLeft: 18,
                        }}
                      >
                        Proof Of Address
                      </FormHelperText>
                      <OutlinedInput
                        // variant="outlined"
                        fullWidth
                        value={proofOfAddress ? proofOfAddress : ""}
                        onClick={handleClickAdd}
                        InputProps={{
                          readOnly: true,
                          // classes: {
                          //   notchedOutline: {
                          //     borderColor: "red !important",
                          //   },
                          // },
                        }}
                      />
                      <input
                        type="file"
                        ref={addInputRef}
                        style={{
                          display: "none",
                        }}
                        onChange={onUploadProofOfAddress}
                        // accept=".pdf, .doc, .docx"
                      />
                    </>
                  ) : (
                    <CustomTextField
                      variant="contained"
                      placeholder="Proof of Address"
                      label="Proof of Address*"
                      // disabled={true}
                      onChange={onUploadProofOfAddress}
                      type="file"
                      onKeyPress={onKeyPress}
                      value={proofOfAddress}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {proofOfIdentity ? (
                    <>
                      <FormHelperText
                        // className={classes.label}
                        style={{
                          font: "normal normal 500 13px/20px poppins",
                          textAlign: "left",
                          letterSpacing: "0px",
                          color: "#707070",
                          paddingBottom: 8,
                          marginLeft: 18,
                        }}
                      >
                        Proof of Identity
                      </FormHelperText>
                      <OutlinedInput
                        // variant="outlined"
                        fullWidth
                        value={proofOfIdentity ? proofOfIdentity : ""}
                        onClick={handleClick}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{
                          display: "none",
                        }}
                        onChange={onUploadProofOfIdentity}
                        // accept=".pdf, .doc, .docx"
                      />
                    </>
                  ) : (
                    <CustomTextField
                      variant="contained"
                      placeholder="Proof of Identity"
                      label="Proof of Identity*"
                      // disabled={true}
                      onChange={onUploadProofOfIdentity}
                      type="file"
                      onKeyPress={onKeyPress}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Dealer Code*"
                    placeholder="Dealer Code"
                    onChange={(e) => handleDealerCode(e.target.value)}
                    value={dealerCode ? dealerCode : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Dealer Name*"
                    placeholder="Dealer Name"
                    onChange={(e) => handleDealerName(e.target.value)}
                    value={dealerName ? dealerName : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Dealer Address*"
                    placeholder="Dealer Address"
                    onChange={(e) => handleDealerAddress(e.target.value)}
                    value={dealerAddress ? dealerAddress : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Dealer Town*"
                    placeholder="Dealer Town"
                    onChange={(e) => handleDealerTown(e.target.value)}
                    value={dealerTown ? dealerTown : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Dealer Contact Name*"
                    placeholder="Dealer Contact Name"
                    onChange={(e) => handleDealerContactName(e.target.value)}
                    value={dealerContactName ? dealerContactName : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Dealer Contact Number*"
                    placeholder="Dealer Contact Number"
                    onChange={(e) => handleDealerContactNumber(e.target.value)}
                    value={dealerContactNumber ? dealerContactNumber : ""}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                style={{ paddingTop: "16px" }}
              >
                <Grid
                  item
                  style={{ paddingRight: "24px", paddingBottom: "15px" }}
                >
                  <ColorOutlinedButton
                    className={classes.buttonOutlined}
                    variant="outlined"
                    onClick={handleKycClose}
                  >
                    Cancel
                  </ColorOutlinedButton>
                </Grid>
                <Grid item>
                  {createloading ? (
                    <CircularProgress />
                  ) : (
                    <ColorButton
                      type="submit"
                      className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={handleKycSubmit}
                      disabled={
                        imei == null ||
                        vin == null ||
                        engineNumber == null ||
                        yearOfmg == null ||
                        vehicleMake == null ||
                        vehicleModel == null ||
                        fuelType == null ||
                        customerID == null ||
                        customerName == null ||
                        customerMobile1 == null ||
                        customerAddress == null ||
                        customerEmail == null ||
                        proofOfAddress == null ||
                        proofOfIdentity == null ||
                        dealerCode == null ||
                        dealerName == null ||
                        dealerAddress == null ||
                        dealerTown == null ||
                        dealerContactName == null ||
                        dealerContactNumber == null ||
                        imei == "" ||
                        vin == "" ||
                        engineNumber == "" ||
                        yearOfmg == "" ||
                        vehicleMake == "" ||
                        vehicleModel == "" ||
                        fuelType == "" ||
                        customerID == "" ||
                        customerName == "" ||
                        customerMobile1 == "" ||
                        customerAddress == "" ||
                        customerEmail == "" ||
                        dealerCode == "" ||
                        dealerName == "" ||
                        dealerAddress == "" ||
                        dealerTown == "" ||
                        dealerContactName == "" ||
                        dealerContactNumber == "" ||
                        snackbarOpen
                      }
                    >
                      Submit
                    </ColorButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}

export default CreateKyc;
