import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  TextField,
  Menu,
  MenuItem,
  IconButton,
  TablePagination,
  InputAdornment,
} from "@mui/material";
// import { mdiHand } from "@mdi/js";
import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";
import classStyle from "./styles";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { debounce } from "throttle-debounce";
import FileSaver from "file-saver";
import axios from "axios";
import KycLogsByVin from "./KycLogsByVin";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

const GET_KYC_DETAILS = gql`
  query($oemId: Int!, $limit: Int!, $offset: Int!, $searchValue: String) {
    getKycLogs(
      oemId: $oemId
      limit: $limit
      offset: $offset
      searchValue: $searchValue
    ) {
      code
      message
      totalCount
      kycLogRows {
        imei
        serialNumber
        iccid
        vin
        engineNumber
        yearOfmg
        vehicleMake
        vehicleModel
        fuelType
        customerID
        customerName
        customerMobile1
        retailInvoiceNumber
        customerAddress
        customerEmail
        proofOfAddress
        dealerCode
        dealerName
        dealerAddress
        dealerTown
        dealerContactName
        dealerContactNumber
        kyccount
        kycApprovedDate
        kycRejectedDate
        lastKycReceivedDate
        vehicleActivationDate
        kycMode
        ticketCreated
        status
        rejectionReason
        simPlanType
        simSubscriptionStartDate
        simSubscriptionEndDate
        createdOn
      }
    }
  }
`;

const columns = [
  {
    id: "no",
    label: "Sr.No",
    minWidth: 100,
  },
  {
    id: "vin",
    label: "VIN",
    minWidth: 200,
  },
  {
    id: "imei",
    label: "IMEI",
    minWidth: 200,
  },
  {
    id: "engineNumber",
    label: "Engine Number",
    minWidth: 200,
  },
  {
    id: "yearOfmg",
    label: "Year",
    minWidth: 150,
  },
  {
    id: "vehicleMake",
    label: "Vehicle Make",
    minWidth: 150,
  },
  {
    id: "vehicleModel",
    label: "Vehicle Model",
    minWidth: 200,
  },
  {
    id: "fuelType",
    label: "Fuel Type",
    minWidth: 200,
  },
  {
    id: "customerID",
    label: "customer ID",
    minWidth: 150,
  },

  {
    id: "customerName",
    label: "Customer Name",
    minWidth: 150,
  },
  {
    id: "customerMobile1",
    label: "Customer Mobile",
    minWidth: 180,
  },
  {
    id: "retailInvoiceNumber",
    label: "Retail Invoice Number",
    minWidth: 200,
  },
  {
    id: "customerAddress",
    label: "Customer Address",
    minWidth: 200,
  },
  {
    id: "customerEmail",
    label: "Customer Email",
    minWidth: 150,
  },
  {
    id: "dealerCode",
    label: "Dealer Code",
    minWidth: 150,
  },
  {
    id: "dealerName",
    label: "Dealer Name",
    minWidth: 200,
  },
  {
    id: "dealerAddress",
    label: "Dealer Address",
    minWidth: 150,
  },
  {
    id: "dealerTown",
    label: "Dealer Town",
    minWidth: 150,
  },
  {
    id: "dealerContactName",
    label: "Dealer ContactName",
    minWidth: 200,
  },
  {
    id: "kyccount",
    label: "KYC Count",
    minWidth: 200,
  },

  {
    id: "kycRejectedDate",
    label: "KYC Rejected Date",
    minWidth: 200,
  },

  {
    id: "lastKycReceivedDate",
    label: "Last KYC Received Date",
    minWidth: 200,
  },
  // {
  //   id: "vehicleActivationDate",
  //   label: "Vehicle Activation Date",
  //   minWidth: 150,
  // },
  {
    id: "kycMode",
    label: "KYC Mode",
    minWidth: 150,
  },

  {
    id: "ticketCreated",
    label: "Ticket Created",
    minWidth: 200,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 150,
  },
  {
    id: "rejectionReason",
    label: "Rejection Reason",
    minWidth: 250,
  },
  {
    id: "log_created",
    label: "Log Created",
    minWidth: 200,
  },
];

const statusOptions = [
  "All",
  "VIN-IMEI Mapped",
  // "VIN_EOLT_completed",
  "KYC_RECEIVED",
  "IMEI_NOT_MAPPED",
  "KYC_APPROVED",
  "KYC_REJECTED",
];
function KycLogs(props) {
  const classes = classStyle();
  const [openRej, setopenRej] = useState(false);
  const [reason, setReason] = useState();
  const [openApprove, setApprove] = useState(false);

  const [kycDetails, setKycDetails] = useState([]);
  let [next, setNext] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [kycId, setKycId] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [allData, setAlldata] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [kycLogs, setkycLogs] = useState(false);
  const [selectedVin, setSelectedVin] = useState(null);
  let history = useHistory();
  /**
   * @summary This handles the page change in pagination
   */
  const handleChangePage = async (event, newPage) => {
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    await KycDetails({
      variables: { oemId: props.oem, limit: rowsPerPage, offset: newPage },
    });
  };

  /**
   * @summary This handles the row change in pagination
   */
  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
    await KycDetails({
      variables: { oemId: props.oem, limit: event.target.value, offset: 0 },
    });
  };

  /**
   * @summary opens the Rejection model to select reason
   */

  const handleReject = (vin) => {
    setKycId(vin);
    setopenRej(true);
  };

  /**
   * @summary opens the Approval model
   */
  const handleAprove = (vin) => {
    setApprove(true);
    setKycId(vin);
  };

  /**
   * @summary closes the Approval model
   */
  const cancelApprove = () => {
    setApprove(false);
    setKycId(null);
  };

  /**
   * @summary closes the Rejection model
   */
  const closeReject = () => {
    setopenRej(false);
    setKycId(null);
    setReason(null);
  };

  /**
   * @summary closes the snackbar
   */
  const handleClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  /**
   * @summary handles Search value
   */
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }
  
  /**
   * @summary returns the filtered array for search
   */
  const debounceSearchRender = useCallback(debounce(async (input) => {
    console.log(props)
    if (input) 
    {
      await fetchKycDetailsForSearch({
        variables: {
          oemId: props.oem,
          limit: 20000,
          offset: 0,
          searchValue: input.toLowerCase(),
        },
      });
    } else {
      await fetchKycDetailsForSearch({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
      });
    }
  }, 1000), [props.oem,totalCount, rowsPerPage, page]);
 
  const handleSearchChange = (event) => {
    const input = event.target.value;
     debounceSearchRender(input);
  };

  // /**
  //  * @summary handles Search value
  //  */
  // const handleSearchChange = (event) => {
  //   // setFilterText(event.target.value);
  //   setTimeout(debounceSearchRender(event.target.value), 2000);
  // };

  // /**
  //  * @summary returns the filtered array for search
  //  */
  // const debounceSearchRender = debounce(1000, async (event) => {
  //   if (event.toLowerCase().trim() != "") {
  //     await fetchKycDetailsForSearch({
  //       variables: {
  //         oemId: props.oem,
  //         limit: 20000,
  //         offset: 0,
  //         searchValue: event.toLowerCase(),
  //       },
  //     });
  //   } else {
  //     await fetchKycDetailsForSearch({
  //       variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
  //     });
  //   }
  // });

  const [fetchKycDetailsForSearch, { loading: logsLoading }] = useLazyQuery(
    GET_KYC_DETAILS,
    {
      fetchPolicy: "network-only",
      onCompleted: async (result) => {
        console.log("completed");
        setKycDetails(result.getKycLogs.kycLogRows);
        setTotalCount(result.getKycLogs.totalCount);
      },
    }
  );

  /**
   * @summary opens the pop up to select status
   */
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * @summary filters the data on status
   */
  const handleStatusChange = async (status) => {
    if (status == "All") {
      await fetchKycDetailsForStatus({
        variables: { oemId: props.oem, limit: 5, offset: 0 },
      });
    } else {
      const mappedData =
        status && allData.filter((row) => row.status === status);
      setKycDetails(mappedData);
    }
  };

  /**
   * @summary fetch kyc details for Status All
   */
  const [fetchKycDetailsForStatus, { data: kyc }] = useLazyQuery(
    GET_KYC_DETAILS,
    {
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
        handleClose();
      },
      onCompleted: async (result) => {
        console.log("completed");
        setKycDetails(result.getKycLogs.kycLogRows);
        setTotalCount(result.getKycLogs.totalCount);
      },
    }
  );

  const [
    KycDetails,
    { error: error, loading: loading, data: Details },
  ] = useLazyQuery(GET_KYC_DETAILS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleClose();
    },
    onCompleted: async (result) => {
      setKycDetails(result.getKycLogs.kycLogRows);
      setTotalCount(result.getKycLogs.totalCount);
    },
  });

  const handleVinLogs = (vin) => {
    setSelectedVin(vin);
    kycLogs ? setkycLogs(false) : setkycLogs(true);
  };

  useEffect(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    var token = localStorage.getItem("id_token");
    var decoded = token && jwt_decode(token);
    if (!token || currentTimestamp > decoded.exp) {
      localStorage.clear();
      history.push({
        pathname: "/login",
      });
    } else {
      KycDetails({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
      });
    }
  }, [props.oem]);

  return (
    <React.Fragment>
      <Grid container>
        {kycLogs && (
          <KycLogsByVin
            open={kycLogs}
            columns={columns}
            selectedVin={selectedVin}
            oem={props.oem}
            handleVinLogs={handleVinLogs}
          />
        )}
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item></Grid>
            <Grid
              container
              style={{
                backgroundColor: "#FFFFFF",
                paddingTop: "30px",
                margin: "10px 0px 0px 30px",
              }}
            >
              <Grid
                item
                xs={6}
                style={{
                  paddingBottom: "30px",
                  // paddingTop: "20px",
                  paddingLeft: "30px",
                }}
              >
                <TextField
                  placeholder="Search Number"
                  variant="outlined"
                  // value={filterText}
                  onChange={(e) => handleSearchChange(e)}
                  style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ paddingLeft: "3px" }} />
                      </InputAdornment>
                    ),
                  }}
                  className={classes.textfield}
                />
                {/* <IconButton
                  onClick={handleFilterClick}
                  disabled={totalCount == 0}
                >
                  <FilterListIcon style={{ fill: "#57B793" }} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  {statusOptions.map((status) => (
                    <MenuItem
                      key={status}
                      onClick={() => handleStatusChange(status)}
                    >
                      {status}
                    </MenuItem>
                  ))}
                </Menu> */}
                {/* <IconButton
                    title="Download Excel"
                    aria-label="Download Excel"
                    disabled={totalCount == 0}
                    onClick={DownloadExcel}
                  >
                    <CloudDownloadIcon
                      style={{ fill: "#57B793", size: "large" }}
                    />
                  </IconButton> */}
              </Grid>

              <Grid item xs={12}>
                {loading || logsLoading ? (
                  <CustomCircularProgress />
                ) : kycDetails && totalCount > 0 ? (
                  <Grid
                    item
                    style={{ backgroundColor: "#FFFFFF", marginRight: "1px" }}
                  >
                    <TableContainer className={classes.container}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                align="center"
                                style={{
                                  minWidth: column.minWidth,
                                  font:
                                    "normal normal 600 15px/20px Nunito Sans",
                                  backgroundColor: "#faf8f7",
                                }}
                                className={classes.tableHead}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {kycDetails.map((row, index) => {
                            let kycApprovedDate = row.kycApprovedDate
                              ? moment
                                  .unix(row.kycApprovedDate)
                                  .format("DD-MM-YYYY HH:mm:ss")
                              : null;
                            let kycRejectedDate = row.kycRejectedDate
                              ? moment
                                  .unix(row.kycRejectedDate)
                                  .format("DD-MM-YYYY HH:mm:ss")
                              : null;
                            let lastKycReceivedDate = row.lastKycReceivedDate
                              ? moment
                                  .unix(row.lastKycReceivedDate)
                                  .format("DD-MM-YYYY HH:mm:ss")
                              : null;
                            let vehicleActivationDate = row.vehicleActivationDate
                              ? moment
                                  .unix(row.vehicleActivationDate * 1000)
                                  .format("DD-MM-YYYY HH:mm:ss")
                              : null;

                            let logDate = row.createdOn
                              ? moment
                                  .unix(row.createdOn)
                                  .format("DD-MM-YYYY HH:mm:ss")
                              : null;
                            return (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {next + (index + 1)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                  onClick={() => handleVinLogs(row.vin)}
                                  style={{
                                    cursor: "pointer",
                                    color: "#5CB593",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {row.vin}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.imei ? row.imei : "NA"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.engineNumber}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.yearOfmg}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.vehicleMake}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.vehicleModel}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.fuelType}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.customerID}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.customerName ? row.customerName : "NA"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.customerMobile1
                                    ? row.customerMobile1
                                    : "NA"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.retailInvoiceNumber
                                    ? row.retailInvoiceNumber
                                    : "NA"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.customerAddress
                                    ? row.customerAddress
                                    : "NA"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.customerEmail}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.dealerCode}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.dealerName ? row.dealerName : "NA"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.dealerAddress ? row.dealerAddress : "NA"}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.dealerTown}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.dealerContactName}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.kyccount ? row.kyccount : "NA"}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {kycRejectedDate ? kycRejectedDate : "NA"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {lastKycReceivedDate
                                    ? lastKycReceivedDate
                                    : "NA"}
                                </TableCell>
                                {/* <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {vehicleActivationDate}
                                </TableCell> */}
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.kycMode}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.ticketCreated ? row.ticketCreated : "NA"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.status}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.rejectionReason
                                    ? row.rejectionReason
                                    : "NA"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {logDate}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      component="div"
                      count={kycDetails && totalCount > 0 ? totalCount : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Grid>
                ) : (
                  <NoData />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default withSnackbar(KycLogs);
