import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Tab,
  Tabs,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  CustomSelect,
  CustomTextArea,
  CustomTextField,
} from "../../hoc/CustomComponents";
import Book_10 from "../../images/Book_10.xlsx";
import EotaBulkUpload from "../../images/Eota-Bulk-upload.xlsx";
import SearchIcon from "@mui/icons-material/Search";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "@mui/material/styles";
import useStyles from "./styles";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@material-ui/core/IconButton";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { gql, useLazyQuery } from "@apollo/client";
import { CProgress, CProgressBar } from "@coreui/react";

const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
  zIndex: 1,
  color: "#5CB593",
  width: 20,
  height: 20,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#E0FFF3",
  border: `2px solid #5CB593`,
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  "&.MuiStepConnector-root": {
    flex: "1",
    top: "10px",
    left: "calc(-70% + 20px)",
    right: "calc(30% + 20px)",
  },
  "& .MuiStepConnector-line": {
    borderColor: "#5CB593",
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

function ColorlibStepIcon({ active }) {
  return (
    <ColorlibStepIconRoot>
      {active && <CircleIcon style={{ fontSize: "14px" }} />}
    </ColorlibStepIconRoot>
  );
}

const columns = [
  { field: "vin", headerName: "VIN", width: 150 },
  { field: "imei", headerName: "IMEI", width: 150 },
];

const bulkColumns = [
  { field: "vin", headerName: "Vin", width: 120 },
  { field: "error", headerName: "Error", width: 250 },
];

function AddNewCampaign({
  open,
  handleClose,
  handleDone,
  allScheduleTypes,
  allEcuGroups,
  allEcuNames,
  allFileTypes,
  allFiles,
  handleScheduleType,
  handleEcuGroup,
  handleEcuName,
  handleFileType,
  handleFile,
  handleDescription,
  handleExpiryDate,
  scheduleType,
  ecuGroup,
  ecuName,
  fileType,
  description,
  expiryDate,
  fileName,
  allVINs,
  selectedVin,
  setSelectedVin,
  handleConfirm,
  handleOnBlur,
  checKExpiryLength,
  isExpiryValid,
  handleBulkConfirm,
  filteredRows,
  setFilteredRows,
  searchQuery,
  setSearchQuery,
  activeTab,
  setActiveTab,
  activeStep,
  setActiveStep,
  bulkRows,
  setBulkRows,
  openSelectVin,
  setOpenSelectVin,
  uploadProgress,
  setUploadProgress,
  uploadStatus,
  setUploadStatus,
  fileUploadName,
  setFileUploadName,
  dragActive,
  setDragActive,
  handleNext,
  handleBack,
  handleBulkBack,
  handleTabChange,
  handleFileUpload,
  handleUploadbar,
  handleDrag,
  handleDrop,
  inputRef,
  onButtonClick,
  totalRecordsCount,
  successCount,
  errorCount,
  progressNumber,
  isFileUploaded
}) {
  const steps = ["New Campaign", "VIN Selection"];
  const classes = useStyles();

  useEffect(() => {
    setFilteredRows(allVINs);
  }, [allVINs]);

  const filterRows = (query) => {
    if (query) {
      const filtered = allVINs.filter((row) => {
        const lowerCaseQuery = query.toLowerCase();
        return (
          row.vin?.toString().toLowerCase().includes(lowerCaseQuery) ||
          row.imei?.toString().toLowerCase().includes(lowerCaseQuery)
        );
      });
      setFilteredRows(filtered);
    } else {
      setFilteredRows(allVINs);
    }
  };

  const showSelectedVIN = (selectedVIN) => {
    console.log("selectedVIN", selectedVIN);
    if (selectedVIN.length > 0) {
      const filteredData = allVINs.filter((item, index) =>
        selectedVIN.includes(item.id)
      );
      setSelectedVin(filteredData);
    } else {
      setSelectedVin([]);
    }
  };

  useEffect(() => {
    filterRows(searchQuery);
  }, [searchQuery]);

  const exportExcel = () => {
    if (isFileUploaded) { 
      const exportValue = bulkRows.map((item, index) => {
        return {
          vin: item.vin,
          error: item.error,
        };
      });
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(exportValue);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "Error Sheet");
      });
    }
  };
  

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
      PaperProps={{
        style: {
          maxHeight: "100vh",
          overflow: "hidden",
        },
      }}
    >
      <DialogContent style={{ padding: "2vw" }}>
        {/* Content Start */}
        <Grid container>
          {/* Dialog Header Start */}
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Grid container style={{ paddingBottom: "10px" }}>
              <Typography variant="h5" style={{ marginBottom: "30px" }}>
                {activeStep
                  ? "Select VIN / Create New Campaign"
                  : "Create New Campaign"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon} />
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
          {/* Dialog Header End */}

          {/* Dialog Content Start */}
          <Grid
            container
            spacing={2}
            style={{ minHeight: "80vh", maxHeight: "80vh" }}
          >
            {!activeStep ? (
              <>
                {/* Dialog Create Campaign Start */}
                <Grid item xs={6}>
                  <CustomSelect
                    label="Select Campaign Type*"
                    value={scheduleType}
                    options={allScheduleTypes}
                    placeholder="Campaign Type"
                    getOptionLabel={(option) => option.scheduleName}
                    handleSelectChange={handleScheduleType}
                    onBlur={handleOnBlur}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomSelect
                    label="Select ECU Group*"
                    value={ecuGroup}
                    placeholder="Select ECU Group"
                    options={allEcuGroups}
                    getOptionLabel={(option) => option.ecu_group_name}
                    handleSelectChange={handleEcuGroup}
                    onBlur={handleOnBlur}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomSelect
                    label="Select ECU Name*"
                    placeholder="Select ECU Name"
                    value={ecuName}
                    options={allEcuNames}
                    getOptionLabel={(option) => option.ecu_name}
                    handleSelectChange={handleEcuName}
                    disabled={!ecuGroup}
                    onBlur={handleOnBlur}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomSelect
                    label="Select File Type*"
                    placeholder="Select File Type"
                    value={fileType}
                    options={allFileTypes}
                    getOptionLabel={(option) => option.fileTypeName}
                    handleSelectChange={handleFileType}
                    onBlur={handleOnBlur}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomSelect
                    label="Select File*"
                    placeholder="Select File"
                    value={fileName}
                    options={allFiles}
                    getOptionLabel={(option) => option.filename}
                    handleSelectChange={handleFile}
                    disabled={!fileType}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    id="ScheduleExpiryDates"
                    name="Schedule Expiry Days"
                    label="Schedule Expiry Days*"
                    placeholder="Type Expiry Number"
                    type="text"
                    onChange={(e) => {
                      handleExpiryDate(e);
                      checKExpiryLength();
                    }}
                    value={expiryDate}
                    error={!isExpiryValid}
                    style={{ height: "5px" }}
                  />
                  {!isExpiryValid ? (
                    <span style={{ color: "red" }}>
                      The length of Expiry should be less than 10 and should not
                      be 0 or negative.
                    </span>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <CustomTextArea
                    id="description"
                    name="description"
                    label="Description"
                    placeholder="Description"
                    type="text"
                    onChange={(e) => {
                      handleDescription(e.target.value);
                    }}
                    value={description}
                    rows="3"
                  />
                </Grid>
              </>
            ) : (
              // {/* Dialog Create Campaign End */}
              // Dialog VIN Selection Campaign Start
              <>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  textColor="none"
                  TabIndicatorProps={{ style: { backgroundColor: "black" } }}
                >
                  <Tab
                    label="Manual Selection"
                    // style={{ marginRight: activeTab === 1 ? "20px" : 0 }}
                  />
                  <Tab label="Bulk Selection" />
                </Tabs>
                <Grid container style={{ padding: "1vw" }}>
                  {activeTab === 0 ? (
                    <>
                      {/* Manual Selection Start */}
                      <Grid item xs={12}>
                        <Typography style={{ fontSize: "18px" }}>
                          Total Vehicle: {allVINs.length}
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={6}
                          style={{
                            backgroundColor: "#EFEFEF",
                            borderRadius: "10px",
                          }}
                        >
                          <Grid container alignItems="center" justify="center">
                            <Grid item xs={12} style={{ padding: "1vw" }}>
                              <TextField
                                placeholder="Search for VIN/IMEI"
                                variant="outlined"
                                style={{
                                  width: "100%",
                                  backgroundColor: "#F3F3F3",
                                }}
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon
                                        style={{ paddingLeft: "3px" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                padding: "1vw",
                              }}
                            >
                              <DataGrid
                                style={{
                                  height: "40vh",
                                }}
                                rows={filteredRows.map((item, index) => ({
                                  id: item.id || index,
                                  vin: item.vin,
                                  imei: item.imei,
                                }))}
                                columns={columns}
                                pageSize={5}
                                checkboxSelection
                                onRowSelectionModelChange={(newSelection) => {
                                  showSelectedVIN(newSelection);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          spacing={2}
                          style={{
                            padding: "0.5vw",
                            height: "50vh",
                            overflow: "auto",
                          }}
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(2, 1fr)",
                              gridGap: "8px",
                            }}
                          >
                            {selectedVin &&
                              selectedVin.length > 0 &&
                              selectedVin.map((element) => (
                                <Typography
                                  variant="h6"
                                  style={{
                                    marginRight: "10px",
                                    backgroundColor: "#EFEFEF",
                                    borderRadius: "30px",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    padding: "5px",
                                  }}
                                >
                                  {element.vin}
                                </Typography>
                              ))}
                          </div>
                        </Grid>
                      </Grid>
                      {/* Manual Selection End */}
                    </>
                  ) : (
                    <>
                      {/* Bulk Selection Start */}
                      <Grid container spacing={4}>
                        {/* Download Template Start */}
                        <Grid item xs={6}>
                          <Grid
                            fullWidth
                            container
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            style={{
                              backgroundColor: "#EFEFEF",
                              border: "1px solid #DEDEDF",
                              borderRadius: "4px",
                              height: "50px",
                            }}
                          >
                            <Typography style={{ marginLeft: "10px" }} variant="h6">
                              {" "}
                              Download Template
                            </Typography>
                            <a
                              href={EotaBulkUpload}
                              download="Eota-Bulk-upload.xlsx"
                              style={{ marginRight: "10px", color: 'black' }}
                            >
                              <SaveAltIcon />
                            </a>
                          </Grid>
                        </Grid>
                        {/* Download Template End */}
                        {/* Download File Progress Start */}
                        <Grid item xs={6}>
                          <Grid container justifyContent={"space-between"}>
                            <Grid item xs={4} spacing={2}>
                              <Typography
                                style={{ color: "#707376", fontSize: "14px" }}
                                noWrap
                              >
                                {fileUploadName}
                              </Typography>
                              <Typography
                                style={{ color: "#A5A8A9", fontSize: "12px" }}
                              >
                                {uploadStatus}
                              </Typography>
                            </Grid>
                            <Grid item xs={true} fullWidth>
                              <CProgress className="mb-3">
                                <CProgressBar
                                  color="success"
                                  variant="striped"
                                  animated
                                  value={uploadProgress}
                                >
                                  {uploadProgress}%
                                </CProgressBar>
                              </CProgress>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Download File Progress End */}
                        {/* Drag and Drop File Start */}
                        <Grid item xs={6}>
                          <Grid
                            fullWidth
                            container
                            direction="column"
                            justifyContent="center"
                            sx={{
                              backgroundColor: "#EFEFEF",
                              border: "1px solid #DEDEDF",
                              borderRadius: "8px",
                              opacity: 1,
                              textAlign: "center",
                              minHeight: "45vh",
                            }}
                            onDragEnter={handleDrag}
                            onDragOver={handleDrag}
                            onDragLeave={handleDrag}
                            onDrop={handleDrop}
                          >
                            <Grid item style={{ marginTop: "5em" }}>
                              <Typography
                                align="center"
                                fontSize={"16px"}
                                color={"#888B8D"}
                              >
                                {dragActive
                                  ? "Drop the file here"
                                  : "Drag and drop file here"}
                              </Typography>
                              <br></br>
                              <Typography
                                align="center"
                                fontSize={"16px"}
                                color={"#888B8D"}
                              >
                                or
                              </Typography>
                              <br></br>
                              <Typography
                                fontSize={"16px"}
                                color={"#888B8D"}
                                onClick={onButtonClick}
                                sx={{ cursor: "pointer" }}
                              >
                                Browse to <u>upload</u> file
                              </Typography>
                              <input
                                accept=".xlsx"
                                id="fileInput"
                                type="file"
                                style={{ display: "none" }}
                                ref={inputRef}
                                onChange={handleFileUpload}
                              />
                            </Grid>
                            <Grid item style={{ marginTop: "4.5em" }}>
                              <Typography fontSize={"14px"} color={"#A7A8A9"}>
                                Only xlsx files supported
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Drag and Drop File End */}
                        {/* Datatable Start */}
                        <Grid item xs={6}>
                          <Grid
                            container
                            item
                            alignItems="center"
                            justifyContent="space-between"
                            padding={"10px 0px"}
                          >
                            <Typography variant="body2">
                              Total Records:{totalRecordsCount}; Successful:
                              {successCount}; Error:{errorCount}
                            </Typography>
                            <SaveAltIcon
                              onClick={exportExcel}
                              cursor={"pointer"}
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            style={{
                              backgroundColor: "#EFEFEF",
                              borderRadius: "10px",
                              height: "40vh",
                            }}
                          >
                            <DataGrid
                              rows={bulkRows}
                              columns={bulkColumns}
                              pageSize={5}
                              backgroundColor={"#EFEFEF"}
                            />
                          </Grid>
                        </Grid>
                        {/* Data table End */}
                      </Grid>
                      {/* Bulk Selection End */}
                    </>
                  )}
                </Grid>
              </>
            )}
            {/* // Dialog VIN Selection Campaign End */}
            {/* Dialog Footer Start */}
          <Grid
            container
            justifyContent="flex-end"
            style={{ paddingTop: "10px" }}
            spacing={3}
          >
            <Grid item xs={3}>
              {activeStep ? (
                <ColorOutlinedButton
                  variant="outlined"
                  onClick={handleBack}
                  size="large"
                  style={{ padding: "6px 24px", fontSize: "16px" }}
                >
                  Back
                </ColorOutlinedButton>
              ) : (
                <ColorOutlinedButton
                  variant="outlined"
                  onClick={handleClose}
                  style={{ padding: "6px 24px", fontSize: "16px" }}
                >
                  Cancel
                </ColorOutlinedButton>
              )}
            </Grid>
            <Grid item xs={3}>
              {activeStep ? (
                <ColorButton
                  variant="outlined"
                  size="large"
                  onClick={handleConfirm}
                  style={{ padding: "6px 24px", fontSize: "16px" }}
                  disabled={selectedVin && selectedVin.length === 0}
                >
                  Confirm
                </ColorButton>
              ) : (
                <ColorButton
                  style={{ padding: "6px 24px", fontSize: "16px" }}
                  variant="outlined"
                  onClick={handleNext}
                  disabled={
                    scheduleType == null ||
                    ecuGroup == null ||
                    ecuName == null ||
                    fileType == null ||
                    fileName == null ||
                    expiryDate == null ||
                    expiryDate == "" ||
                    expiryDate <= 0
                  }
                >
                  Next
                </ColorButton>
              )}
            </Grid>
          </Grid>
          {/* Dialog Footer End */}
          </Grid>
          {/* Dialog Content End */}
        </Grid>
        {/* Content End */}
      </DialogContent>
    </Dialog>
  );
}

export default AddNewCampaign;
