import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Button,
  Snackbar,
} from "@mui/material";
import { CustomTextField } from "../../hoc/CustomComponents";
import { ColorButton } from "../../hoc/PaletteComponent";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";

export function VinBulkUploads({
  open,
  handleClose,
  submitBulkUpload,
  onUpload,
  bulkUploadResponse,
  snackbar,
}) {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                {" "}
                <Typography variant="h4">Bulk Upload</Typography>
              </Grid>
              <Grid item xs={3}>
                <Button style={{ float: "right" }} onClick={handleClose}>
                  <CloseIcon
                    style={{
                      fill: "#2b2924",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <CustomTextField
              variant="contained"
              placeholder="Upload template"
              label="Upload File*"
              type="file"
              onChange={onUpload}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item style={{ paddingBottom: "15px", paddingRight: "30px" }}>
          <ColorButton
            variant="outlined"
            style={{ padding: "5px 20px" }}
            onClick={submitBulkUpload}
            disabled={bulkUploadResponse == null || snackbar}
          >
            Submit
          </ColorButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
