import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  // pageTitle: {
  //   color: "#212121",
  //   fontSize: "2rem !important",
  // },
  // input: {
  //   display: "none",
  // },
  // buttonDiv: {
  //   marginTop: "10%",
  //   marginLeft: "50%",
  // },
  button: {
    paddingLeft: "25px",
    paddingRight: "25px",
    width: "80%",
  },
  OTPButton: {
    borderRadius: "20px !important",
    backgroundColor: "#E0FFF3",
    border: "1px solid #57B793",
    color: "#57B793",
    width: "90%",
    // width: "13vw",
  },

  bod: {
    backgroundColor: "#FFF",
    height: "65vh",
  },
  text: {
    marginBottom: theme.spacing(2),
  },

  OTPheading: {
    font: "normal normal bold 24px/32px Nunito Sans",
  },
  OTPsubhead: {
    font: "normal normal 600 16px/20px Poppins",
  },

  stepper: {
    "& .MuiStepIcon-active": { color: "#E0FFF3 !important" },
    "& .MuiStepIcon-completed": { color: "#5CB593 !important" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "#E0FFF3 !important" },
  },
  textfield: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#5CB593",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5CB593",
      },
    },
  },
  checkBox: {
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        border: "1px solid #bbbbbb",
        borderRadius: 3,
      },
    },
  },
  fileInput: {
    font: "normal normal medium 20px/24px Poppins",
    letterSpacing: "0px",
    color: "#333333",
    border: "1px solid #E8E8E8",
    borderRadius: "5px",
    padding: "15px",
    paddingLeft: "20px",
    width: "40vh",
    backgroundColor: "#E8E8E8",
  },
  tableContent: {
    // font: "normal normal normal 27px/38px Nunito Sans ",
    letterSpacing: "0px",
    // color: "#212121",
    borderColor: "#E8E8E8",
    borderRightWidth: 1,
  },
}));
