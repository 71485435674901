import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { Grid, Typography, Dialog, DialogContent } from "@mui/material";
import { CustomSelect } from "../../hoc/CustomComponents";
import { ColorOutlinedButton, ColorButton } from "../../hoc/PaletteComponent";

// const GET_ALL_PLANS=gql``
export default function Plans({
  openPlan,
  selectedPlan,
  cancelPlan,
  submitPlan,
  handlePlanSelect,
}) {
  const classes = useStyles();
  const [allPlans, setAllPlans] = useState(null);

  //query call to fetch plans
  //   const [
  //     getAllPlans,
  //     { loading: planLoading, error: planError, data: allactivePlans },
  //   ] = useLazyQuery(GET_ALL_PLANS, {
  //     fetchPolicy: "network-only",
  //     onCompleted: (result) => {
  //       //set all plans
  //     },
  //   });

  useEffect(() => {
    //to fetch all plans
    // getAllPlans({variables:{

    // }})

    setAllPlans([
      { id: 1, planName: "plan1" },
      { id: 2, planName: "plan2" },
    ]);
  }, []);

  return (
    <Dialog open={openPlan}>
      <DialogContent style={{ padding: "20px" }}>
        <Grid container spacing={3}>
          <Grid item>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4">Select the plan</Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  placeholder="Select plan"
                  options={allPlans}
                  getOptionLabel={(option) => option.planName}
                  handleSelectChange={handlePlanSelect}
                  name="selectPlan"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              style={{ paddingLeft: "9vw", paddingTop: "2px" }}
            >
              <Grid item>
                <ColorOutlinedButton
                  className={classes.buttonOutlined}
                  onClick={cancelPlan}
                >
                  Cancel
                </ColorOutlinedButton>
              </Grid>
              <Grid item>
                <ColorButton
                  variant="contained"
                  className={classes.buttonOutlined}
                  onClick={submitPlan}
                  disabled={selectedPlan == "" || selectedPlan == null}
                >
                  Activate
                </ColorButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
