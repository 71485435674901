import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";

export default function Dashboard(props) {
  return (
    <Grid container>
      <Grid item>
        <Grid item xs={12}>
          <Typography variant="h4">Summary</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
