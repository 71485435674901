import { Button } from "@mui/material";
import styled from "styled-components";

export const ColorButton = styled(Button)({
  textTransform: "none !important",
  width: "100% !important",
  textAlign: "center !important",
  font: "normal normal 500 22px/30px poppins !important",
  letterSpacing: "0px !important",
  color: "#57B793 !important",
  opacity: 1,
  variant: "contained !important",
  background: "#E0FFF3 0% 0% no-repeat padding-box !important",
  border: "1px solid #57B793 !important",
  borderRadius: "32px !important",
  ":disabled": {
    background: "#E0FFF3 0% 0% no-repeat padding-box !important",
    opacity: 0.6,
  },
});
export const ColorOutlinedButton = styled(Button)({
  textTransform: "none !important",
  border: "2px solid #333333 !important",
  textAlign: "center !important",
  width: "100% !important",
  borderRadius: "32px !important",
  font: "normal normal 500 22px/30px poppins !important",
  letterSpacing: "0px !important",
  color: "#333333 !important",
  variant: "contained !important",
  opacity: 1,
  ":disabled": {
    background: "#FFFFFF 0% 0% no-repeat padding-box !important",
    opacity: 0.4,
    border: "2px solid #333333 !important",
  },
});

export const ColorSampleButton = styled(Button)({
  textTransform: "none !important",
  textAlign: "center !important",
  font: "normal normal 500 22px/30px poppins !important",
  letterSpacing: "0px !important",
  color: "#57B793 !important",
  opacity: 1,
  variant: "contained !important",
  borderRadius: "20px !important",
  //  backgroundColor: "#111",
  border: "1px solid #333 !important",
  // paddingLeft: "25px",
  // paddingRight: "25px",
  backgroundColor: "black !important",
  width: "90%",
  ":disabled": {
    background: "#E0FFF3 0% 0% no-repeat padding-box !important",
    opacity: 0.6,
  },
});

export const LoginButton = styled(Button)({
  color: "#ffffff !important",
  borderRadius: "30px !important",
  height: "40px !important",
  opacity: "1 !important",
  background: "#57B793 0% 0% no-repeat padding-box !important",
  textAlign: "center !important",
  font: "normal normal bold 18px/24px Nunito Sans !important",
  letterSpacing: "0px !important",

  "&:hover, &:focus": {
    color: "#ffffff !important",
    // backgroundColor: "#ffffff",
  },
  ":disabled": {
    background: "#9ee6c2 0% 0% no-repeat padding-box !important",
  },
});

export const CustomButton = styled(Button)({
  color: "#57B793 !important",
  borderRadius: "28px !important",
  border: "1px solid #57B793 !important",
  font: "normal normal 100 10px/20px Nunito Sans !important",
  background: "#E0FFF3 0% 0% no-repeat padding-box !important",
  textAlign: "center !important",
  height: "26px !important",
  letterSpacing: " 0px !important",
  ":disabled": {
    background: "#FFFFFF 0% 0% no-repeat padding-box !important",
    opacity: 0.4,
    border: "1px solid #333333 !important",
    color: "black !important",
  },
});
