import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter, useHistory } from "react-router-dom";
import classnames from "classnames";
import { Box, Grid } from "@mui/material";

//icons

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard/Dashboard.js";

// context
import { useLayoutState } from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { gql, useLazyQuery, useQuery } from "@apollo/client";

import jwt_decode from "jwt-decode";
import Tables from "../../pages/tables/Tables";
import Charts from "../../pages/charts/Charts";
import DeviceGroup from "../../pages/maps/devicegroup";
import { CircularProgress } from "@mui/material";
import ManagerTable from "../../pages/compaign/managerTable";
import CertificationManager from "../../pages/CertificationManager/CertificationManager";
import CertificationViewer from "../../pages/CertificationViewer/CertificationViewer";
import VinImeMap from "../../pages/Vehicles";
import KycDetails from "../../pages/KYC_Details";
import TicketCreation from "../../pages/Ticket_Creation";
import FotaManager from "../../pages/FOTA";
import VinKycLogs from "../../pages/VinImeLogs";
import ECU from "../../pages/ECU_OTA";
import VinsByCampaign from "../../pages/ECU_OTA/VinsByCampaign";

import { PageNotFound } from "./PageNotFound";
import ServiceUserL2 from "../../pages/ServiceUserL2/ServiceUserL2";
import ServiceUserLogs from "../../pages/ServiceUserLogs/ServiceUserLogs";
import DealerLogins from "../../pages/DealerLogins";
import EoltReport from "../../pages/EOLTReport";
const getUsersQuery = gql`
  query ($id: Int) {
    getUsers(id: $id) {
      id
      username
      role_id
      status
      ownerId
      categories {
        id
        iconPath
        name
        tooltip
        features {
          id
          category_id
          iconPath
          name
          tooltip
          selectedIconPath
        }
      }
    }
  }
`;

const getOemQuery = gql`
  query {
    getOem {
      id
      oemName
      oemCode
      oemEmail
      phoneNumber
      logo
    }
  }
`;

function Layout(props) {
  var classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [featuresForDLM, setFeaturesForDLM] = useState([]);
  const [featuresForCM, setFeaturesForCM] = useState([]);
  const [oem, setOem] = useState(null);
  const [feature, setFeature] = useState(false);
  const userDispatch = useUserDispatch();
  const [featuresForOTA, setFeaturesForOTA] = useState([]);
  const [id, setID] = useState();
  const [allFeatures, setAllFeature] = useState([]);

  //const { featureId } = useParams();
  const { state } = props.location;
  let history = useHistory();

  // global
  var layoutState = useLayoutState();
  var token = localStorage.getItem("id_token");
  var decoded = token
    ? jwt_decode(token)
    : history.push({
      pathname: "/login",
    });

  console.log(decoded, "jwtjwtjwt");
  const currentTimestamp = Math.floor(Date.now() / 1000);
  console.log(currentTimestamp, "currentTimestamp");

  if (currentTimestamp > decoded?.exp || !token) {
    localStorage.clear();
    history.push({
      pathname: "/login",
    });
  }

  const handleCategoty = (e) => {
    localStorage.setItem("categories", e);
    if (e === 1) {
      history.push({
        pathname: `/app/${featuresForDLM[0].name.toLowerCase()}`,
      });
    } else if (e === 2) {
      allFeatures && allFeatures.includes("certification%20manager")
        ? history.push({
          pathname: "/app/certification%20manager",
        })
        : allFeatures.includes("certification%20viewer")
          ? history.push({
            pathname: "/app/certification%20viewer",
          })
          : history.push({
            pathname: "/app/ticket-manager",
          });
    } else if (e === 3) {
      history.push({
        pathname: `/app/${featuresForOTA[0].name.toLowerCase()}`,
      });
      // if (decoded.id == 73) {
      //   history.push({
      //     pathname: "/app/ecu-ota",
      //   });
      // } else {
      //   history.push({
      //     pathname: "/app/fota-manager",
      //   });
      // }
    }

    // else {
    //   history.push({
    //     pathname: "/app/ota-campaign",
    //   });
    // }
  };

  useEffect(() => {
    if (decoded?.id) {
      getUsers({
        variables: {
          id: decoded.id,
        },
      });
    }
  }, [decoded?.id]);

  const {
    loading: oemloading,
    error: oemerror,
    data: oemData,
  } = useQuery(getOemQuery, { fetchPolicy: "network-only" });

  console.log(
    "loading--",
    oemloading,
    "---error---",
    oemerror,
    "--data--",
    oemData
  );

  const [getUsers, { loading, error, data }] = useLazyQuery(getUsersQuery, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res && res.getUsers) {
        let categories = [];
        let features = [];
        console.log(res.getUsers[0].categories, "ressss");
        data.getUsers.forEach((item) => {
          item.categories.forEach((elem) => {
            console.log("elemid---", elem);
            categories.push({
              id: elem.id,
              iconPath: elem.iconPath,
              name: elem.name,
              tooltip: elem.tooltip,
            });
            features.push(elem.features);
          });
        });
        console.log(features);
        console.log(categories, "categories");
        let allFeature = [];
        setCategories(categories);
        for (let i = 0; i < categories.length; i++) {
          // allFeature.allFeature(...features[0], ...features[1], ...features[2]);
          // console.log(allFeature, "allFeature");
          features[0] && allFeature.push(...features[0]);
          features[1] && allFeature.push(...features[1]);
          features[2] && allFeature.push(...features[2]);
          if (categories[i].id == 1) {
            setFeaturesForDLM(features[0]);
          } else if (categories[i].id == 2) {
            features[1] ? setFeaturesForCM(features[1]) : setFeaturesForCM(features[0]);
          } else if (categories[i].id == 3) {
            if (features[2]) {
              setFeaturesForOTA(features[2]);
            } else {
              setFeaturesForOTA(features[1]);
            }
          }
        }
        let featureName = [];
        for (let j = 0; j < allFeature.length; j++) {
          //since spaces are provided in name ,adding this condition
          console.log(allFeature[j], "allFeature[j]");
          allFeature[j].name == "Certification Manager" &&
            featureName.push("certification%20manager");
          allFeature[j].name == "Certification Viewer" &&
            featureName.push("certification%20viewer");
          allFeature[j].name == "Certification Viewer" &&
            featureName.push("certification%20viewer");
          allFeature[j].name == "Data Push" && featureName.push("data%20push");
          featureName.push(allFeature[j].name.toLowerCase(), "vinCampaign");
        }
        setAllFeature([...featureName]);
      }
    },
  });

  if (loading) return "Loading...";
  if (error) signOut(userDispatch, props.history);
  // const pathname = location.href.substring(0, location.href.lastIndexOf("/"));
  const pathname = window.location.href.split("/");

  console.log(pathname.at(-1), "pathname");

  if (
    pathname.at(-1) == "devicemanager" ||
    pathname.at(-1) == "sim-management" ||
    pathname.at(-1) == "vehicles" ||
    pathname.at(-1) == "kyc-details" ||
    pathname.at(-1) == "devicemanager" ||
    pathname.at(-1) == "support-ticket" ||
    pathname.at(-1) == "vin-kyc-logs" ||
    pathname.at(-1) == "summary" ||
    pathname.at(-1) == "eolt-report"
  ) {
    localStorage.setItem("categories", 1);
  } else if (
    pathname.at(-1) == "certification%20manager" ||
    pathname.at(-1) == "certification%20viewer" ||
    pathname.at(-1) == "ticket-manager" ||
    pathname.at(-1) == "service-tickets-logs"
  ) {
    localStorage.setItem("categories", 2);
  } else if (
    pathname.at(-1) == "ecu-ota" ||
    pathname.at(-1) == "ota-campaign" ||
    pathname.at(-1) == "fota-manager" ||
    pathname.at(-1) == "vinCampaign"
  ) {
    localStorage.setItem("categories", 3);
  }
  let categoryId = localStorage.getItem("categories");
  console.log(allFeatures, "allFeatures");
  return (
    <>
      {allFeatures.length > 0 && !allFeatures.includes(pathname.at(-1)) ? (
        <>
          {
            history.push({
              pathname: "/login"
            }) && localStorage.clear()
          }
        </>

      ) : (
        <div className={classes.root}>
          <>
            {oemloading ? (
              <CircularProgress
                color="secondary"
                style={{ color: "#57B793" }}
              />
            ) : (
              <Header
                history={props.history}
                data={{ categories: categories, oems: oemData.getOem }}
                token={decoded}
                getOem={(val) => setOem(val)}
                feature={(val) => {
                  // if (val === 2) setFeature(true);
                  // else setFeature(false);
                }}
                handleCategoty={handleCategoty}
              />
            )}
            {categoryId == 3 ? (
              <Sidebar data={featuresForOTA} />
            ) : categoryId == 2 ? (
              <Sidebar data={featuresForCM} />
            ) : (
              <Sidebar data={featuresForDLM} />
            )}

            <div
              className={classnames(classes.content, {
                [classes.contentShift]: layoutState.isSidebarOpened,
              })}
            >
              <div className={classes.fakeToolbar} />
              <Switch>
                <Route
                  path="/app/summary"
                  render={(props) => <Dashboard featureId={state} oem={oem} />}
                />
                <Route
                  path="/app/devicemanager"
                  render={(props) => <Tables featureId={state} oem={oem} />}
                />
                <Route
                  path="/app/sim-management"
                  render={(props) => <Charts featureId={state} oem={oem} />}
                />
                <Route
                  path="/app/device-group-manager"
                  render={(props) => (
                    <DeviceGroup featureId={state} oem={oem} />
                  )}
                />
                <Route
                  path="/app/ota-campaign"
                  render={(props) => (
                    <ManagerTable featureId={state} oem={oem} />
                  )}
                />
                <Route
                  path="/app/certification manager"
                  render={(props) => (
                    <CertificationManager
                      featureId={state}
                      oem={oem}
                      token={decoded}
                    />
                  )}
                />
                <Route
                  path="/app/certification viewer"
                  render={(props) => (
                    <CertificationViewer
                      featureId={state}
                      oem={oem}
                      token={decoded}
                    />
                  )}
                />

                <Route
                  path="/app/vehicles"
                  render={(props) => (
                    <VinImeMap featureId={state} oem={oem} token={decoded} />
                  )}
                />
                <Route
                  path="/app/kyc-details"
                  render={(props) => <KycDetails featureId={state} oem={oem} />}
                />
                <Route
                  path="/app/Support-Ticket"
                  render={(props) => (
                    <TicketCreation
                      featureId={state}
                      oem={oem}
                      token={decoded}
                    />
                  )}
                />
                <Route
                  path="/app/FOTA-Manager"
                  render={(props) => (
                    <FotaManager featureId={state} oem={oem} />
                  )}
                />
                <Route
                  path="/app/Vin-Kyc-logs"
                  render={(props) => <VinKycLogs featureId={state} oem={oem} />}
                />
                <Route
                  path="/app/ecu-ota"
                  render={(props) => (
                    <ECU
                      featureId={state}
                      oem={oem}
                      token={decoded}
                      props={props}
                    />
                  )}
                />
                <Route
                  path="/app/vinCampaign"
                  render={(props) => <VinsByCampaign props={props} oem={oem} />}
                />
                <Route
                  path="/app/ticket-manager"
                  render={(props) => (
                    <ServiceUserL2 props={props} oem={oem} token={decoded} />
                  )}
                />
                <Route
                  path="/app/service-tickets-logs"
                  render={(props) => (
                    <ServiceUserLogs props={props} oem={oem} token={decoded} />
                  )}
                />
                <Route
                  path="/app/dealer-login"
                  render={(props) => (
                    <DealerLogins props={props} oem={oem} token={decoded} />
                  )}
                />
                <Route
                  path="/app/eolt-report"
                  render={(props) => (
                    <EoltReport props={props} oem={oem} token={decoded} />
                  )}
                />
              </Switch>

              <Box
                mt={5}
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent="space-between"
              >
                {/* <div>
              <Link
                color={"primary"}
                href={"https://flatlogic.com/"}
                target={"_blank"}
                className={classes.link}
              >
                Flatlogic
              </Link>
              <Link
                color={"primary"}
                href={"https://flatlogic.com/about"}
                target={"_blank"}
                className={classes.link}
              >
                About Us
              </Link>
              <Link
                color={"primary"}
                href={"https://flatlogic.com/blog"}
                target={"_blank"}
                className={classes.link}
              >
                Blog
              </Link>
            </div> */}
                {/* <div>
              <Link
                href={"https://www.facebook.com/flatlogic"}
                target={"_blank"}
              >
                <IconButton aria-label="facebook">
                  <Icon path={FacebookIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
              <Link href={"https://twitter.com/flatlogic"} target={"_blank"}>
                <IconButton aria-label="twitter">
                  <Icon path={TwitterIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
              <Link href={"https://github.com/flatlogic"} target={"_blank"}>
                <IconButton aria-label="github" style={{ marginRight: -12 }}>
                  <Icon path={GithubIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
            </div> */}
              </Box>
            </div>
          </>
        </div>
      )}
    </>
  );
}

export default withRouter(Layout);
