import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  title: {
    marginTop: "20px",
    marginBottom: "25px",
    paddingBottom: "25px",
  },
  textfield: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#5CB593",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5CB593",
      },
    },
  },
  buttonText: {
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  buttonOutlined: {
    // border: "1px solid #333333",
    // borderRadius: "20px",
    // opacity: 1,
    width: "150px",
    height: "40px",
  },
  tableHead: {
    // font: "normal normal 600 15px/20px Nunito Sans",
    letterSpacing: "0px",
    color: "#212121",
    borderRightWidth: 1,
    borderColor: "#E8E8E8",
    borderTop: "1px solid #E8E8E8",
    padding:'8px !important'
  },
  tableContent: {
    // font: "normal normal normal 27px/38px Nunito Sans ",
    letterSpacing: "0px",
    // color: "#212121",
    borderColor: "#E8E8E8",
    borderRightWidth: 1,
    padding: "4px !important",
  },
  sampleButton: {
    borderRadius: "20px !important",
    //  backgroundColor: "#111",
    border: "1px solid #333 !important",
    // paddingLeft: "25px",
    // paddingRight: "25px",
    backgroundColor: "black !important",
    // width: "90%",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    // marginBottom: theme.spacing(2),
    paddingRight: "10px",
  },
  tab: {
    // textTransform: "none",
    fontWeight: 800,
    // fontSize: "90px",

    // marginRight: theme.spacing(2),
    "&.Mui-selected": {
      color: "#5CB593",
      //   backgroundColor: theme.palette.primary.light,
      //   fontWeight: 600,
      padding: "20px",
      font: "normal normal 500 20px/30px Nunito Sans ",
    },
    "&:hover": {
      color: "#5CB593",
    },
    "&:focus": {
      opacity: 1,
      color: "#5CB593",
    },
  },
}));
