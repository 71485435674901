import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  container: {
    maxHeight: 440,
  },
  pageTitle: {
    color: "#212121",
    // fontSize: "1.5rem !important",
    font: "normal normal 500 26px/34px poppins !important",
  },
  input: {
    display: "none",
  },
  buttonDiv: {
    marginTop: "10%",
    marginLeft: "50%",
  },
  button: {
    borderRadius: "20px !important",
    backgroundColor: "#E0FFF3",
    border: "1px solid #57B793",
    color: "#57B793",
    // width: "80%",
  },

  sampleButton: {
    borderRadius: "20px !important",
    //  backgroundColor: "#111",
    border: "1px solid #333 !important",
    paddingLeft: "25px",
    paddingRight: "25px",
    backgroundColor: "black !important",
    width: "80%",
  },
  bod: {
    backgroundColor: "#FFF",
    height: "65vh",
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  footerButtonGrid: {
    marginTop: "50px !important",
    marginBottom: "20px !important",
  },
  footerButton: {
    margin: theme.spacing(1),
    borderRadius: "25px !important",
  },
  saveButton: {
    margin: theme.spacing(1),
    borderRadius: "25px !important",
    color: "#5BB996",
  },
  cssLabel: {
    color: "#333",
  },
  headerFont: {
    font: "normal normal 600 15px/20px Nunito Sans",
    letterSpacing: "0px",
    color: "#212121",
    borderRightWidth: 1,
    borderColor: "#E8E8E8",
    borderTop: "1px solid #E8E8E8",
    padding:'8px !important'
  },
  textfield: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#5CB593",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5CB593",
      },
    },
  },
  tableContent: {
    // font: "normal normal normal 27px/38px Nunito Sans ",
    letterSpacing: "0px",
    // color: "#212121",
    borderColor: "#E8E8E8",
    borderRightWidth: 1,
    padding:'4px !important'
  },
  buttonOutlined: {
    // border: "1px solid #333333",
    // borderRadius: "20px",
    // opacity: 1,
    width: "200px",
    height: "48px",
  },

  planButton: {
    width: "150px",
    height: "48px",
  },
  deleteMessage: {
    textAlign: "left",
    font: "normal normal 500 26px/34px poppins",
    letterSpacing: "0px",
    color: "#000000",
    opacity: 1,
  },
  actions: {
    paddingBottom: "25px",
  },
  addSimButton: {
    paddingLeft: "10px",
    paddingLeft: "10px",
  },
}));
