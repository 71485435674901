import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { CustomSelect, CustomTextField } from "../../hoc/CustomComponents";
import CloseIcon from "@mui/icons-material/Close";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";
const allStatus = ["INPROGRESS","PENDING"];
export default function UpdateTicket({
  open,
  ticketId,
  vin,
  otp,
  status,
  reason,
  handleOtp,
  handleReason,
  handleStatus,
  handleClose,
  handleSubmit,
}) {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={"md"}
      fullWidth={true}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="h4">Update Request</Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  style={{ float: "right" }}
                  onClick={() => handleClose()}
                >
                  <CloseIcon
                    style={{
                      fill: "#2b2924",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomTextField
                  value={ticketId}
                  label="Request ID"
                  placeholder="ticketId"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField value={vin} label="Vin" disabled={true} />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  value={otp}
                  label="OTP"
                  type="number"
                  placeholder="Enter OTP"
                  onChange={handleOtp}
                  onKeyPress={handleKeyPress}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  value={reason}
                  label="Reason"
                  placeholder="Enter Reason"
                  onChange={handleReason}
                  onKeyPress={handleKeyPress}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomSelect
                  label="Status"
                  placeholder="Select the Status"
                  options={allStatus}
                  value={status}
                  handleSelectChange={handleStatus}
                  getOptionLabel={(option) => option}
                  onKeyPress={handleKeyPress}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={2}>
                <ColorOutlinedButton
                  variant="outlined"
                  onClick={() => handleClose()}
                >
                  Cancel
                </ColorOutlinedButton>
              </Grid>
              <Grid item xs={2}>
                <ColorButton variant="outlined" onClick={handleSubmit}>
                  Submit
                </ColorButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
