import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import {
  CustomCircularProgress,
  CustomSelect,
  CustomTextArea,
  CustomTextField,
} from "../../hoc/CustomComponents";
import CloseIcon from "@mui/icons-material/Close";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";
// const allStatus = ["INPROGRESS", "RESOLVED"];
export default function AssignTicket({
  open,
  vin,
  requestId,
  handleCloseAssign,
  handleAssignL2Submit,
  allCategories,
  category,
  subCategory,
  allSubCat,
  handleCategory,
  handleSubCategory,
  handleRemarks,
  remark,
  upload,
  filename,
  snackbarOpen,
  assignLoading,
  subCatLoading,
  validFile,
}) {
  return (
    <Dialog
      open={open}
      maxWidth={"md"}
      fullWidth={true}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="h4">Assign Ticket</Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  style={{ float: "right" }}
                  onClick={() => handleCloseAssign()}
                >
                  <CloseIcon
                    style={{
                      fill: "#2b2924",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomTextField value={vin} label="Vin" disabled={true} />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  value={requestId}
                  label="Request ID"
                  disabled={true}
                />
              </Grid>

              <Grid item xs={6}>
                <CustomSelect
                  label="Category"
                  placeholder="Select the Category"
                  value={category}
                  options={allCategories}
                  getOptionLabel={(options) => options.ticket_category_name}
                  handleSelectChange={handleCategory}
                />
              </Grid>
              <Grid item xs={6}>
                {/* {subCatLoading ? (
                  <Grid item xs={6} style={{ marginTop: "30px" }}>
                    <CircularProgress />
                  </Grid>
                ) : ( */}
                <CustomSelect
                  label="Sub Category"
                  placeholder="Select the Sub Category"
                  options={allSubCat}
                  value={subCategory}
                  handleSelectChange={handleSubCategory}
                  disabled={!category}
                  getOptionLabel={(option) => option.sub_category_name}
                />
                {/* )} */}
              </Grid>
              <Grid item xs={6}>
                <CustomTextArea
                  value={remark}
                  label="Remarks"
                  placeholder="Remarks"
                  type="text"
                  onChange={(e) => {
                    handleRemarks(e.target.value);
                  }}
                  rows="3"
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  variant="contained"
                  placeholder="Upload template"
                  label="Upload File"
                  // disabled={true}
                  type="file"
                  onChange={upload}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={2}>
                <ColorOutlinedButton
                  variant="outlined"
                  onClick={() => handleCloseAssign()}
                >
                  Cancel
                </ColorOutlinedButton>
              </Grid>
              <Grid item xs={2}>
                {assignLoading ? (
                  <CircularProgress style={{ color: "#57B793" }} />
                ) : (
                  <ColorButton
                    variant="outlined"
                    onClick={handleAssignL2Submit}
                    disabled={
                      category == null ||
                      category == "" ||
                      subCategory == null ||
                      subCategory == "" ||
                      snackbarOpen ||
                      !validFile
                    }
                  >
                    Submit
                  </ColorButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
