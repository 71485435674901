import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
} from "@mui/material";
import {
  ColorButton,
  ColorOutlinedButton,
  CustomButton,
} from "../../hoc/PaletteComponent";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import useStyles from "./styles";
import { gql } from "@apollo/client";
import { client2 } from "../tables/client";

/**
 * @summary getPublicDownloadURL schema
 */
const GET_DOWNLOAD_URL = gql`
  query($bucketName: String!, $filename: String!) {
    getPublicDownloadURL(bucketName: $bucketName, filename: $filename)
  }
`;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const statusType = ["IN_PROGRESS", "SOLVING", "FIXED", "CLOSED"];
export default function ViewIssue({
  open,
  handleClose,
  ticketNumber,
  issue,
  comment,
  status,
  subject,
  attached_filename_url,
  attached_screenshot_url,
  onDownload,
}) {
  /**
   * @summary This sets the value of file type
   */
  const handleFileType = (fileType, fileName) => {
    // setfileType(fileType);
    // setfilename(fileName);
    onDownload(fileType, fileName);
  };
  const classes = useStyles();
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogContent>
          <form>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9}>
                    <Typography variant="h4">Ticket Details</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      style={{ float: "right" }}
                      onClick={() => handleClose()}
                    >
                      <CloseIcon
                        style={{
                          fill: "#2b2924",
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table aria-label="customized table">
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              align="center"
                              className={classes.tableContent}
                            >
                              Ticket Number
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableContent}>
                              {ticketNumber}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              align="center"
                              className={classes.tableContent}
                            >
                              Subject
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableContent}>
                              {subject}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              align="center"
                              className={classes.tableContent}
                            >
                              Issue
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.tableContent}
                              size="medium"
                              colSpan={3}
                            >
                              {issue}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              align="center"
                              className={classes.tableContent}
                            >
                              Attached File
                            </StyledTableCell>
                            <StyledTableCell>
                              {attached_filename_url ? (
                                <CustomButton
                                  style={{ fontSize: "8px" }}
                                  onClick={() =>
                                    handleFileType(
                                      "file",
                                      attached_filename_url
                                    )
                                  }
                                  variant="contained"
                                >
                                  Download
                                </CustomButton>
                              ) : (
                                <CustomButton
                                  style={{ fontSize: "8px" }}
                                  variant="contained"
                                >
                                  N/A
                                </CustomButton>
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              align="center"
                              className={classes.tableContent}
                            >
                              Attached Screenshot
                            </StyledTableCell>
                            <StyledTableCell>
                              {attached_screenshot_url ? (
                                <CustomButton
                                  style={{ fontSize: "8px" }}
                                  onClick={() =>
                                    handleFileType(
                                      "screenshot",
                                      attached_screenshot_url
                                    )
                                  }
                                  variant="contained"
                                >
                                  Download
                                </CustomButton>
                              ) : (
                                <CustomButton
                                  style={{ fontSize: "8px" }}
                                  variant="contained"
                                >
                                  N/A
                                </CustomButton>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              align="center"
                              className={classes.tableContent}
                            >
                              Comment
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.tableContent}
                              size="medium"
                              colSpan={3}
                            >
                              {comment}
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
