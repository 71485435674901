import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  logotype: {
    // marginLeft: theme.spacing(2.5),
    // marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  selectBox: {
    color: "#333 !important",
    backgroundColor: "#F3F3F3 !important",
    // border: "2px solid #CFD1D1 !important",
    borderRadius: "8px !important",
  },
  logoImg: {
    width: "50%",
    marginLeft: "30px",
  },
  eicherlogoImg: {
    width: "20%",
    // height: "20%",

    marginLeft: "10px",
  },
  appBar: {
    width: "100vw",
    zIndex: "1300 !important",
    // transition: theme.transitions.create(["margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
  },
  toolbar: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    backgroundColor: "#FFFFFE",
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: 25,
    paddingLeft: theme.spacing(2.5),
    width: 36,
    backgroundColor: `rgba(${theme.palette.common.black / 0})`,
    transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: `rgba(${(theme.palette.common.black, 0.08)})`,
    },
  },
  avatarDiv: {
    display: "flex !important",
  },
  avatar: {
    width: "20px !important",
    height: "20px !important",
    paddingRight: "25px !important",
    borderRadius: "0 !important",
  },
  noAvatar: {
    width: "20px !important",
    height: "20px !important",
    // paddingRight: "25px !important",
    // borderRadius: "0 !important",
  },
  searchFocused: {
    backgroundColor: `rgba(${theme.palette.common.black / 0.08})`,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 250,
    },
  },
  searchIcon: {
    width: 36,
    right: 0,
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
      // color: "white",
    },
  },
  headerMenuButton: {
    // marginLeft: theme.spacing(2),
    // padding: theme.spacing(0.5),
  },
  headerMenuButtonSandwich: {
    marginLeft: 9,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: 28,
    color: "#333",
    //color: "rgba(255, 255, 255, 0.35)",
  },
  headerIconCollapse: {
    color: "white",
  },
  profileMenu: {
    minWidth: 265,
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  },
  profileMenuItem: {
    // color: theme.palette.text.hint,
  },
  profileMenuIcon: {
    marginRight: "5px",
    color: theme.palette.text.hint,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  messageNotification: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
  },
  messageNotificationSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "5px",
  },
  messageNotificationBodySide: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  sendMessageButton: {
    // margin: theme.spacing(4),
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    textTransform: "none",
  },
  sendButtonIcon: {
    // marginLeft: theme.spacing(2),
  },
  purchaseBtn: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    // marginRight: theme.spacing(3),
  },
  textfield: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#5CB593",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5CB593",
      },
    },
  },
  customAutocompleteMenu: {
    // Add your custom autocomplete menu styles here
    backgroundColor: "red", // Change this to your desired color
    color: "#333", // Change this to your desired text color
    // Additional styles if needed
  },
}));
