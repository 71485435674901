import React from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

export default function AlertDialog({
  isDialogOpened,
  handleCloseDialog,
  errorData,
  excelData,
  columns,
  title,
}) {
  const handleClose = () => {
    //setOpen(false);
    handleCloseDialog();
  };
  let obj;
  if (excelData.failedUploadList) {
    obj = JSON.parse(excelData.failedUploadList);
  } else {
    obj = JSON.parse(excelData.SimfailedUploadList);
  }

  const options = {
    search: true,
    download: true,
    selectableRows: false,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Dialog
          open={isDialogOpened}
          disableEscapeKeyDown={true}
          maxWidth={"lg"}
        >
          <Grid container>
            <Grid item xs={12}>
              <DialogTitle id="alert-dialog-title">
                {"Upload Response"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {errorData ? (
                    errorData
                  ) : (
                    <>
                      {excelData
                        ? (console.log("----excel----", excelData),
                          (
                            <>
                              <span>
                                Total records :{excelData.totalExcelDataRecords}
                              </span>
                              <span>
                                Successfully Uploaded:
                                {excelData.successfullyUploaded}
                              </span>
                              <span>
                                Total Failed Records:{excelData.failedToUpload}
                              </span>
                              <span>
                                Total Duplicated Records:
                                {excelData.totalDuplicateRecords}
                              </span>
                              <MUIDataTable
                                title={title}
                                data={obj}
                                columns={columns}
                                options={options}
                              />
                              {/* <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <>
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  </>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {obj.map(
                                (row) => (
                                  console.log(row),
                                  (
                                    <TableRow key={row.name}>
                                      <TableCell component="th" scope="row">
                                        {row.device}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.imei}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.serialNumber}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.iccid}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.error}
                                      </TableCell>
                                    </TableRow>
                                  )
                                ),
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer> */}
                            </>
                          ))
                        : null}
                    </>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} style={{ color: "#5CB593" }}>
                  Close
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    </Grid>
  );
}
