import { React, useState, useEffect } from "react";
import {
  Button,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Typography,
  TableBody,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { useLazyQuery, gql } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";

// styles
import useStyles from "./styles";

//Components
import GroupMaxWidthDialog from "./addDeviceGroup";
import { debounceSearchRender } from "mui-datatables";

import { debounce } from "throttle-debounce";
import { ColorButton } from "../../hoc/PaletteComponent";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";

const getFeatureAccess = gql`
  query($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const getTcuGroupData = gql`
  query($oemId: Int!) {
    getTcuGroup(oemId: $oemId) {
      id
      oemId {
        id
        oemName
        oemCode
        oemEmail
        phoneNumber
      }
      tcuId {
        id
        vendorId
        vendorCode
        tcuName
        ownerId
        tcu_code
      }
      groupName
      ownerId
    }
  }
`;

export default function DeviceGroup(props) {
  var classes = useStyles();
  const [readPermission, setReadPermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [editId, setId] = useState(0);
  let [next, setNext] = useState(1);

  let id;
  if (typeof props.featureId != "undefined") {
    localStorage.setItem("featureId", props.featureId);
    id = props.featureId;
  } else {
    id = parseInt(localStorage.getItem("featureId"));
  }

  const debounceSearchRender = debounce(1000, (event) => {
    console.log("debounce");
    //  call search api here
  });
  const handleSearch = (event) => {
    console.log("search");
    event.persist();
    setTimeout(debounceSearchRender(event, 2000));
  };
  const [getAccess, { loading, error, data }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });

  const [getTcuGroup, { loading: tcuLoading, data: tcuData }] = useLazyQuery(
    getTcuGroupData,
    {
      fetchPolicy: "network-only",
    }
  );

  const handleOpen = (id, oemId) => {
    setOpen(true);
    setId(id);
  };

  //Call the Feature Access Permission on page load
  useEffect(() => {
    getAccess({
      variables: {
        featureId: id,
      },
    });

    getTcuGroup({
      variables: {
        oemId: props.oem,
      },
    });
  }, [isOpen]);

  useEffect(() => {
    if (props.oem) {
      getTcuGroup({
        variables: {
          oemId: props.oem,
        },
      });
    }
  }, [props.oem]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ backgroundColor: "#F3F3F3 " }}>
          <Typography variant="h4">Device Group Manager</Typography>
        </Grid>

        {readPermission ? (
          <>
            <Grid
              container
              style={{ backgroundColor: "#FFFFFF", marginTop: "20px" }}
            >
              {createPermission ? (
                <>
                  <Grid item xs={6} style={{ padding: "30px" }}>
                    <TextField
                      placeholder="Search Number"
                      variant="outlined"
                      onClick={handleSearch}
                      // multiline
                      style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon style={{ paddingLeft: "3px" }} />
                          </InputAdornment>
                        ),
                      }}
                      className={classes.textfield}
                      // style={{  }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ padding: "30px" }}>
                    <Grid container justifyContent="flex-end">
                      <Grid item xs={4}>
                        <ColorButton
                          // className={classes.button}
                          style={{ float: "right" }}
                          variant="outlined"
                          onClick={() => setOpen(true)}
                        >
                          <Typography> Add Device Group</Typography>
                        </ColorButton>
                      </Grid>
                      {isOpen ? (
                        <GroupMaxWidthDialog
                          isDialogOpened={isOpen}
                          handleCloseDialog={() => setOpen(false)}
                          oemId={props.oem}
                          editId={editId}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </>
              ) : null}

              <Grid item xs={12}>
                {tcuLoading ? (
                  <CustomCircularProgress />
                ) : tcuData && tcuData.getTcuGroup.length > 0 ? (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{
                              font: "normal normal 600 15px/20px Nunito Sans",
                              letterSpacing: "0px",
                              color: "#212121",
                              borderRightWidth: 1,
                              borderColor: "#E8E8E8",
                              borderTop: "1px solid #E8E8E8",
                            }}
                          >
                            Sl.no
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              font: "normal normal 600 15px/20px Nunito Sans",
                              letterSpacing: "0px",
                              color: "#212121",
                              borderRightWidth: 1,
                              borderColor: "#E8E8E8",
                              borderTop: "1px solid #E8E8E8",
                            }}
                          >
                            Group Name
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              font: "normal normal 600 15px/20px Nunito Sans",
                              letterSpacing: "0px",
                              color: "#212121",
                              borderRightWidth: 1,
                              borderColor: "#E8E8E8",
                              borderTop: "1px solid #E8E8E8",
                            }}
                          >
                            Device Manuf. Code
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              font: "normal normal 600 15px/20px Nunito Sans",
                              letterSpacing: "0px",
                              color: "#212121",
                              borderRightWidth: 1,
                              borderColor: "#E8E8E8",
                              borderTop: "1px solid #E8E8E8",
                            }}
                          >
                            Device Model Code
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              font: "normal normal 600 15px/20px Nunito Sans",
                              letterSpacing: "0px",
                              color: "#212121",
                              borderRightWidth: 1,
                              borderColor: "#E8E8E8",
                              borderTop: "1px solid #E8E8E8",
                            }}
                          >
                            Device Model
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              font: "normal normal 600 15px/20px Nunito Sans",
                              letterSpacing: "0px",
                              color: "#212121",
                              borderRightWidth: 1,
                              borderColor: "#E8E8E8",
                              borderTop: "1px solid #E8E8E8",
                            }}
                          >
                            Group Added On
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              font: "normal normal 600 15px/20px Nunito Sans",
                              letterSpacing: "0px",
                              color: "#212121",
                              borderRightWidth: 1,
                              borderColor: "#E8E8E8",
                              borderTop: "1px solid #E8E8E8",
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tcuData ? (
                          tcuData.getTcuGroup.map((item, index) => {
                            return (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {item.groupName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {item.tcuId.vendorCode}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {item.tcuId.tcu_code}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {item.tcuId.tcuName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  N/A
                                </TableCell>
                                {updatePermission || deletePermission ? (
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {updatePermission ? (
                                      <>
                                        <Button
                                          onClick={() =>
                                            handleOpen(item.id, item.oemId.id)
                                          }
                                        >
                                          <CreateIcon />
                                        </Button>
                                      </>
                                    ) : null}
                                    {deletePermission ? (
                                      <Button>
                                        <DeleteIcon />{" "}
                                      </Button>
                                    ) : null}
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            );
                          })
                        ) : (
                          <CircularProgress />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoData />
                )}
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
    </div>
  );
}
