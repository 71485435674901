import {
  Button,
  Table,
  TableBody,
  TableCell,
  TablePagination,
} from "@mui/material";
import {
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  CustomCircularProgress,
  CustomDialogBox,
  NoData,
} from "../../hoc/CustomComponents";
import { ColorButton } from "../../hoc/PaletteComponent";
import useStyles from "./styles";
import UploadECUFiles from "./UploadECUFiles";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import { debounce } from "throttle-debounce";

const getFeatureAccess = gql`
  query($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const GET_SCHEDULE_TYPE = gql`
  query {
    getScheduleType {
      code
      message
      data {
        id
        scheduleName
        createdOn
      }
    }
  }
`;

const GET_FILE_TYPES = gql`
  query {
    getFileType {
      code
      message
      data {
        id
        fileTypeName
        createdOn
      }
    }
  }
`;

const GET_ECU_GROUPS = gql`
  query($oemId: Int!) {
    getOemEcuGroup(oemId: $oemId) {
      code
      message
      data {
        id
        oemId
        ecu_group_name
        createdOn
      }
    }
  }
`;

const GET_ECU_NAME = gql`
  query($groupId: Int!) {
    getOemEcuName(groupId: $groupId) {
      code
      message
      data {
        id
        ecu_group_id
        ecu_name
        createdOn
      }
    }
  }
`;

const CREATE_ECU_FILE = gql`
  mutation(
    $oemId: Int!
    $scheduleTypeId: Int!
    $fileTypeId: Int!
    $ecuGroupId: Int!
    $ecuNameId: Int!
    $filename: String!
    $createdBy: Int!
  ) {
    createEcuFileName(
      oemId: $oemId
      scheduleTypeId: $scheduleTypeId
      fileTypeId: $fileTypeId
      ecuGroupId: $ecuGroupId
      ecuNameId: $ecuNameId
      filename: $filename
      createdBy: $createdBy
    ) {
      code
      message
    }
  }
`;

const GET_ALL_ECU_FILES = gql`
  query($oemId: Int!, $limit: Int, $offset: Int) {
    getAllEcuFilesByOem(oemId: $oemId, limit: $limit, offset: $offset) {
      code
      message
      totalCount
      data {
        id
        oemId
        schedule_type_id
        scheduleName
        file_type_id
        fileTypeName
        ecu_group_id
        ecu_group_name
        ecu_name_id
        ecu_name
        filename
        createdBy
        createdByName
        createdOn
      }
    }
  }
`;

const UPDATE_ECU_FILE = gql`
  mutation(
    $id: Int!
    $oemId: Int!
    $scheduleTypeId: Int!
    $fileTypeId: Int!
    $ecuGroupId: Int!
    $ecuNameId: Int!
    $updatedBy: Int!
  ) {
    updateEcuFileName(
      id: $id
      oemId: $oemId
      scheduleTypeId: $scheduleTypeId
      fileTypeId: $fileTypeId
      ecuGroupId: $ecuGroupId
      ecuNameId: $ecuNameId
      updatedBy: $updatedBy
    ) {
      code
      message
    }
  }
`;

const DELETE_FILE = gql`
  mutation($id: Int!, $oemId: Int!, $fileName: String!) {
    deleteEcuFileName(id: $id, oemId: $oemId, fileName: $fileName) {
      code
      status
      remarks
    }
  }
`;
const columns = [
  {
    name: "Serial Number",
    minWidth: 150,
  },
  {
    name: "Schedule Type",
    minWidth: 300,
  },
  {
    name: "File Name",
    minWidth: 250,
  },
  {
    name: "ECU Name",
    minWidth: 150,
  },
  {
    name: "ECU Group",
    minWidth: 150,
  },
  {
    name: "File Type",
    minWidth: 200,
  },
  {
    name: "Uploaded By",
    minWidth: 150,
  },
  {
    name: "Uploaded Date",
    minWidth: 200,
  },
];

function Files(props) {
  const [openUpload, setOpenUpload] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [scheduleType, setScheduleType] = useState(null);
  const [ecuGroup, setEcuGroup] = useState(null);
  const [ecuName, setEcuName] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [allScheduleTypes, setAllScheduleTypes] = useState(null);
  const [allEcuGroups, setAllEcuGroups] = useState([]);
  const [allEcuNames, setAllEcuNames] = useState([]);
  const [allFileTypes, setAllFileTypes] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [readPermission, setReadPermission] = useState(false);
  const [upload, setUpload] = useState(null);
  const [ecuData, setEcuData] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [allData, setAllData] = useState(null);

  let [next, setNext] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const classes = useStyles();
  let id = 15;
  // if (typeof props.featureId != "undefined") {
  //   localStorage.setItem("featureId", props.featureId);
  //   id = props.featureId;
  // } else if (parseInt(localStorage.getItem("featureId"))) {
  //   id = parseInt(localStorage.getItem("featureId"));
  // } else {
  //   id = 15;
  // }

  console.log(id, "id");

  const [getAccess, { data: access }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });

  /**
   * @summary This handles the page change in pagination
   */
  const handleChangePage = async (event, newPage) => {
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    await getAllEcuFile({
      variables: {
        oemId: props.oem,
        limit: rowsPerPage,
        offset: newPage,
      },
    });
  };

  /**
   * @summary This handles the row change in pagination
   */
  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
    await getAllEcuFile({
      variables: {
        oemId: props.oem,
        limit: event.target.value,
        offset: 0,
      },
    });
  };

  /**
   * @summary This opens the modal to upload file
   */
  const handleUpload = () => {
    setOpenUpload(true);
  };

  /**
   * @summary This closes the modal and clear the states
   */
  const handleClose = () => {
    setOpenUpload(false);
    setScheduleType(null);
    setEcuGroup(null);
    setEcuName(null);
    setFileType(null);
    setFileName(null);
    setUpload(false);
    setEdit(false);
  };

  /**
   * @summary This opens the Confirm Modal
   */
  const handleDone = () => {
    setOpenConfirm(true);
  };

  /**
   * @summary This handle the schedulType state
   */
  const handleScheduleType = (e, select) => {
    if (select) {
      setScheduleType(select);
    } else {
      setScheduleType(null);
    }
  };

  /**
   * @summary This handles the ecuGroup state
   */
  const handleEcuGroup = (e, select) => {
    if (select) {
      setEcuGroup(select);
      setEcuName(null);
      getAllEcuName({
        variables: {
          groupId: select.id,
        },
      });
    } else {
      setEcuName(null);
      setEcuGroup(null);
    }
  };

  /**
   * @summary This handles the ecu Name state
   */
  const handleEcuName = (e, select) => {
    if (select) {
      setEcuName(select);
    } else {
      setEcuName(null);
    }
  };

  /**
   * @summary This handle the FileType state
   */
  const handleFileType = (e, select) => {
    setFileType(select);
  };

  /**
   * @summary This closes the confirm modal
   */
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  /**
   * @summary handles the api call to get all schedule types
   */
  const [getAllScheduleTypes] = useLazyQuery(GET_SCHEDULE_TYPE, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getScheduleType?.code == 200) {
        let allTypes = [];
        allTypes =
          res.getScheduleType?.data?.length > 0 &&
          res.getScheduleType.data.map((type) => type);
        setAllScheduleTypes(allTypes);
      }
    },
  });

  /**
   * @summary handles the api call to get all file types
   */
  const [getFileType] = useLazyQuery(GET_FILE_TYPES, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getFileType.code == 200) {
        let allFileTypes = [];
        allFileTypes =
          res.getFileType.data?.length > 0 &&
          res.getFileType.data?.map((file) => file);
        setAllFileTypes(allFileTypes);
      }
    },
  });

  /**
   * @summary handles the api call to get all ecu groups
   */
  const [getAllEcuGroups] = useLazyQuery(GET_ECU_GROUPS, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getOemEcuGroup.code == 200) {
        let allGroups = [];
        allGroups =
          res.getOemEcuGroup.data.length > 0 &&
          res.getOemEcuGroup.data.map((group) => group);
        console.log(allGroups);
        setAllEcuGroups(allGroups);
      }
    },
  });

  /**
   * @summary handles the api call to get all ecu names
   */
  const [getAllEcuName] = useLazyQuery(GET_ECU_NAME, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getOemEcuName.code == 200) {
        let allNames = [];
        allNames =
          res.getOemEcuName.data.length > 0 &&
          res.getOemEcuName.data.map((name) => name);
        setAllEcuNames(allNames);
      }
    },
  });

  /**
   * @summary handles the api call to create  ecu files
   */
  const [createEcuFile] = useMutation(CREATE_ECU_FILE, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
    },
    onCompleted: (res) => {
      console.log(res, "upload res");
      if (res.createEcuFileName.code == 200) {
        props.setMessage("Successfully Uploaded the file");
      } else {
        props.setMessage(
          res.createEcuFileName?.message
            ? res.createEcuFileName?.message
            : "Failed To Upload the file"
        );
      }
      getAllEcuFile({
        variables: {
          oemId: props.oem,
          limit: 5,
          offset: 0,
        },
      });
      props.setNetworkError(true);
      props.setSnackbar(true);
      setOpenConfirm(false);
      handleSnackbarClose();
      handleClose();
    },
  });

  /**
   * @summary handles the api call to update  ecu files
   */
  const [updateEcuFile] = useMutation(UPDATE_ECU_FILE, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
    },
    onCompleted: async (res) => {
      if (res.updateEcuFileName.code == 200) {
        props.setMessage("Successfully updated the file");
        props.setNetworkError(true);
        props.setSnackbar(true);
      } else {
        props.setMessage("Failed To update the file");
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      handleSnackbarClose();
      setOpenConfirm(false);
      handleClose();
      await getAllEcuFile({
        variables: {
          oemId: props.oem,
          limit: rowsPerPage,
          offset: page,
        },
      });
    },
  });

  /**
   * @summary This closes the snackbar
   */
  const handleSnackbarClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  /**
   * @summary handles the file upload to S3 bucket
   */
  const uploadFileOnS3 = async (file) => {
    var FormData = require("form-data");
    var data = new FormData();

    data.append("filedata", file, file.name);
    data.append("bucketName", "zel-fota");
    data.append("fileName", file.name);

    var config = {
      method: "post",
      url: process.env.REACT_APP_S3_BUCKET,
      data: data,
    };

    let response = await axios(config)
      .then(function (response) {
        console.log(response.data);
        props.setMessage("Uploaded Successfully!");
        props.setNetworkError(true);
        return response.data;
      })
      .catch(function (error) {
        return error;
      });

    return response;
  };

  /**
   * @summary handles file upload
   */
  const uploadFile = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (file) {
      setUpload(true);
      let bulkUpload = await uploadFileOnS3(file);
      if (bulkUpload.Code == 200) {
        setFileName(file.name);
        setUpload(false);
      } else {
        props.setMessage("Failed to Upload please try again");
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    } else {
      props.setMessage("No file is selected");
    }
  };

  /**
   * @summary handles file upload and edit
   */
  const addFile = async () => {
    let variables = {
      oemId: props.oem,
      scheduleTypeId: scheduleType.id,
      fileTypeId: fileType.id,
      ecuGroupId: ecuGroup.id,
      ecuNameId: ecuName.id,
      filename: fileName,
      createdBy: 19,
    };
    console.log(variables, "variables");
    if (edit) {
      await updateEcuFile({
        variables: {
          id: editId,
          oemId: props.oem,
          scheduleTypeId: scheduleType.id,
          fileTypeId: fileType.id,
          ecuGroupId: ecuGroup.id,
          ecuNameId: ecuName.id,
          updatedBy: props.token.id,
        },
      });
    } else {
      await createEcuFile({
        variables: {
          oemId: props.oem,
          scheduleTypeId: scheduleType.id,
          fileTypeId: fileType.id,
          ecuGroupId: ecuGroup.id,
          ecuNameId: ecuName.id,
          filename: fileName,
          createdBy: props.token.id,
        },
      });
    }
  };

  /**
   * @summary handles file  edit
   */
  const handleEdit = (ecu) => {
    setScheduleType({
      scheduleName: ecu.scheduleName,
      id: ecu.schedule_type_id,
    });
    setEcuGroup({ ecu_group_name: ecu.ecu_group_name, id: ecu.ecu_group_id });
    setEcuName({ ecu_name: ecu.ecu_name, id: ecu.ecu_name_id });
    setFileType({ fileTypeName: ecu.fileTypeName, id: ecu.file_type_id });
    setFileName(ecu.filename);
    setOpenUpload(true);
    setEditId(ecu.id);
    setEdit(true);
  };

  /**
   * @summary opens confirm modal to delete file
   */
  const handleDelete = (id, fileName) => {
    setOpenDelete(true);
    setEditId(id);
    setFileName(fileName);
  };

  /**
   * @summary closes the delete confoirmation modal
   */
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  /**
   * @summary api call to delete the file
   */
  const [handledeleteFile] = useMutation(DELETE_FILE, {
    errorPolicy: "all",
    onCompleted: (res) => {
      console.log(res, "res");
      if (res.deleteEcuFileName?.code == 200) {
        props.setMessage("Successfully Deleted the file");
        props.setNetworkError(true);
        props.setSnackbar(true);
      } else {
        props.setMessage(
          res.deleteEcuFileName?.remarks
            ? res.deleteEcuFileName?.remarks
            : "Failed to delete"
        );
        props.setNetworkError(true);
        props.setSnackbar(true);
      }
      setOpenDelete(false);

      setFileName(null);
      setEditId(null);
      handleSnackbarClose();

      getAllEcuFile({
        variables: {
          oemId: props.oem,
          limit: 5,
          offset: 0,
        },
      });
    },
  });

  /**
   * @summary handles the file delete
   */
  const deleteFile = async () => {
    console.log(editId, fileName);
    await handledeleteFile({
      variables: {
        oemId: props.oem,
        id: editId,
        fileName: fileName,
      },
    });
  };

  /**
   * @summary api call to fetch all ecu files
   */
  const [getAllEcuFile, { loading }] = useLazyQuery(GET_ALL_ECU_FILES, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      console.log(res, "res");
      if (res.getAllEcuFilesByOem?.data?.length > 0) {
        setEcuData(res.getAllEcuFilesByOem?.data);
        setTotalCount(res.getAllEcuFilesByOem.totalCount);
        getAlldata({
          variables: {
            oemId: props.oem,
            limit: res.getAllEcuFilesByOem.totalCount,
            offset: 0,
          },
        });
      } else {
        setEcuData([]);
        setTotalCount(0);
      }
    },
  });

  /**
   * @summary api call to fetch all  files
   */
  const [getAlldata] = useLazyQuery(GET_ALL_ECU_FILES, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      console.log(res, "res");
      if (res.getAllEcuFilesByOem?.data?.length > 0) {
        setAllData(res.getAllEcuFilesByOem?.data);
      } else {
        setAllData([]);
      }
    },
  });

  /**
   * @summary handles Search value
   */
  const handleSearchChange = (event) => {
    // setSearchText(event.target.value);
    setTimeout(debounceSearchRender(event.target.value), 2000);
  };
  const debounceSearchRender = debounce(1000, async (event) => {
    if (event) {
      const filteredRows = allData.filter((row) => {
        const searchText = event.toLowerCase();
        return (
          (row.scheduleName &&
            row.scheduleName.toLowerCase().includes(searchText)) ||
          (row.filename && row.filename.toLowerCase().includes(searchText)) ||
          (row.ecu_name && row.ecu_name.toLowerCase().includes(searchText)) ||
          (row.ecu_group_name &&
            row.ecu_group_name.toLowerCase().includes(searchText)) ||
          (row.fileTypeName &&
            row.fileTypeName.toLowerCase().includes(searchText)) ||
          (row.fileTypeName &&
            row.fileTypeName.toLowerCase().includes(searchText)) ||
          (row.createdByName &&
            row.createdByName.toLowerCase().includes(searchText))
        );
      });
      console.log(filteredRows, "filteredRows");
      setEcuData(filteredRows);
    } else {
      getAllEcuFile({
        variables: {
          oemId: props.oem,
          limit: 5,
          offset: 0,
        },
      });
    }
  });

  useEffect(() => {
    getAllScheduleTypes();
    getFileType();
    getAllEcuGroups({
      variables: {
        oemId: props.oem,
      },
    });
    getAllEcuFile({
      variables: {
        oemId: props.oem,
        limit: 5,
        offset: 0,
      },
    });
    getAccess({
      variables: {
        featureId: id,
      },
    });
  }, [props.oem]);
  return (
    <Grid container>
      <UploadECUFiles
        open={openUpload}
        handleClose={handleClose}
        allScheduleTypes={allScheduleTypes}
        allEcuGroups={allEcuGroups}
        allEcuNames={allEcuNames}
        allFileTypes={allFileTypes}
        handleDone={handleDone}
        handleScheduleType={handleScheduleType}
        handleEcuGroup={handleEcuGroup}
        handleEcuName={handleEcuName}
        handleFileType={handleFileType}
        scheduleType={scheduleType}
        ecuGroup={ecuGroup}
        ecuName={ecuName}
        fileType={fileType}
        upload={uploadFile}
        isUpload={upload}
        fileName={fileName}
        edit={edit}
      />
      <CustomDialogBox
        open={openDelete}
        content={`Are you sure you want to Delete This file?`}
        negativeResponseHandler={handleCloseDelete}
        negativeResponseButtonText="Cancel"
        positiveResponseHandle={deleteFile}
        positiveResponseButtonText="Delete"
      />
      <CustomDialogBox
        open={openConfirm}
        content={`Are you sure you want to upload This file?`}
        negativeResponseHandler={handleCloseConfirm}
        negativeResponseButtonText="Cancel"
        positiveResponseHandle={addFile}
        positiveResponseButtonText="Confirm"
      />
      <Grid item xs={12} style={{ backgroundColor: "#FFFFFF", marginTop: 20 }}>
        <Grid container>
          <Grid item xs={12} style={{ paddingTop: "4vh" }}>
            <Grid container>
              <Grid item xs={12} sm={6} style={{ paddingLeft: "2vw" }}>
                <TextField
                  placeholder="Search Here"
                  variant="outlined"
                  style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                  size="small"
                  className={classes.textfield}
                  onChange={(e) => handleSearchChange(e)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ paddingLeft: "3px" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6} sm={6}>
                {createPermission ? (
                  <Grid container justifyContent="flex-end">
                    <Grid
                      item
                      xs={4}
                      md={4}
                      sm={6}
                      style={{ paddingRight: "2vw" }}
                    >
                      <ColorButton onClick={handleUpload}>
                        <Typography className={classes.buttonText}>
                          Upload New File
                        </Typography>
                      </ColorButton>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "4vh" }}>
            {loading ? (
              <CustomCircularProgress />
            ) : ecuData && ecuData.length > 0 ? (
              <>
                <TableContainer
                  style={{
                    maxHeight: 440,
                  }}
                >
                  <Table stickHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => {
                          return (
                            <TableCell
                              align="center"
                              style={{
                                minWidth: column.minWidth,
                                font: "normal normal 600 15px/20px Nunito Sans",
                              }}
                              className={classes.tableHead}
                            >
                              {column.name}
                            </TableCell>
                          );
                        })}
                        {updatePermission && (
                          <TableCell
                            align="center"
                            style={{
                              minWidth: 200,
                              font: "normal normal 600 15px/20px Nunito Sans",
                            }}
                            className={classes.tableHead}
                          >
                            Action
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ecuData.map((ecu, index) => {
                        let created = ecu.createdOn
                          ? moment
                              .unix(ecu.createdOn)
                              .format("DD-MM-YYYY HH:mm:ss")
                          : null;
                        return (
                          <TableRow>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {next + (index + 1)}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {ecu.scheduleName}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {ecu.filename}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {ecu.ecu_name}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {ecu.ecu_group_name}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {ecu.fileTypeName}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {ecu.createdByName}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {created}
                            </TableCell>
                            {updatePermission && (
                              <TableCell
                                align="center"
                                className={classes.tableContent}
                              >
                                <Grid
                                  container
                                  alignContent="center"
                                  justifyContent="center"
                                  style={{
                                    height: "2px",
                                  }}
                                >
                                  {updatePermission ? (
                                    <Grid item>
                                      <Button onClick={() => handleEdit(ecu)}>
                                        <CreateIcon
                                          style={{
                                            fill: "#2b2924",
                                          }}
                                        />
                                      </Button>
                                    </Grid>
                                  ) : null}
                                  {deletePermission ? (
                                    <>
                                      <Grid item>
                                        <Button
                                          onClick={() =>
                                            handleDelete(ecu.id, ecu.filename)
                                          }
                                        >
                                          <DeleteIcon
                                            style={{
                                              fill: "#2b2924",
                                            }}
                                          />
                                        </Button>
                                      </Grid>
                                    </>
                                  ) : null}
                                </Grid>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={totalCount ? totalCount : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <NoData />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default withSnackbar(Files);
