import React, { useEffect, useState, useMemo } from "react";
import { Grid, Tabs, Tab } from "@mui/material";
import useStyles from "./styles";
import KycJourney from "./KycJourney";
import VinImeMap from "./VinImeMap";

export default function VinKyc(props) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            value={activeTab}
            className={classes.root}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#57B793" } }}
          >
            <Tab label="VIN Journey" className={classes.tab} />
            <Tab label="KYC Processing" className={classes.tab} />
          </Tabs>
        </Grid>
      </Grid>
      {activeTab === 1 && (
        <KycJourney
          oem={props.oem}
          roleId={props.token.roleId}
          featureId={props.featureId}
        />
      )}
      {activeTab === 0 && (
        <VinImeMap oem={props.oem} featureId={props.featureId} />
      )}
    </React.Fragment>
  );
}
