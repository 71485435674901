import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink(
  {
    uri: process.env.REACT_APP_AQUILA_URL,
  },
  onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
  })
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = process.env.REACT_APP_AQUILA_TOKEN;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      loggedinuserid: 1,
    },
  };
});

export const client3 = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
