import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Button,
  Snackbar,
} from "@mui/material";
import { CustomSelect, CustomTextField } from "../../hoc/CustomComponents";
import { ColorButton, ColorSampleButton } from "../../hoc/PaletteComponent";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";

export default function AssignVinToDealer({
  open,
  handleClose,
  assignVin,
  handleAssignVin,
  assignVinList,
  handleAssignDealer,
  assignDealerCode,
  assignDealerCodeList,
  assignDealerName,
  setAssignDealerName,
  assignDealerContactNo,
  setAssignDealerContactNo,
  snackbar,
  handleAssignVehicleToDealer,
}) {
  /**
   * @summary This handle the Dealer Name
   */
  const handleDealerName = (e) => {
    setAssignDealerName(e);
  };

  /**
   * @summary This handle the Dealer value
   */
  const handleDealerContactNumber = (e) => {
    setAssignDealerContactNo(e);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                {" "}
                <Typography variant="h4">Assign VIN to Dealer</Typography>
              </Grid>
              <Grid item xs={3}>
                <Button style={{ float: "right" }} onClick={handleClose}>
                  <CloseIcon
                    style={{
                      fill: "#2b2924",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomSelect
                  label="VIN*"
                  placeholder="Select the VIN*"
                  value={assignVin}
                  options={assignVinList}
                  getOptionLabel={(options) => options}
                  handleSelectChange={handleAssignVin}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomSelect
                  label="Dealer Code*"
                  placeholder="Select the Dealer Code*"
                  value={assignDealerCode}
                  options={assignDealerCodeList}
                  getOptionLabel={(options) => options}
                  handleSelectChange={handleAssignDealer}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  type="text"
                  value={assignDealerName}
                  label="Dealer Name*"
                  placeholder={"Provide Dealer Name*"}
                  onChange={(e) => handleDealerName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  type="number"
                  value={assignDealerContactNo}
                  label="Dealer Contact Number*"
                  placeholder={"Provide Dealer Number*"}
                  onChange={(e) => handleDealerContactNumber(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent={"flex-end"}
          spacing={2}
          style={{ padding: "0px 16px 8px 0px" }}
        >
          <Grid item>
            <ColorSampleButton
              variant="outlined"
              style={{ width: "100%" }}
              onClick={handleClose}
            >
              Cancel
            </ColorSampleButton>
          </Grid>
          <Grid item>
            <ColorButton
              variant="outlined"
              style={{ width: "100%" }}
              onClick={handleAssignVehicleToDealer}
              disabled={
                !assignVin ||
                !assignDealerCode ||
                !assignDealerName ||
                !assignDealerContactNo ||
                snackbar
              }
            >
              Submit
            </ColorButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
