import {
  Grid,
  Typography,
  InputAdornment,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  CircularProgress,
} from "@mui/material";
import classStyle from "./styles";
import React, { useState, useEffect , useCallback} from "react";
import moment from "moment";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import { debounce } from "throttle-debounce";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  CustomCircularProgress,
  NoData,
  ValidateButton,
} from "../../hoc/CustomComponents";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FileSaver from "file-saver";
import axios from "axios";
import { ColorButton, CustomButton } from "../../hoc/PaletteComponent";
import CreateKyc from "./CreateKyc";
import { client3 } from "./client";
import ImagePreview from "./ImagePreview";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
const columns = [
  {
    id: "no",
    label: "Sr.No",
    minWidth: 100,
  },

  {
    id: "vin",
    label: "VIN",
    minWidth: 200,
  },
  {
    id: "imei",
    label: "IMEI",
    minWidth: 200,
  },
  {
    id: "dealerCode",
    label: "Dealer Code",
    minWidth: 200,
  },
  {
    id: "dealerName",
    label: "Dealer Name",
    minWidth: 350,
  },
  {
    id: "kycMode",
    label: "KYC Mode",
    minWidth: 200,
  },
  // {
  //   id: "proofofaddress",
  //   label: "Proof of Address",
  //   minWidth: 200,
  // },
  // {
  //   id: "proofofidentity",
  //   label: "Proof of Identity",
  //   minWidth: 200,
  // },
  {
    id: "Kyc Status",
    label: "Vehicle Make",
    minWidth: 200,
  },
  {
    id: "lastKYCRecieved",
    label: "Last KYC Received Date",
    minWidth: 200,
  },
  {
    id: "KYCApproved",
    label: "KYC Approved Date",
    minWidth: 200,
  },
  {
    id: "KYCCount",
    label: "KYC Count",
    minWidth: 200,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 200,
  },

  // {
  //   id: "ticketCreated",
  //   label: "Ticket Created",
  //   minWidth: 200,
  // },
  // {
  //   id: "customerMobile1",
  //   label: "Customer Mobile1",
  //   minWidth: 150,
  // },
  // {
  //   id: "retailInvoiceNumber",
  //   label: "Retail Invoice Number",
  //   minWidth: 150,
  // },
  // {
  //   id: "customerAddress",
  //   label: "Customer Address",
  //   minWidth: 150,
  // },
  // {
  //   id: "customerEmail",
  //   label: "Customer Email",
  //   minWidth: 150,
  // },
  // {
  //   id: "dealerCode",
  //   label: "Dealer Code",
  //   minWidth: 150,
  // },
  // {
  //   id: "dealerName",
  //   label: "Dealer Name",
  //   minWidth: 150,
  // },
  // {
  //   id: "dealerAddress",
  //   label: "Dealer Address",
  //   minWidth: 150,
  // },
  // {
  //   id: "dealerTown",
  //   label: "Dealer Town",
  //   minWidth: 150,
  // },
  // {
  //   id: "dealerContactName",
  //   label: "Dealer ContactName",
  //   minWidth: 150,
  // },
  // {
  //   id: "dealerContactNumber",
  //   label: "Dealer ContactNumber",
  //   minWidth: 150,
  // },
  // {
  //   id: "approve",
  //   label: "Approve",
  //   minWidth: 200,
  // },
  // {
  //   id: "reject",
  //   label: "Reject",
  //   minWidth: 200,
  // },
];

const GET_UPLOAD_URL = gql`
  mutation ($fileExtension: String!) {
    getUploadURL(fileExtension: $fileExtension) {
      bucketName
      filename
      UploadURL
    }
  }
`;

const GET_DOWNLOAD_URL = gql`
  query ($bucketName: String!, $filename: String!) {
    getKycDownloadURL(bucketName: $bucketName, filename: $filename) {
      code
      remarks
      url
    }
  }
`;

const EXCEL_DOWNLOAD = gql`
  mutation ($oemId: Int!, $downloadFor: String!) {
    excelFileDownload(oemId: $oemId, downloadFor: $downloadFor) {
      downloadUrl
    }
  }
`;

const SET_KYC_DETAILS = gql`
  mutation (
    $apiKey: String!
    $count: Int!
    $currentTime: String!
    $newKYCList: [kycList]!
  ) {
    setKycDetails(
      apiKey: $apiKey
      count: $count
      currentTime: $currentTime
      newKYCList: $newKYCList
    ) {
      response {
        message
        count
        result
      }
    }
  }
`;

const CREATE_KYC_DETAILS = gql`
  mutation (
    $oemId: Int!
    $count: String!
    $vin: String!
    $imei: String
    $engineNumber: String!
    $yearOfmg: String!
    $vehicleMake: String!
    $vehicleModel: String!
    $fuelType: String!
    $customerID: String!
    $customerName: String!
    $customerMobile1: String!
    $retailInvoiceNumber: String
    $customerAddress: String!
    $customerEmail: String!
    $proofOfAddress: String!
    $proofOfIdentity: String
    $dealerCode: String!
    $dealerName: String!
    $dealerAddress: String!
    $dealerTown: String!
    $dealerContactName: String!
    $dealerContactNumber: String!
  ) {
    createKycDetails(
      oemId: $oemId
      count: $count
      vin: $vin
      imei: $imei
      engineNumber: $engineNumber
      yearOfmg: $yearOfmg
      vehicleMake: $vehicleMake
      vehicleModel: $vehicleModel
      fuelType: $fuelType
      customerID: $customerID
      customerName: $customerName
      customerMobile1: $customerMobile1
      retailInvoiceNumber: $retailInvoiceNumber
      customerAddress: $customerAddress
      customerEmail: $customerEmail
      proofOfAddress: $proofOfAddress
      proofOfIdentity: $proofOfIdentity
      dealerCode: $dealerCode
      dealerName: $dealerName
      dealerAddress: $dealerAddress
      dealerTown: $dealerTown
      dealerContactName: $dealerContactName
      dealerContactNumber: $dealerContactNumber
    ) {
      response {
        message
        count
        result
      }
    }
  }
`;

const GET_KYC_DETAILS = gql`
  query ($oemId: Int!, $limit: Int!, $offset: Int!, $kycSearch: String) {
    getKycDetails(
      oemId: $oemId
      limit: $limit
      offset: $offset
      kycSearch: $kycSearch
    ) {
      totalCount
      kycRows {
        imei
        serialNumber
        iccid
        vin
        engineNumber
        yearOfmg
        vehicleMake
        vehicleModel
        fuelType
        customerID
        customerName
        customerMobile1
        retailInvoiceNumber
        customerAddress
        customerEmail
        proofOfAddress
        proofOfIdentity
        dealerCode
        dealerName
        kycReceivedDate
        dealerAddress
        dealerTown
        dealerContactName
        dealerContactNumber
        kyccount
        kycApprovedDate
        kycRejectedDate
        lastKycReceivedDate
        vehicleActivationDate
        kycMode
        ticketCreated
        status
        rejectionReason
        simPlanType
        simSubscriptionStartDate
        simSubscriptionEndDate
      }
    }
  }
`;

/**
 * @summary status options for filtering the data
 */
const statusOptions = [
  "All",
  // "VIN-IMEI Mapped",
  // "VIN_EOLT_completed",
  "KYC_RECEIVED",
  // "IMEI_NOT_MAPPED",
  "KYC_APPROVED",
  "KYC_REJECTED",
];
function KycJourney(props) {
  const classes = classStyle();
  const [kycJouney, setkycJouney] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [allData, setAlldata] = useState(null);
  const [filterText, setFilterText] = useState("");
  let [next, setNext] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [sortingOrder, setSortingOrder] = useState("ascending");
  const [isSort, setisSort] = React.useState(false);
  const [sortColumns, setSortColumn] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState("1");
  const [imei, setImei] = useState(null);
  const [vin, setVin] = useState(null);
  const [engineNumber, setEngineNumber] = useState(null);
  const [yearOfmg, setYearOfmg] = useState(null);
  const [vehicleMake, setVehicleMake] = useState(null);
  const [vehicleModel, setVehicleModel] = useState(null);
  const [fuelType, setFuelType] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [customerMobile1, setCustomerMobile1] = useState(null);
  const [retailInvoiceNumber, setRetailInvoiceNumber] = useState(null);
  const [customerAddress, setCustomerAddress] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [proofOfAddress, setProofOfAddress] = useState(null);
  const [proofOfIdentity, setProofOfIdentity] = useState(null);
  const [dealerCode, setDealerCode] = useState(null);
  const [dealerName, setDealerName] = useState(null);
  const [dealerAddress, setDealerAddress] = useState(null);
  const [dealerTown, setDealerTown] = useState(null);
  const [dealerContactName, setDealerContactName] = useState(null);
  const [dealerContactNumber, setDealerContactNumber] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(null);
  const [previewUrl, setUrl] = useState(null);
  const [show, setShow] = useState(false);
  const [file, setuploadFile] = useState(null);
  const [POA, setPOA] = useState(true);
  const [POI, setPOI] = useState(true);
  const [fileType, setFileType] = useState(null);
  //  const [totalCount,setTotalCount]
  let history = useHistory();
  /**
   * @summary This handles the page change in pagination
   */
  const handleChangePage = async (event, newPage) => {
    setFilterText("");
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    await KycDetails({
      variables: { oemId: props.oem, limit: rowsPerPage, offset: newPage },
    });
  };

  /**
   * @summary This handles the modal of createKyc
   */
  const handleCreateKyc = async () => {
    setOpen(true);
  };

  /**
   * @summary This handles the modal of createKyc
   */
  const handleKycClose = async () => {
    setPOA(true);
    setPOI(true);
    setOpen(false);
  };

  /**
   * @summary This handles the row change in pagination
   */
  const handleChangeRowsPerPage = async (event) => {
    setFilterText("");
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
    await KycDetails({
      variables: { oemId: props.oem, limit: event.target.value, offset: 0 },
    });
  };

  /**
   * @summary closes the snackbar
   */
  const handleClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  /**
   * @summary formats the date
   */
  function formatDate(epochTime) {
    const date = new Date(epochTime * 1000);
    const year = date.getFullYear().toString().padStart(4, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return year + month + day + hours + minutes + seconds;
  }


  /**
   * @summary fetches  the kycDetails data with limit and offset
   */
  const [KycDetails, { error: error, loading: loading, data: Details }] =
    useLazyQuery(GET_KYC_DETAILS, {
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
        handleClose();
      },
      fetchPolicy: "network-only",
      onCompleted: async (result) => {
        setkycJouney(result.getKycDetails.kycRows);
        setTotalCount(result.getKycDetails.totalCount);
      },
    });

/**
   * @summary handles Search value
   */
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

/**
 * @summary returns the filtered array for search
 */
const debounceSearchRender = useCallback(debounce(async (input) => {
  console.log(props)
  if (input) 
    {
      fetchKycDetailsForSearch({
        variables: {
          oemId: props.oem,
          limit: totalCount,
          offset: 0,
          kycSearch: input.trim(),
        },
      });
    } else {
      await KycDetails({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
      });
    }
}, 1000), [props.oem,totalCount, rowsPerPage, page]);

const handleSearchChange = (event) => {
  const input = event.target.value;
  setFilterText(input);
  debounceSearchRender(input);
};

  // /**
  //  * @summary handles Search value
  //  */
  // const handleSearchChange = (event) => {
  //   setFilterText(event.target.value);
  //   setTimeout(debounceSearchRender(event.target.value), 2000);
  // };

  // /**
  //  * @summary returns the filter array for search
  //  */
  // const debounceSearchRender = debounce(1000, async (event) => {
  //   if (event) {
  //     fetchKycDetailsForSearch({
  //       variables: {
  //         oemId: props.oem,
  //         limit: totalCount,
  //         offset: 0,
  //         kycSearch: event.trim(),
  //       },
  //     });
  //     // const filteredRows = allData.filter((row) => {
  //     //   const searchText = event.toLowerCase();

  //     //   return (
  //     //     (row.vin && row.vin.toLowerCase().includes(searchText)) ||
  //     //     (row.imei && row.imei.toLowerCase().includes(searchText)) ||
  //     //     (row.dealerCode &&
  //     //       row.dealerCode.toLowerCase().includes(searchText)) ||
  //     //     (row.dealerName &&
  //     //       row.dealerName.toLowerCase().includes(searchText)) ||
  //     //     (row.kycMode && row.kycMode.toLowerCase().includes(searchText)) ||
  //     //     (row.vehicleMake &&
  //     //       row.vehicleMake.toLowerCase().includes(searchText)) ||
  //     //     (row.lastKycReceivedDate &&
  //     //       moment
  //     //         .unix(row.lastKycReceivedDate)
  //     //         .format("DD-MM-YYYY")
  //     //         .includes(searchText)) ||
  //     //     (row.kycApprovedDate &&
  //     //       moment
  //     //         .unix(row.kycApprovedDate)
  //     //         .format("DD-MM-YYYY")
  //     //         .includes(searchText)) ||
  //     //     (row.kyccount && row.kyccount.toString().includes(searchText)) ||
  //     //     (row.status && row.status.toLowerCase().includes(searchText))
  //     //   );
  //     // });
  //     // setkycJouney(filteredRows);
  //   } else {
  //     await KycDetails({
  //       variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
  //     });
  //   }
  // });

  /**
   * @summary fetches kyc details when search fields is empty
   */
  const [fetchKycDetailsForSearch] = useLazyQuery(GET_KYC_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: async (result) => {
      console.log("oncomplete");
      setkycJouney(result.getKycDetails.kycRows);
      // setTotalCount(result.getKycDetails.totalCount);
    },
  });

  /**
   * @summary opens the popup to select status
   */
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * @summary filters the popup to select status
   */
  const handleStatusChange = async (status) => {
    setFilterText("");
    if (status == "All") {
      await KycDetails({
        variables: { oemId: props.oem, limit: 20, offset: 0 },
      });
    } else {
      fetchKycDetailsForSearch({
        variables: {
          oemId: props.oem,
          limit: totalCount,
          offset: 0,
          kycSearch: status.trim(),
        },
      });
      // const mappedData =
      //   status && allData.filter((row) => row.status === status);
      // setkycJouney(mappedData);
    }
    setAnchorEl(null);
  };

  /**
   * @summary fetches the kyc details for status All
   */
  const [fetchKycDetailsforStatus] = useLazyQuery(GET_KYC_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: async (result) => {
      console.log("oncomplete");
      setkycJouney(result.getKycDetails.kycRows);
      setTotalCount(result.getKycDetails.totalCount);
    },
  });

  useEffect(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    var token = localStorage.getItem("id_token");
    var decoded = token && jwt_decode(token);
    if (!token || currentTimestamp > decoded.exp) {
      localStorage.clear();
      history.push({
        pathname: "/login",
      });
    } else {
      KycDetails({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
      });
    }
  }, [props.oem]);

  const [getDownloadUrl, { data: url }] = useMutation(EXCEL_DOWNLOAD, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
    onCompleted: async (result) => {
      const res = await axios({
        url: "",
        method: "GET",
        headers: { Accept: "application/vnd.ms-excel" },
        responseType: "blob", // important
      });

      FileSaver.saveAs(new Blob([res.data]), "sample.xlsx");
    },
  });

  /**
   * @summary api call to set kyc Details
   */
  const [
    createKyc,
    { error: createError, loading: createloading, data: createData },
  ] = useMutation(CREATE_KYC_DETAILS, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleClose();
    },
    onCompleted: async (result) => {
      console.log(result, "result");
      if (result.createKycDetails.response[0].result == 1) {
        props.setMessage("Succesfully Created the Kyc details");
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleClose();
      } else {
        if (result.createKycDetails.response[0].message) {
          props.setMessage(result.createKycDetails.response[0].message);
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleClose();
        }
      }
      await KycDetails({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
      });
    },
  });

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const [uploadURLForProofOfAddress] = useMutation(GET_UPLOAD_URL, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleClose();
    },
    onCompleted: async (response) => {
      console.log(response, "responseCommon");

      if (response && response.getUploadURL.UploadURL) {
        console.log("response----", response);
        const url = response.getUploadURL.UploadURL;
        await axios.put(url, file, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            let percentage = Math.round((100 * data.loaded) / data.total);
            props.setMessage(`Uploading....`);
            props.setSnackbar(true);
            handleClose();
          },
        });
        setProofOfAddress(response.getUploadURL.filename);
        setPOA(false);
      } else {
        props.setMessage("Something went wrong,Please Try again!");
        props.setSnackbar(true);
        handleClose();
      }
    },
  });

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const onUploadProofOfAddress = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    // setIsBulkUploadOpen(false);
    console.log("file----", file);
    console.log("validdiiitt---", validity);
    if (file) {
      //setUpload(true);
      //setProgressDialog(true);
      setuploadFile(file);
      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);
      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        await uploadURLForProofOfAddress({
          variables: {
            fileExtension,
          },
        });
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const [uploadURLForProofOfIdentity] = useMutation(GET_UPLOAD_URL, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleClose();
    },
    onCompleted: async (response) => {
      console.log(response, "responseCommon");

      if (response && response.getUploadURL.UploadURL) {
        console.log("response----", response);
        const url = response.getUploadURL.UploadURL;
        await axios.put(url, file, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            let percentage = Math.round((100 * data.loaded) / data.total);
            props.setMessage(`Uploading....`);
            props.setSnackbar(true);
            handleClose();
          },
        });
        setProofOfIdentity(response.getUploadURL.filename);
        setPOI(false);
      } else {
        props.setMessage("Something went wrong,Please Try again!");
        props.setSnackbar(true);
        handleClose();
      }
    },
  });

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const onUploadProofOfIdentity = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    // setIsBulkUploadOpen(false);
    console.log("file----", file);
    console.log("validdiiitt---", validity);
    if (file) {
      //setUpload(true);
      //setProgressDialog(true);
      setuploadFile(file);
      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);
      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        await uploadURLForProofOfIdentity({
          variables: {
            fileExtension,
          },
        });
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  const handleKycSubmit = () => {
    createKyc({
      variables: {
        oemId: props.oem,
        count: count,
        vin: vin,
        imei: imei,
        engineNumber: engineNumber,
        yearOfmg: yearOfmg,
        vehicleMake: vehicleMake,
        vehicleModel: vehicleModel,
        fuelType: fuelType,
        customerID: customerID,
        customerName: customerName,
        customerMobile1: customerMobile1,
        retailInvoiceNumber: retailInvoiceNumber,
        customerAddress: customerAddress,
        customerEmail: customerEmail,
        proofOfAddress: proofOfAddress,
        proofOfIdentity: proofOfIdentity,
        dealerCode: dealerCode,
        dealerName: dealerName,
        dealerAddress: dealerAddress,
        dealerTown: dealerTown,
        dealerContactName: dealerContactName,
        dealerContactNumber: dealerContactNumber,
      },
    });
  };

  const DownloadExcel = () => {
    getDownloadUrl({
      variables: {
        oemId: props.oem,
        downloadFor: "DEVICE_MANAGER",
      },
    });
  };

  const [previewType, setPreviewType] = useState("");

  const [getPreviewUrl, { data: previeUrl }] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
    onCompleted: async (result) => {
      console.log(result);

      setUrl(result.getKycDownloadURL.url);
      setPreviewOpen(true);
    },
  });

  const DownloadPreview = (filename, e) => {
    setPreviewType(e);
    console.log("ppp");
    setFileType(filename.split(".")[1]);
    getPreviewUrl({
      variables: {
        bucketName: "customerkyc-qa",
        filename: filename,
      },
    });
  };

  const handlePreviewClose = () => {
    setUrl(null);
    setPreviewOpen(false);
    setFileType(null);
  };
  return (
    <React.Fragment>
      <CreateKyc
        open={open}
        show={show}
        handleKycClose={handleKycClose}
        oemId={props.oem}
        imei={imei}
        vin={vin}
        engineNumber={engineNumber}
        yearOfmg={yearOfmg}
        vehicleMake={vehicleMake}
        vehicleModel={vehicleModel}
        fuelType={fuelType}
        customerID={customerID}
        customerName={customerName}
        customerMobile1={customerMobile1}
        retailInvoiceNumber={retailInvoiceNumber}
        customerAddress={customerAddress}
        customerEmail={customerEmail}
        proofOfAddress={proofOfAddress}
        POA={POA}
        setPOA={setPOA}
        proofOfIdentity={proofOfIdentity}
        POI={POI}
        setPOI={setPOI}
        dealerCode={dealerCode}
        dealerName={dealerName}
        dealerAddress={dealerAddress}
        dealerTown={dealerTown}
        dealerContactName={dealerContactName}
        dealerContactNumber={dealerContactNumber}
        setImei={setImei}
        setVin={setVin}
        setEngineNumber={setEngineNumber}
        setYearOfmg={setYearOfmg}
        setVehicleMake={setVehicleMake}
        setVehicleModel={setVehicleModel}
        setFuelType={setFuelType}
        setCustomerID={setCustomerID}
        setCustomerName={setCustomerName}
        setCustomerMobile1={setCustomerMobile1}
        setRetailInvoiceNumber={setRetailInvoiceNumber}
        setCustomerAddress={setCustomerAddress}
        setCustomerEmail={setCustomerEmail}
        setProofOfAddress={setProofOfAddress}
        setProofOfIdentity={setProofOfIdentity}
        setDealerCode={setDealerCode}
        setDealerName={setDealerName}
        setDealerAddress={setDealerAddress}
        setDealerTown={setDealerTown}
        setDealerContactName={setDealerContactName}
        setDealerContactNumber={setDealerContactNumber}
        onUploadProofOfAddress={onUploadProofOfAddress}
        onUploadProofOfIdentity={onUploadProofOfIdentity}
        handleKycSubmit={handleKycSubmit}
      />
      {previewOpen ? (
        <ImagePreview
          previewOpen={previewOpen}
          handlePreviewClose={handlePreviewClose}
          url={previewUrl}
          previewType={previewType}
          fileType={fileType}
        />
      ) : null}
      <Grid container style={{ paddingTop: "10px" }}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              {/* <Typography variant="h4">KYC Journey</Typography> */}
            </Grid>
            <Grid
              item
              xs={12}
              style={
                {
                  // paddingLeft: "12px",
                  // paddingRight: "12px",
                  // margin: "10px",
                  // margin: "10px",
                }
              }
            >
              <Grid
                container
                style={{
                  backgroundColor: "#FFFFFF",
                  paddingTop: "0px",
                }}
              >
                <Grid item xs={6} style={{ padding: "8px 0px 8px 8px" }}>
                  <TextField
                    placeholder="Search Number"
                    variant="outlined"
                    value={filterText}
                    onChange={(e) => handleSearchChange(e)}
                    style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ paddingLeft: "3px" }} />
                        </InputAdornment>
                      ),
                    }}
                    className={classes.textfield}
                  />
                  <IconButton
                    onClick={handleFilterClick}
                    disabled={totalCount == 0}
                  >
                    <FilterListIcon style={{ fill: "#57B793" }} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    {statusOptions.map((status) => (
                      <MenuItem
                        key={status}
                        onClick={() => handleStatusChange(status)}
                      >
                        {status}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
                {props.roleId === 2 ? (
                  <Grid
                    item
                    xs={6}
                    style={{
                      // paddingBottom: "30px",
                      paddingTop: "20px",
                      paddingRight: "10px",
                    }}
                  >
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        {/* <ColorButton onClick={() => handleCreateKyc()}>
                          <Typography className={classes.buttonText}>
                            Create Kyc
                          </Typography>
                        </ColorButton> */}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid item xs={12} style={{ maxHeight: '60vh' }}>
                  {loading ? (
                    <CustomCircularProgress />
                  ) : kycJouney && kycJouney.length > 0 ? (
                    <Paper>
                      <TableContainer
                        sx={{ overflow: "scroll", maxHeight: "calc(100vh - 280px)" }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  align="center"
                                  style={{
                                    minWidth: column.minWidth,
                                    font: "normal normal 600 15px/20px Nunito Sans",
                                    backgroundColor: "#faf8f7",
                                  }}
                                  className={classes.tableHead}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {kycJouney.map((row, index) => {
                              let kycApprovedDate = row.kycApprovedDate
                                ? moment
                                  .unix(row.kycApprovedDate)
                                  .format("DD-MM-YYYY HH:mm:ss")
                                : null;
                              let lastKycReceivedDate = row.lastKycReceivedDate
                                ? moment
                                  .unix(row.lastKycReceivedDate)
                                  .format("DD-MM-YYYY HH:mm:ss")
                                : null;
                              return (
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {next + (index + 1)}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.vin}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.imei}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.dealerCode}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.dealerName}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.kycMode}
                                  </TableCell>
                                  {/* <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.proofOfAddress &&
                                      row.proofOfAddress != "N/A" ? (
                                      <CustomButton
                                        variant="contained"
                                        onClick={() =>
                                          DownloadPreview(
                                            row.proofOfAddress,
                                            "Proof of Address"
                                          )
                                        }
                                      // style={{
                                      //   padding: "0px",
                                      //   fontSize: "12px",
                                      // }}
                                      >
                                        Preview
                                      </CustomButton>
                                    ) : (
                                      " NA"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.proofOfIdentity &&
                                      row.proofOfIdentity != "N/A" ? (
                                      <CustomButton
                                        variant="contained"
                                        onClick={() =>
                                          DownloadPreview(
                                            row.proofOfIdentity,
                                            "Proof Of Identity"
                                          )
                                        }
                                      >
                                        Preview
                                      </CustomButton>
                                    ) : (
                                      " NA"
                                    )}
                                  </TableCell> */}
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.vehicleMake}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {lastKycReceivedDate
                                      ? lastKycReceivedDate
                                      : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {kycApprovedDate ? kycApprovedDate : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.kyccount}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.status}
                                  </TableCell>
                                  {/* <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.ticketCreated}
                                  </TableCell> */}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[20, 50, 100]}
                        component="div"
                        count={kycJouney && totalCount ? totalCount : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <NoData />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withSnackbar(KycJourney);
