import React, { useEffect, useState, useMemo } from "react";
import { Grid } from "@mui/material";
import useStyles from "./styles";
import VinImeLogs from "./VinImeiLogs.js";
import KycLogs from "./KycLogs";
// import VinImeMap from "./VinImeMap";
import { Tabs, Tab } from "@mui/material";

export default function VinKycLogs(props) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            value={activeTab}
            className={classes.root}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#57B793" } }}
          >
            <Tab label="VIN IMEI LOGS" className={classes.tab} />
            <Tab label="KYC LOGS" className={classes.tab} />
          </Tabs>
        </Grid>
      </Grid>
      {activeTab === 0 && <VinImeLogs oem={props.oem} />}
      {activeTab === 1 && <KycLogs oem={props.oem} />}
    </React.Fragment>
  );
}
