import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { Grid, Typography, Dialog, DialogContent } from "@mui/material";
import { CustomSelect, CustomTextArea } from "../../hoc/CustomComponents";
import { ColorOutlinedButton, ColorButton } from "../../hoc/PaletteComponent";
import classStyles from "./styles";

// const allReasons = ["Invalid Aadhar Number", "Invalid Contact Number"];
export default function KycAction({
  open,
  cancelRejection,
  setReason,
  rejectKyc,
  reason,
}) {
  const classes = classStyles();
  const handleReasonSelect = (e) => {
    setReason(e);
  };
  return (
    <Dialog open={open}>
      <DialogContent style={{ padding: "20px" }}>
        <Grid container spacing={3}>
          <Grid item>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4">Enter the remarks</Typography>
              </Grid>
              <Grid item xs={12}>
                <form onSubmit={rejectKyc}>
                  <CustomTextArea
                    // label="Remarks"
                    placeholder="Remarks"
                    value={reason}
                    onChange={(e) => {
                      handleReasonSelect(e.target.value);
                    }}
                    rows="2"
                  />
                </form>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            style={{ paddingTop: "20px" }}
          >
            <Grid item>
              <ColorOutlinedButton
                style={{ padding: "6px 20px" }}
                onClick={cancelRejection}
              >
                Cancel
              </ColorOutlinedButton>
            </Grid>
            <Grid item>
              <ColorButton
                onClick={rejectKyc}
                style={{ padding: "6px 20px" }}
                disabled={reason == "" || reason == null}
              >
                Submit
              </ColorButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
