import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Table,
  Typography,
  TableHead,
  TableBody,
  Button,
} from "@mui/material";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../../hoc/PaletteComponent";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
let columns = [
  {
    name: "Log ID",
    minWidth: 100,
  },
  {
    name: "Timestamp",
    minWidth: 150,
  },
  {
    name: "Username",
    minWidth: 200,
  },
  {
    name: "Download report",
    minWidth: 200,
  },
];

const EoltLogsReport = ({
  open,
  eoltReportDetails,
  handleDownloadReport,
  closeReport,
  seteolSelRep,
  logsLoading,
}) => {
  const classes = useStyles();
  const handleSelRep = (rep) => {
    handleDownloadReport(rep);
  };
  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        <Grid container>
          <Grid item xs={9}>
            <Typography>EOLT Report</Typography>
          </Grid>
          <Grid item xs={3}>
            <Button style={{ float: "right" }} onClick={closeReport}>
              <CloseIcon
                style={{
                  fill: "#2b2924",
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer>
              {logsLoading ? (
                <CircularProgress style={{ color: "#57B793" }} />
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          style={{
                            font: "normal normal 600 15px/20px Nunito Sans",
                            letterSpacing: "0px",
                            color: "#212121",
                            borderRightWidth: 1,
                            borderColor: "#E8E8E8",
                            borderTop: "1px solid #E8E8E8",
                            backgroundColor: "#faf8f7",
                            minWidth: column.minWidth,
                            textAlign: "center",
                          }}
                        >
                          {column.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eoltReportDetails &&
                      eoltReportDetails.map((row, index) => {
                        let createdOn = row.createdOn
                          ? moment
                              .unix(row.createdOn)
                              .format("DD-MM-YYYY HH:mm:ss")
                          : "NA";
                        return (
                          <TableRow>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {createdOn}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.createdByName}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                              style={{
                                cursor: "pointer",
                                color: "#5CB593",
                                textDecoration: "underline",
                              }}
                            >
                              <CustomButton
                                variant="contained"
                                onClick={() => {
                                  handleSelRep(row.fileName);
                                }}
                              >
                                Download
                              </CustomButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EoltLogsReport;
