import { Button, Dialog, Grid, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CustomTextField } from "../../hoc/CustomComponents";
import { ColorButton, CustomButton } from "../../hoc/PaletteComponent";
export default function RemapImei({
  open,
  vin,
  imei,
  engineNo,
  handleImei,
  closeEditModel,
  handleRemap,
}) {
  return (
    <Dialog
      open={open}
      maxWidth={"md"}
      fullWidth={true}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
    >
      <Grid container>
        <Grid item xs={12} style={{ padding: "10px" }}>
          <Grid container>
            <Grid item xs={9}>
              <Typography variant="h5">Edit IMEI</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button style={{ float: "right" }} onClick={closeEditModel}>
                <CloseIcon
                  style={{
                    fill: "#2b2924",
                  }}
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CustomTextField value={vin} label="VIN" disabled={true} />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    value={imei}
                    label="IMEI"
                    onChange={handleImei}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    value={engineNo}
                    label="Engine Number"
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                display={"flex"}
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item xs={2}>
                  <ColorButton onClick={closeEditModel}>
                    <Typography>Cancel</Typography>
                  </ColorButton>
                </Grid>
                <Grid item xs={2}>
                  <ColorButton onClick={handleRemap} disabled={!imei}>
                    <Typography>Submit</Typography>
                  </ColorButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
