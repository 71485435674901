import {
  Grid,
  TableContainer,
  TableRow,
  TextField,
  TableCell,
  TableHead,
  TableBody,
  Tooltip,
  Table,
  Button,
  TablePagination,
  Typography,
  CircularProgress,
} from "@mui/material";
import useStyles from "./styles";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";
import { CProgress, CProgressBar } from "@coreui/react";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import moment from "moment";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useHistory } from "react-router-dom";
import { debounce } from "throttle-debounce";
import CancelIcon from "@mui/icons-material/Cancel";

const columns = [
  {
    label: "Sr.No",
    minWidth: 150,
    align: "center",
  },
  {
    id: "",
    label: "VIN",
    minWidth: 200,
    align: "center",
  },
  {
    id: "",
    label: "Campaign Created On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "EOTA Progress",
    minWidth: 200,
    align: "center",
  },
  {
    id: "",
    label: "IMEI",
    minWidth: 200,
    align: "center",
  },
  {
    id: "",
    label: "ECU Name",
    minWidth: 200,
    align: "center",
  },
  {
    label: "File Download Triggered On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "File Download Failed On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "File Download Completed On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Flash Triggered On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Flash Failed On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Flash Completed On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Cancelled Triggered On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Cancelled Completed On",
    minWidth: 220,
    align: "center",
  },
  {
    id: "",
    label: "Status",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Communication Status",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Location",
    minWidth: 200,
    align: "center",
  },
  {
    id: "",
    label: "Download File",
    minWidth: 200,
    align: "center",
  },
  {
    id: "",
    label: "Flash ECU",
    minWidth: 200,
    align: "center",
  },
  {
    id: "",
    label: "Action",
    minWidth: 200,
    align: "center",
  },
];
const GET_ALL_VIN_CAMPAIGN = gql`
  query(
    $oemId: Int!
    $campaignId: Int!
    $limit: Int!
    $offset: Int!
    $search: vinSearchInput
  ) {
    getAllVinByCampaign(
      oemId: $oemId
      campaignId: $campaignId
      limit: $limit
      offset: $offset
      search: $search
    ) {
      totalCount
      data {
        id
        oemId
        campaignName
        serialNumber
        ecuName
        fileName
        status
        campaignProgress
        ownerId
        createdOn
        fileDwldStart
        fileDwldFail
        fileDwldSuccess
        flashStart
        flashFail
        flashSuccess
        campaignStatus
        vinEotaStatus
        vin
        communicationStatus
        location
        enableCancel
        campaignCancelledOn
        cancelSuccessOn
      }
    }
  }
`;

const EOTA = gql`
  mutation(
    $serialNumber: String!
    $fileName: String!
    $scope: String!
    $ecuName: String!
  ) {
    eota(
      serialNumber: $serialNumber
      fileName: $fileName
      scope: $scope
      ecuName: $ecuName
    ) {
      code
      message
    }
  }
`;

const GET_ALL_VIN_COUNT = gql`
  query($campaignId: Int!, $oemId: Int!) {
    getAllVinByStatus(campaignId: $campaignId, oemId: $oemId) {
      code
      status
      data {
        totalVINs
        scheduled
        downloadInProgress
        downloadCompleted
        flashingInProgress
        flashingCompleted
        cancelled
        failed
      }
    }
  }
`;
const totalCountobject = [
  {
    key: "vin",
    name: "Total VINs",
    Value: null,
  },
  {
    key: "scheduled",
    name: "Scheduled",
    Value: null,
  },
  {
    key: "dwldInProgress",
    name: "Download in Progress",
    Value: null,
  },
  {
    key: "dwldComplete",
    name: "Download Completed",
    Value: null,
  },
  {
    key: "flashInProgress",
    name: "Flashing In Progress",
    Value: null,
  },
  {
    key: "flashComplete",
    name: "Flashing Completed",
    Value: null,
  },
  {
    key: "cancelled",
    name: "Cancelled",
    Value: null,
  },
  {
    key: "failed",
    name: "Failed",
    Value: null,
  },
];

const campaignDetail = [
  {
    name: "Campaign Type",
    value: null,
  },
  {
    name: "Group",
    value: null,
  },
  {
    name: "Name",
    value: null,
  },
  {
    name: "File Type",
    value: null,
  },
  {
    name: "Created On",
    value: null,
  },
];
let timer = null;
let Scope = null;
function VinsByCampaign(props) {
  let campaignId = parseInt(localStorage.getItem("campaignId"));
  let campaignName = props.props.location.campaignName;
  let scheduleType = props.props.location.scheduleType;
  let ecuGroup = props.props.location.ecuGroup;
  let ecuName = props.props.location.ecuName;
  let fileType = props.props.location.fileType;
  let createdOn = props.props.location.createdOn;
  console.log(createdOn, "createdOn");

  console.log(props.props.location.campaignId, "fileData");
  console.log(props, "props");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  let [next, setNext] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const [allVindata, setAllVinData] = useState([]);
  const [search, setSearchText] = useState(null);
  const [scope, setScope] = useState(null);
  const [serialNumber, setSerialNum] = useState(null);

  const [otaData, setOtaData] = useState([]);
  /**
   * @summary This handles the page change in pagination
   */
  const handleChangePage = async (event, newPage) => {
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    getAllVinCampaign({
      variables: {
        oemId: props.oem,
        campaignId: campaignId,
        limit: rowsPerPage,
        offset: newPage,
      },
    });
  };

  /**
   * @summary This handles the row change in pagination
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
    getAllVinCampaign({
      variables: {
        oemId: props.oem,
        campaignId: campaignId,
        limit: event.target.value,
        offset: 0,
      },
    });
  };
  const handleDownloadFlash = async (
    scope,
    fileName,
    serialNumber,
    ecuName
  ) => {
    Scope = scope;
    clearInterval(timer);
    setScope(scope);
    setSerialNum(serialNumber);
    await eotaCreation({
      variables: {
        serialNumber: serialNumber,
        fileName: fileName,
        scope: scope,
        ecuName: ecuName,
      },
    });

    console.log("download/flash");
  };

  /**
   * @summary This closes the snackBar
   */
  const handleClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  /**
   * @summary This is api call to create eota
   */
  const [
    eotaCreation,
    { error: eotaError, loading: eotaLoading, data: eotaData },
  ] = useMutation(EOTA, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleClose();
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleClose();
        });
      }
    },
    onCompleted: (result) => {
      console.log(result.eota?.message, "result in ota");
      props.setMessage(result.eota?.message);
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleClose();
      getAllVinCounts({
        variables: {
          oemId: props.oem,
          campaignId: campaignId,
        },
      });
      getAllVinCampaign({
        variables: {
          oemId: props.oem,
          campaignId: campaignId,
          limit: 5,
          offset: 0,
        },
      });

      timer = setInterval(getPollingData, 2000);
    },
  });

  /**
   * @summary This is api call for polling
   */
  const [getAllVinCampaignPolling, { data: otadata }] = useLazyQuery(
    GET_ALL_VIN_CAMPAIGN,
    {
      fetchPolicy: "network-only",

      onCompleted: (result) => {
        if (otaDatas.getAllVinByCampaign?.data) {
          setOtaData(otaDatas.getAllVinByCampaign.data);
          setTotalCount(otaDatas.getAllVinByCampaign.totalCount);
        } else {
          setOtaData([]);
          setTotalCount(0);
        }
      },
    }
  );

  const getPollingData = async () => {
    getAllVinCampaignPolling({
      variables: {
        oemId: props.oem,
        campaignId: campaignId,
        limit: 5,
        offset: 0,
      },
    });
    getAllVinCounts({
      variables: {
        oemId: props.oem,
        campaignId: parseInt(localStorage.getItem("campaignId")),
      },
    });
    console.log(Scope, "scope in");
    // if (Scope) {
    //   // Stop the interval
    //   console.log("here");
    //   return clearInterval(timer);
    // }
  };

  const [fetchAllData, { data: allData }] = useLazyQuery(GET_ALL_VIN_CAMPAIGN, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (allData.getAllVinByCampaign?.data) {
        setAllVinData(allData.getAllVinByCampaign?.data);
      } else {
        setAllVinData([]);
      }
    },
  });

  /**
   * @summary This is api call to fetch all data
   */
  const [
    getAllVinCampaign,
    { loading: otaLoading, data: otaDatas },
  ] = useLazyQuery(GET_ALL_VIN_CAMPAIGN, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      Scope = null;
      console.log(result, "ota result");
      if (otaDatas.getAllVinByCampaign?.data) {
        setOtaData(otaDatas.getAllVinByCampaign.data);
        setTotalCount(otaDatas.getAllVinByCampaign.totalCount);
      } else {
        setOtaData([]);
        setTotalCount(0);
      }
      fetchAllData({
        variables: {
          oemId: props.oem,
          campaignId: campaignId,
          limit: otaDatas?.getAllVinByCampaign?.totalCount
            ? otaDatas?.getAllVinByCampaign?.totalCount
            : 0,
          offset: 0,
        },
      });
    },
  });

  /**
   * @summary handles Search value
   */
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }
  
  /**
   * @summary returns the filtered array for search
   */
  const debounceSearchRender = useCallback(debounce(async (input) => {
    console.log(props)
    if (input) {
      getAllVinCampaign({
        variables: {
          oemId: props.oem,
          campaignId: campaignId,
          limit: 5,
          offset: 0,
          search: {
            vin: input,
          },
        },
      });
    } else {
      getAllVinCampaign({
        variables: {
          oemId: props.oem,
          campaignId: campaignId,
          limit: 5,
          offset: 0,
        },
      });
    }
  }, 1000), [props.oem,totalCount, rowsPerPage, page]);
 
  const handleSearchChange = (event) => {
    const input = event.target.value;
    setSearchText(input);
    debounceSearchRender(input);
  };

  // /**
  //  * @summary handles Search value
  //  */
  // const handleSearchChange = (event) => {
  //   setSearchText(event.target.value);
  //   setTimeout(debounceSearchRender(event.target.value), 2000);
  // };
  // const debounceSearchRender = debounce(1000, async (event) => {
  //   if (event) {
  //     getAllVinCampaign({
  //       variables: {
  //         oemId: props.oem,
  //         campaignId: campaignId,
  //         limit: 5,
  //         offset: 0,
  //         search: {
  //           vin: event,
  //         },
  //       },
  //     });
  //   } else {
  //     getAllVinCampaign({
  //       variables: {
  //         oemId: props.oem,
  //         campaignId: campaignId,
  //         limit: 5,
  //         offset: 0,
  //       },
  //     });
  //   }
  // });

  const [getAllVinCounts] = useLazyQuery(GET_ALL_VIN_COUNT, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getAllVinByStatus.code == 200) {
        totalCountobject.forEach((item) => {
          switch (item.key) {
            case "vin":
              item.Value = res.getAllVinByStatus.data.totalVINs;
              break;
            case "scheduled":
              item.Value = res.getAllVinByStatus.data.scheduled;
              break;
            case "dwldInProgress":
              item.Value = res.getAllVinByStatus.data.downloadInProgress;
              break;
            case "dwldComplete":
              item.Value = res.getAllVinByStatus.data.downloadCompleted;
              break;
            case "flashInProgress":
              item.Value = res.getAllVinByStatus.data.flashingInProgress;
              break;
            case "flashComplete":
              item.Value = res.getAllVinByStatus.data.flashingCompleted;
              break;
            case "cancelled":
              item.Value = res.getAllVinByStatus.data.cancelled;
              break;
            case "failed":
              item.Value = res.getAllVinByStatus.data.failed;
              break;
            default:
              break;
          }
        });
      }
    },
  });

  useEffect(() => {
    getAllVinCampaign({
      variables: {
        oemId: props.oem,
        campaignId: parseInt(localStorage.getItem("campaignId")),
        limit: 5,
        offset: 0,
      },
    });

    campaignDetail.forEach((item) => {
      switch (item.name) {
        case "Campaign Type":
          item.value = localStorage.getItem("scheduleType");
          break;
        case "Group":
          item.value = localStorage.getItem("ecuGroup");
          break;
        case "Name":
          item.value = localStorage.getItem("ecuName");
          break;
        case "File Type":
          item.value = localStorage.getItem("fileType");
          break;
        case "Created On":
          item.value = localStorage.getItem("createdOn");
          break;
        default:
          break;
      }
    });

    getAllVinCounts({
      variables: {
        oemId: props.oem,
        campaignId: parseInt(localStorage.getItem("campaignId")),
      },
    });
  }, [props.oem, campaignId]);

  useEffect(() => {
    Scope = null;
    timer = setInterval(getPollingData, 2000);
    return () => clearInterval(timer);
  }, [props.oem, page, search]);

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Grid container spacing={4} style={{}}>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Button
                  onClick={() => {
                    history.push({
                      pathname: "/app/ecu-ota",
                      activeTab: 1,
                    });
                  }}
                >
                  <KeyboardBackspaceIcon
                    style={{
                      fill: "green",
                      fontSize: 40,
                    }}
                  />
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  ECU OTA Manager/{localStorage.getItem("campaignName")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={2}>
              <Grid item xs={2}>
                <Grid container>
                  <Grid item xs={12} style={{ backgroundColor: "#ffffff" }}>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {totalCountobject.map((row) => {
                            return (
                              <TableRow style={{ height: "35px" }}>
                                <TableCell
                                  style={{
                                    borderBottom: "none",
                                    font:
                                      "normal normal 600 14px/21px Nunito Sans",
                                    padding: "0 10px",
                                    color:
                                      row.key == "scheduled" ||
                                      row.key == "dwldInProgress" ||
                                      row.key == "dwldComplete" ||
                                      row.key == "flashInProgress"
                                        ? "#EECA64"
                                        : row.key == "flashComplete"
                                        ? "#70B595"
                                        : row.key == "vin"
                                        ? "Total VINs"
                                        : "#CD6C5C",
                                  }}
                                >
                                  {row.name}
                                </TableCell>
                                <TableCell
                                  style={{
                                    borderBottom: "none",
                                    padding: "0 10px",
                                    fontSize: "12px",
                                    font:
                                      "normal normal 600 14px/21px Nunito Sans",
                                    color:
                                      row.key == "scheduled" ||
                                      row.key == "dwldInProgress" ||
                                      row.key == "dwldComplete" ||
                                      row.key == "flashInProgress"
                                        ? "#EECA64"
                                        : row.key == "flashComplete"
                                        ? "#70B595"
                                        : row.key == "vin"
                                        ? "Total VINs"
                                        : "#CD6C5C",
                                  }}
                                >
                                  {row.Value}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      style={{ backgroundColor: "#ffffff", marginTop: "20px" }}
                    >
                      <Grid item xs={12} style={{ padding: "30px 12px" }}>
                        {campaignDetail.map((campaign) => {
                          return (
                            <>
                              <Grid item xs={12}>
                                <span
                                  style={{
                                    font:
                                      "normal normal medium 12px/18px Nunito Sans",
                                  }}
                                >
                                  {campaign.name}
                                </span>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  border: "1px solid #333 !important",
                                  backgroundColor: "#EFEFEF",
                                  borderRadius: "6px",
                                  padding: "5px 11px",
                                  marginBottom: "15px",
                                }}
                              >
                                <span
                                  style={{
                                    font:
                                      "normal normal 600 14px/20px Nunito Sans",
                                  }}
                                >
                                  {campaign.value}
                                </span>
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Grid
                  containe
                  style={{ backgroundColor: "#ffffff" }}
                  spacing={4}
                >
                  <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: "30px", paddingTop: "30px" }}
                  >
                    <Grid item xs={6}>
                      <TextField
                        placeholder="Search Vin Here"
                        variant="outlined"
                        style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                        size="small"
                        className={classes.textfield}
                        onChange={(e) => handleSearchChange(e)}
                        value={search}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: "30px" }}>
                    {otaLoading ? (
                      <CustomCircularProgress size={20} />
                    ) : otaData && totalCount > 0 ? (
                      <>
                        <TableContainer
                          style={{
                            maxHeight: 440,
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <>
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                      minWidth: column.minWidth,
                                      font:
                                        "normal normal 600 15px/20px Nunito Sans",
                                      letterSpacing: "0px",
                                      color: "#212121",
                                      borderRightWidth: 1,
                                      borderColor: "#E8E8E8",
                                      borderTop: "1px solid #E8E8E8",
                                      backgroundColor: "#faf8f7",
                                    }}
                                  >
                                    {column.label}
                                  </TableCell>
                                </>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {otaData && totalCount > 0 ? (
                              otaData.map((row, index) => {
                                let createdDate = moment
                                  .unix(row.createdOn / 1000)
                                  .format("DD-MM-YYYY HH:mm:ss");
                                let fileDwldStart = row.fileDwldStart
                                  ? moment
                                      .unix(row.fileDwldStart)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                  : null;
                                let fileDwldFail = row.fileDwldFail
                                  ? moment
                                      .unix(row.fileDwldFail)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                  : null;
                                let fileDwldSuccess = row.fileDwldSuccess
                                  ? moment
                                      .unix(row.fileDwldSuccess)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                  : null;
                                let flashStart = row.flashStart
                                  ? moment
                                      .unix(row.flashStart)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                  : null;

                                let flashFail = row.flashFail
                                  ? moment
                                      .unix(row.flashFail)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                  : null;

                                let flashSuccess = row.flashSuccess
                                  ? moment
                                      .unix(row.flashSuccess)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                  : null;

                                let campaignCancelledOn = row.campaignCancelledOn
                                  ? moment
                                      .unix(row.campaignCancelledOn)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                  : null;

                                let cancelledSuccessOn = row.cancelSuccessOn
                                  ? moment
                                      .unix(row.cancelSuccessOn)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                  : null;

                                return (
                                  <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {next + (index + 1)}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {row.vin}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {createdDate}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {" "}
                                      {/* <LinearProgress variant="buffer" /> */}
                                      <CProgress className="mb-3">
                                        <CProgressBar
                                          color="success"
                                          variant="striped"
                                          animated
                                          value={
                                            row.status == "FLASH_COMPLETED"
                                              ? 100
                                              : row.campaignProgress
                                              ? row.campaignProgress
                                              : 0
                                          }
                                        >
                                          {row.status == "FLASH_COMPLETED"
                                            ? 100
                                            : row.campaignProgress
                                            ? row.campaignProgress
                                            : 0}
                                          %
                                        </CProgressBar>
                                      </CProgress>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {row.serialNumber}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {row.ecuName}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {fileDwldStart}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {fileDwldFail}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {fileDwldSuccess}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {flashStart}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {flashFail}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {flashSuccess}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {campaignCancelledOn}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {cancelledSuccessOn}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {row.status}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {row.communicationStatus
                                        ? row.communicationStatus
                                        : "OFFLINE"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {row.location ? row.location : "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      <Grid
                                        container
                                        alignContent="center"
                                        alignItems="center"
                                        style={{ height: "5px" }}
                                      >
                                        <Grid item style={{ margin: "auto" }}>
                                          {eotaLoading &&
                                          scope == "fileDownload" &&
                                          row.serialNumber == serialNumber ? (
                                            <CircularProgress
                                              size={32}
                                              style={{ color: "#57B793" }}
                                            />
                                          ) : (
                                            <Tooltip title="Download">
                                              <Button
                                                onClick={() =>
                                                  handleDownloadFlash(
                                                    "fileDownload",
                                                    row.fileName,
                                                    row.serialNumber,
                                                    row.ecuName
                                                  )
                                                }
                                                disabled={
                                                  row.status != "PENDING" ||
                                                  row.vinEotaStatus ==
                                                    "INACTIVE"
                                                }
                                              >
                                                <CloudDownloadIcon
                                                  style={
                                                    row.status != "PENDING" ||
                                                    row.vinEotaStatus ==
                                                      "INACTIVE"
                                                      ? { fill: "grey" }
                                                      : { fill: "#2b2924" }
                                                  }
                                                />
                                              </Button>
                                            </Tooltip>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      <Grid
                                        container
                                        alignContent="center"
                                        alignItems="center"
                                        style={{ height: "5px" }}
                                      >
                                        <Grid item style={{ margin: "auto" }}>
                                          {eotaLoading &&
                                          scope == "flashEcu" &&
                                          row.serialNumber == serialNumber ? (
                                            <CircularProgress
                                              size={32}
                                              style={{ color: "#57B793" }}
                                            />
                                          ) : (
                                            <Tooltip title="Flash">
                                              <Button
                                                onClick={() =>
                                                  handleDownloadFlash(
                                                    "flashEcu",
                                                    row.fileName,
                                                    row.serialNumber,
                                                    row.ecuName
                                                  )
                                                }
                                                disabled={
                                                  row.status !==
                                                    "FILE_DWLD_COMPLETED" ||
                                                  row.vinEotaStatus ==
                                                    "INACTIVE"
                                                }
                                              >
                                                <OfflineBoltIcon
                                                  style={
                                                    row.status !==
                                                      "FILE_DWLD_COMPLETED" ||
                                                    row.vinEotaStatus ==
                                                      "INACTIVE"
                                                      ? { fill: "grey" }
                                                      : {
                                                          fill: "red",
                                                        }
                                                  }
                                                />
                                              </Button>
                                            </Tooltip>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      <Grid
                                        container
                                        alignContent="center"
                                        alignItems="center"
                                        style={{ height: "5px" }}
                                      >
                                        <Grid item style={{ margin: "auto" }}>
                                          {eotaLoading &&
                                          scope == "deleteFile" &&
                                          row.serialNumber == serialNumber ? (
                                            <CircularProgress
                                              size={32}
                                              style={{ color: "#57B793" }}
                                            />
                                          ) : (
                                            <Tooltip title="Cancel">
                                              <Button
                                                onClick={() =>
                                                  handleDownloadFlash(
                                                    "deleteFile",
                                                    row.fileName,
                                                    row.serialNumber,
                                                    row.ecuName
                                                  )
                                                }
                                                disabled={
                                                  row.enableCancel == false
                                                }
                                              >
                                                <CancelIcon
                                                  style={
                                                    row.enableCancel == false
                                                      ? { fill: "grey" }
                                                      : {
                                                          fill: "red",
                                                        }
                                                  }
                                                />
                                              </Button>
                                            </Tooltip>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <>No Records found!</>
                            )}
                          </TableBody>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 100]}
                          component="div"
                          count={totalCount ? totalCount : 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </>
                    ) : (
                      <NoData />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default withSnackbar(VinsByCampaign);
