import React from "react";
import { gql, useMutation } from "@apollo/client";
import Header from "../components/Header";
import jwt_decode from "jwt-decode";
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

const LoginQuery = gql`
  mutation($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`;

const getUsersQuery = gql`
  query($id: Int) {
    getUsers(id: $id) {
      id
      username
      role_id
      status
      ownerId
      categories {
        id
        iconPath
        name
        tooltip
        features {
          id
          category_id
          iconPath
          name
          tooltip
        }
      }
    }
  }
`;

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return localStorage.getItem("id_token")
    ? context
    : { isAuthenticated: false };
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

async function loginUser(
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError,
  client
) {
  setError(false);
  setIsLoading(true);
  let Error = 0;

  const result = await client
    .mutate({
      variables: { username: login, password: password },
      mutation: LoginQuery,
    })
    .catch((err) => (Error = 1));
  //throwErrorIfApolloErrorOccurred(result)

  if (Error == 0) {
    if (!!result.data.login) {
      setTimeout(async () => {
        localStorage.setItem("id_token", result.data.login);
        setError(false);
        setIsLoading(false);

        dispatch({ type: "LOGIN_SUCCESS" });
        var decoded = jwt_decode(result.data.login);
        console.log(decoded, "decoded");
        let user = await client.query({
          query: getUsersQuery,
          fetchPolicy: "network-only",
          variables: {
            id: decoded.id,
          },
        });
        if (user && user.data.getUsers) {
          let categories = [];
          let features = [];
          user.data.getUsers.forEach((item) => {
            item.categories.forEach((elem) => {
              categories.push({
                id: elem.id,
                iconPath: elem.iconPath,
                name: elem.name,
                tooltip: elem.tooltip,
              });
              features.push(elem.features);
            });
          });
          console.log(user.data.getUsers, "res.getUsers");
          for (let i = 0; i < categories.length; i++) {
            if (categories[i].id == 1) {
              // console.log(categories[i], "categories[i]");
              // console.log(features[0], "features[0]");
              history.push({
                pathname: `/app/${features[0][0].name.toLowerCase()}`,
              });
              break;
            } else if (categories[i].id == 2) {
              history.push({
                pathname: `/app/${features[0][0].name.toLowerCase()}`,
              });
            } else if (categories[i].id == 3) {
              if (features[2]) {
                history.push({
                  pathname: `/app/${features[2][2].name.toLowerCase()}`,
                });
                break;
              } else {
                history.push({
                  pathname: `/app/${features[1][1].name.toLowerCase()}`,
                });
                break;
              }
            }
          }
        }
        console.log(user, "user");
      }, 100);
    }
  } else {
    console.log("login-data----", Error);
    setError(true);
    setIsLoading(false);
    setTimeout(async () => {
      dispatch({ type: "LOGIN_FAILURE" });
    }, 2000);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.clear();
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
