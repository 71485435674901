import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { CustomSelect, CustomTextField } from "../../hoc/CustomComponents";

const statusType = ["RAISED", "IN_PROGRESS", "RESOLVED"];
export default function UpdateTicket({
  open,
  handleClose,
  ticketNumber,
  status,
  comment,
  setStatus,
  setComment,
  updateTickets,
  previousStatus,
  previousComment,
  disableButton
}) {
  const classes = useStyles();

  /**
   * @summary This sets the value of customer name
   */
  const handleStatus = (e, status) => {
    setStatus(status);
  };

  /**
   * @summary This sets the value of customer email
   */
  const handleComment = (e) => {
    setComment(e);
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogContent>
          <form>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9}>
                    <Typography variant="h4">Update Ticket</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      style={{ float: "right" }}
                      onClick={() => handleClose()}
                    >
                      <CloseIcon
                        style={{
                          fill: "#2b2924",
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <CustomTextField
                      label="Ticket Number"
                      placeholder="Ticket Number"
                      value={ticketNumber}
                      read="true"
                      disabled="true"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomSelect
                      label="Ticket Status*"
                      placeholder="Select Status*"
                      value={status}
                      options={statusType}
                      handleSelectChange={handleStatus}
                      getOptionLabel={(option) => option}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      label="Comment"
                      placeholder="Comment"
                      onChange={(e) => handleComment(e.target.value)}
                      value={comment}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ paddingTop: "16px" }}
                >
                  <Grid
                    item
                    style={{ paddingRight: "24px", paddingBottom: "15px" }}
                  >
                    <ColorOutlinedButton
                      className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={handleClose}
                    >
                      Cancel
                    </ColorOutlinedButton>
                  </Grid>
                  <Grid item>
                    <ColorButton
                      className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={updateTickets}
                      disabled={status == null || status == "" || disableButton == true ||(status == previousStatus && comment == previousComment)}
                    >
                      Submit
                    </ColorButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
