import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SaveIcon from "@mui/icons-material/Save";
import ReplayIcon from "@mui/icons-material/Replay";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

// styles
import useStyles from "./styles";
import { CustomDialogBox, CustomTextField } from "../../hoc/CustomComponents";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";
import withSnackbar from "../../components/Snackbar/withSnackbar";

const getTCUData = gql`
  query($OEMId: Int!, $id: Int, $limit: Int!, $offset: Int!, $status: String!) {
    getTCUInventory(
      OEMId: $OEMId
      id: $id
      status: $status
      limit: $limit
      offset: $offset
    ) {
      totalCount
      rows {
        id
        oem {
          id
          oemName
          oemCode
          oemEmail
          phoneNumber
          logo
        }
        tcu {
          id
          vendorId
          vendorCode
          tcuName
          ownerId
          tcu_code
        }
        imei
        serialNumber
        owner
      }
    }
  }
`;

const updateDevice = gql`
  mutation($id: Int!, $imei: String!, $serialNumber: String!, $status: Int!) {
    addEditTCUInventory(
      id: $id
      imei: $imei
      serialNumber: $serialNumber
      status: $status
    ) {
      code
      status
      message
    }
  }
`;

const DELETE_DEVICE = gql`
  mutation($id: Int!) {
    deleteTcuInventory(id: $id) {
      code
      status
      message
    }
  }
`;

function MaxWidthDialog({
  isDialogOpened,
  handleCloseDialog,
  tcuInventoryId,
  oemTcuInventoryId,
  isDelete,
  updatePermission,
  setSnackbar,

  setMessage,
  setNetworkError,
}) {
  console.log(setSnackbar, "setSnackbar");
  console.log(setMessage, "setMessage");
  console.log("tcuInventoryId---", tcuInventoryId);

  useEffect(() => {
    console.log("ldjklsdjfks");
    getTCUInventoryEdit({
      variables: {
        OEMId: oemTcuInventoryId,
        id: tcuInventoryId,
        limit: 1,
        offset: 0,
        status: "1",
      },
    });
  }, [isDialogOpened]);
  //const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth] = useState("md");
  const [deviceCode, setdeviceCode] = useState();
  const [deviceModelCode, setdeviceModelCode] = useState();
  const [deviceModelName, setdeviceModelName] = useState();
  const [device, setDevice] = useState();
  const [imeiNumber, setImei] = useState();
  const [serialNumber, setSerialNumber] = useState();
  const [isUpdate, setUpdate] = useState(false);
  const [updateMessage, setUpdateMessage] = useState();
  const [deleteMessage, setDeleteMessage] = useState(
    "Are you sure you want to delete this Device?"
  );
  const [isdeleted, setIsDeleted] = useState(false);
  const classes = useStyles();

  //Fetch the imei details using the id passed through props
  const [getTCUInventoryEdit, { loading, error, data }] = useLazyQuery(
    getTCUData,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        if (result) {
          setdeviceCode(result.getTCUInventory.rows[0]?.tcu?.vendorCode);
          setDevice(result.getTCUInventory.rows[0]?.tcuId);
          setdeviceModelCode(result.getTCUInventory.rows[0]?.tcu.tcu_code);
          setdeviceModelName(result.getTCUInventory.rows[0]?.tcu.tcuName);
          setImei(result.getTCUInventory.rows[0]?.imei);
          setSerialNumber(result.getTCUInventory.rows[0]?.serialNumber);
        }
      },
    }
  );

  //Fetch the imei details using the id passed through props
  const [
    getUpdateDevice,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useMutation(updateDevice, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      if (result) {
        if (result.addEditTCUInventory?.code === 200) {
          setMessage("Successfully Updated!");
          setNetworkError(true);
          setSnackbar(true);
          handleClose();
        } else {
          setMessage(result.addEditTCUInventory?.message);
          setSnackbar(true);
          setNetworkError(true);
          handleClose();
        }
      }
      //   console.log("data---", result);
      //   setUpdate(true);
      //   setFullWidth(false);
      //   if (result.addEditTCUInventory !== ) {
      //   } else {
      //     setUpdateMessage("Successfully Updated!");
      //   }

      //   setDeleteMessage("Deleted Successfully");
      //   setIsDeleted(true);
      //   console.log("result---", result);
      // }
    },
  });

  //Delete device using device id
  const [
    deleteDevice,
    {
      data: deleteDeviceData,
      loading: deleteDeviceLoading,
      error: deleteDeviceError,
    },
  ] = useMutation(DELETE_DEVICE, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        setMessage(`Message: ${networkError}`);
        setNetworkError(true);
        setSnackbar(true);
        handleClose();
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          setMessage(`Message: ${message}`);
          setNetworkError(true);
          setSnackbar(true);
          handleClose();
        });
      }
    },
    onCompleted: (result) => {
      if (result.deleteTcuInventory.code === 200) {
        setMessage("Device deleted successfully!");
        setNetworkError(true);
        setSnackbar(true);
        handleClose();
      } else {
        setMessage(result.deleteTcuInventory.message);
        setSnackbar(true);
        setNetworkError(true);
        handleClose();
      }
    },
  });

  const handleClose = () => {
    //setIsOpen(false);
    setUpdate(false);
    setTimeout(() => {
      setSnackbar(false);
    }, 2000);
    handleCloseDialog(false);
  };

  const handleRowDelete = async () => {
    await deleteDevice({
      variables: { id: tcuInventoryId },
    });
  };

  const handleReset = (event) => {
    setImei(data.getTCUInventory.rows[0].imei);
    setSerialNumber(data.getTCUInventory.rows[0].serialNumber);
    console.log("imei---", imeiNumber);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleSubmit = (event) => {
    //alert("A name was submitted: ");
    console.log("imei---", imeiNumber);

    getUpdateDevice({
      variables: {
        id: tcuInventoryId,
        imei: imeiNumber,
        serialNumber: serialNumber,
        status: 1,
      },
    });
    event.preventDefault();
  };

  return (
    <React.Fragment>
      {/* {loading ? (
        <CircularProgress />
      ) : ( */}
      <>
        {!isDelete ? (
          <>
            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={isDialogOpened}
              aria-labelledby="max-width-dialog-title"
              disableEscapeKeyDown={true}
            >
              <DialogContent>
                {isUpdate ? (
                  <div style={{ marginTop: "10px", padding: "15px" }}>
                    <Alert severity="success">
                      <AlertTitle>Success</AlertTitle>
                      {updateMessage}
                    </Alert>
                    <Button
                      style={{ position: "absolute", top: "0", right: "0" }}
                      onClick={handleClose}
                    >
                      <CloseIcon
                        style={{
                          fill: "#2b2924",
                        }}
                      />
                    </Button>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container style={{ paddingBottom: "6px" }}>
                          <Grid item xs={9}>
                            <Typography variant="h4">
                              Edit Device Details
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              style={{ float: "right" }}
                              onClick={handleClose}
                            >
                              <CloseIcon
                                style={{
                                  fill: "#2b2924",
                                }}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <CustomTextField
                              label="Device Manuf Code"
                              value={deviceCode}
                              // fullWidth={true}
                              // type="text"
                              read="true"
                              disabled="true"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomTextField
                              id="serial_number"
                              name="serial_number"
                              label="Serial Number"
                              onChange={(e) => setSerialNumber(e.target.value)}
                              value={serialNumber}
                              // fullWidth={true}
                              type="text"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomTextField
                              label="Device Model Code"
                              // type="text"
                              value={deviceModelCode}
                              read="true"
                              disabled="true"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomTextField
                              id="imei"
                              name="imei"
                              label="IMEI Number"
                              onChange={(e) => setImei(e.target.value)}
                              value={imeiNumber}
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomTextField
                              label="Device Model Name"
                              value={deviceModelName}
                              // type="text"
                              read="true"
                              disabled="true"
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          justifyContent="flex-end"
                          style={{ paddingTop: "16px" }}
                        >
                          <Grid item style={{ paddingRight: "24px" }} xs={3}>
                            <ColorOutlinedButton
                              // className={classes.buttonOutlined}
                              variant="outlined"
                              onClick={handleClose}
                            >
                              Cancel
                            </ColorOutlinedButton>
                          </Grid>
                          <Grid item xs={3}>
                            <ColorButton
                              // className={classes.buttonOutlined}
                              variant="outlined"
                              onClick={handleSubmit}
                            >
                              Save
                            </ColorButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </>
        ) : (
          <>
            <CustomDialogBox
              open={isDialogOpened}
              content="Are you sure you want to delete this Device?"
              negativeResponseHandler={handleClose}
              negativeResponseButtonText="Cancel"
              positiveResponseHandle={handleRowDelete}
              positiveResponseButtonText="Delete"
              Loading={deleteDeviceLoading}
            />
          </>
        )}
      </>
      {/* )} */}
    </React.Fragment>
  );
}

export default withSnackbar(MaxWidthDialog);
