import React, { useState, useEffect, useRef , useCallback} from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from "@mui/material";

import PrintIcon from "@mui/icons-material/Print";
import useStyles from "./styles";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import axios from "axios";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import { NoData } from "./NoData";
// import DeleteIcon from "@material-ui/icons/Delete";
import AddEditCampaign from "./AddEditCampaign";
import "@coreui/coreui/dist/css/coreui.min.css";
import { CProgress, CProgressBar } from "@coreui/react";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "throttle-debounce";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import moment from "moment";
import { ColorButton } from "../../hoc/PaletteComponent";
import { fetchSearchResultsNumber1 } from "../../utils";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";

const getFeatureAccess = gql`
  query($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const getTCU = gql`
  query(
    $id: Int
    $oemId: Int!
    $limit: Int!
    $offset: Int!
    $search: campaignSearchInput
  ) {
    getTCUCampaign(
      id: $id
      oemId: $oemId
      limit: $limit
      offset: $offset
      search: $search
    ) {
      id
      oem {
        id
        oemName
        oemCode
        oemEmail
        logo
        __typename
      }
      campaignName
      campaignCategory
      campaignCreatedOn
      campaignType
      fileUrl
      tcuGroup {
        id
        tcuId {
          id
          vendorId
          tcuName
          tcu_code
          __typename
        }
        groupName
        ownerId
        __typename
      }
      campaignCommand {
        id
        imei
        command
        __typename
      }
      status
      campaignProgress
      campaignCondition {
        id
        igntionStatus
        daysOfWeek
        fromTime
        toTime
        terminateOnBreach
        __typename
      }
      __typename
    }
  }
`;

const GET_OTA_CAMPAIGN = gql`
  query($oemId: Int!, $limit: Int!, $offset: Int!) {
    getOtaCampaign(oemId: $oemId, limit: $limit, offset: $offset) {
      totalCount
      data {
        id
        oemId
        campaignName
        serialNumber
        ecuName
        fileName
        status
        campaignProgress
        ownerId
        createdOn
        fileDwldStart
        fileDwldFail
        fileDwldSuccess
        flashStart
        flashFail
        flashSuccess
        campaignStatus
      }
    }
  }
`;

const GET_TCU_GROUP = gql`
  query {
    getTcuGroup(oemId: 1) {
      id
      oemId {
        oemName
        oemCode
        oemEmail
        phoneNumber
        logo
      }
      tcuId {
        vendorId
      }
      groupName
      ownerId
    }
  }
`;

const GET_UPLOAD_URL = gql`
  mutation($fileExtension: String!) {
    getPublicUploadURL(fileExtension: $fileExtension) {
      bucketName
      filename
      publicUploadURL
    }
  }
`;

const GET_FILE_FORMAT = gql`
  query getPublicDownloadURL($bucketname: String!, $filename: String!) {
    getPublicDownloadURL(bucketName: $bucketname, filename: $filename)
  }
`;

const Add_Edit_campaign = gql`
  mutation(
    $id: Int
    $oemId: Int!
    $campaignName: String!
    $campaignCategory: String!
    $campaignType: String!
    $tcuGroupId: Int!
    $command: [String!]!
    $fileUrl: String
    $status: String!
    $TCUCampaignConditionData: TCUCampaignConditionInput!
  ) {
    addEditTCUCampaign(
      id: $id
      oemId: $oemId
      campaignName: $campaignName
      campaignCategory: $campaignCategory
      campaignType: $campaignType
      tcuGroupId: $tcuGroupId
      command: $command
      fileUrl: $fileUrl
      status: $status
      TCUCampaignConditionData: $TCUCampaignConditionData
    )
  }
`;

const RESEND_OTP = gql`
  mutation($campaignId: Int!) {
    resendTCUCampaignOtp(campaignId: $campaignId)
  }
`;

const VALIDATE_OTP = gql`
  query($otp: Int!, $campaignId: Int!) {
    validateTCUCampaignOtp(
      otp: $otp

      campaignId: $campaignId
    )
  }
`;

const EOTA = gql`
  mutation(
    $serialNumber: String!
    $fileName: String!
    $scope: String!
    $ecuName: String!
  ) {
    eota(
      serialNumber: $serialNumber
      fileName: $fileName
      scope: $scope
      ecuName: $ecuName
    ) {
      code
      message
    }
  }
`;

const CREATE_OTA_CAMPAIGN = gql`
  mutation(
    $id: Int
    $oemId: Int!
    $campaignName: String!
    $serialNumber: String!
    $fileName: String!
    $ecuName: String!
  ) {
    createOtaCampaign(
      id: $id
      oemId: $oemId
      campaignName: $campaignName
      serialNumber: $serialNumber
      fileName: $fileName
      ecuName: $ecuName
    ) {
      code
      message
    }
  }
`;

const GET_ALL_IMEI = gql`
  query($oemId: Int!) {
    getAllImei(oemId: $oemId) {
      code
      message
      data {
        imei
        serialNumber
      }
    }
  }
`;

const GET_ALL_ECU = gql`
  query($oemId: Int!) {
    getOEMVendorECUs(oemId: $oemId) {
      id
      ecuName
      ecuDomain
      bankType
      ownerId
    }
  }
`;

const columns = [
  {
    label: "Serial Number",
    minWidth: 150,
    align: "center",
  },
  {
    id: "",
    label: "Campaign Name",
    minWidth: 200,
    align: "center",
  },
  {
    id: "",
    label: "Campaign Created On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "EOTA Progress",
    minWidth: 200,
    align: "center",
  },
  {
    id: "",
    label: "IMEI",
    minWidth: 200,
    align: "center",
  },
  {
    id: "",
    label: "ECU Name",
    minWidth: 200,
    align: "center",
  },
  {
    label: "File Download Triggered On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "File Download Failed On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "File Download Completed On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Flash Triggered On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Flash Failed On",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Flash Completed On",
    minWidth: 200,
    align: "center",
  },
  {
    id: "",
    label: "Status",
    minWidth: 200,
    align: "center",
  },
  {
    id: "",
    label: "Download File",
    minWidth: 200,
    align: "center",
  },
  {
    id: "",
    label: "Flash ECU",
    minWidth: 200,
    align: "center",
  },
];

const row = ["1D001", "1/12/22", "GRP123", "Inprogress"];

function ManagerTable(props) {
  const classes = useStyles();
  console.log(props, "props");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const [oemId, setOemId] = React.useState();

  // setOemId(props.oem);
  const [campaignName, setcampaignName] = useState("");
  const [ecuName, setEcuName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [campaignType, setcampaignType] = useState(" ");
  const [single, setSingle] = useState(false);
  const [group, setGroup] = useState();
  const [speed, setSpeed] = useState(false);
  const [ignition, setIgnition] = useState(false);
  const [ignitionOn, setIgnitionOn] = useState("Off");
  const [timeOfDay, setTimeOfDay] = useState(false);
  const [dayOfWeek, setDayOfWeek] = useState(false);

  const [maxSpeed, setMaxSpeed] = useState(" ");
  const [minSpeed, setMinSpeed] = useState(" ");
  const [mon, setMon] = useState(false);
  const [tue, setTue] = useState(false);
  const [wed, setWed] = useState(false);
  const [thu, setThu] = useState(false);
  const [fri, setFri] = useState(false);
  const [sat, setSat] = useState(false);
  const [sun, setSun] = useState(false);
  const [time, setTime] = useState();
  const [toTime, setToTime] = useState(" ");
  const [fromTime, setFromTime] = useState(" ");
  const [message, setMessage] = useState();
  const [fileName, setFileName] = useState();
  const [bucketName, setBucketName] = useState();
  const [publicUploadURL, setPublicUploadURL] = useState();
  const [enableButton, setEnableButton] = useState(false);
  const [fileURL, setFileURL] = useState(" ");

  const [readPermission, setReadPermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [openSubmitDialogue, setOpenSubmitDialogue] = useState(false);
  const [otpDialogue, setOTPDialogue] = useState(false);
  const [groupId, setGroupId] = useState(" ");
  const [deviceGroup, setDeviceGroup] = useState([]);
  const [code, setCode] = useState("commands here");
  const [otp, setOtp] = useState();
  const [campaignId, setcampaignId] = useState();
  const [breach, setBreach] = useState(false);
  const [terminateOnBreach, setTerminateOnBreach] = useState(0);
  let [openDialogue, setOpenDialogue] = useState(false);
  const [msg, setMsg] = useState("Resend");
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState();
  let [next, setNext] = useState(0);

  const [snackOpen, setSnackOpen] = useState(false);
  const [confirmSnackBar, setConfirmSnackBar] = useState(false);
  const [downloadCompleted, setDownloadComplete] = useState(false);
  const [upload, setUpload] = useState(false);
  const [otaData, setOtaData] = useState([]);
  const [allImei, setAllImei] = useState([]);
  const [allEcu, setAllEcu] = useState([]);

  const [tcuData, setTcuData] = useState(null);

  let [currentStep, setCurrentStep] = useState(0);

  let id;

  if (typeof props.featureId != "undefined") {
    localStorage.setItem("featureId", props.featureId);
    id = props.featureId;
  } else {
    id = parseInt(localStorage.getItem("featureId"));
  }
  id = 5;
  // const debounceSearchrender = debounce(1000, (e) => {
  //   console.log("debounce");
  //   let searchValue = e.target.value;
  //   if (searchValue != "") {
  //     let result = fetchSearchResultsNumber1(
  //       searchValue,
  //       otaData.getOtaCampaign.rows
  //     );
  //     console.log(result, "searchResult");
  //     // setTcuData(result);
  //   }
  //   // getTCUCampaign({
  //   //   variables: {
  //   //     oemId: props.oem,
  //   //     offset: 1,
  //   //     limit: 5,
  //   //     search: {
  //   //       campaignName: e.target.value,
  //   //     },
  //   //   },
  //   // });
  // });

  // const handleSearch = (e) => {
  //   e.persist();
  //   setTimeout(debounceSearchrender(e), 2000);
  // };

  /**
   * @summary handles Search value
   */
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }
  
  /**
   * @summary returns the filtered array for search
   */
  const debounceSearchRender = useCallback(debounce(async (input) => {
    console.log(props)
    if (input) {
      let result = fetchSearchResultsNumber1(
        input,
        otaData.getOtaCampaign.rows
      );
    }
  }, 1000), [props.oem, rowsPerPage, page]);
 
  const handleSearch = (event) => {
    const input = event.target.value;
    // setSearchVal(input);
    debounceSearchRender(input);
  };

  const handleAddEdit = () => {
    console.log(openDialogue);
    setOpenDialogue(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
    getOTACampaign({
      variables: { oemId: props.oem, limit: event.target.value, offset: 0 },
    });
  };

  const handleCopy = () => {
    console.log("copied");
  };

  const handleChangePage = async (event, newPage) => {
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    getOTACampaign({
      variables: { oemId: props.oem, limit: rowsPerPage, offset: newPage },
    });
  };

  const handlecampaignName = (e) => {
    setcampaignName(e);
    console.log(campaignName);
  };

  const handleEcuName = (e, ecu) => {
    setEcuName(ecu);
    console.log(ecu);
  };
  const handleSerialNumber = (e, imei) => {
    console.log(imei, "imei");
    setSerialNumber(imei);
  };

  const handlecampaignType = (e) => {
    setcampaignType(e);
  };

  const handleGroup = (e) => {
    setGroupId(e);
  };

  const handleSingleCategory = (e) => {
    single ? setSingle(false) : setSingle(true);
  };

  const handleGroupCategory = () => {
    group ? setGroup(false) : setGroup(true);
  };

  const handleSpeed = () => {
    speed ? setSpeed(false) : setSpeed(true);
    !speed && setMaxSpeed(" ") && setMinSpeed(" ");
  };

  const handleMinSpeed = (e) => {
    setMinSpeed(e);
  };
  const handleMaxSpeed = (e) => {
    setMaxSpeed(e);
  };
  const handleIgnition = () => {
    // setIgnitionOn("On");
    // ignition ? setIgnition(false) : setIgnition(true);

    ignition ? setIgnitionOn("Off") : setIgnitionOn("On");
    ignition ? setIgnition(false) : setIgnition(true);
  };

  const handleTime = () => {
    timeOfDay ? setTimeOfDay(false) : setTimeOfDay(true);
  };

  const handleToTime = (time) => {
    setToTime(time);
  };

  const handleFromTime = (time) => {
    console.log(time, "time");
    setFromTime(time);
  };
  const handleDay = () => {
    dayOfWeek ? setDayOfWeek(false) : setDayOfWeek(true);
  };
  const handleMon = () => {
    mon ? setMon(false) : setMon(true);
  };
  const handleTue = () => {
    tue ? setTue(false) : setTue(true);
  };
  const handleWed = () => {
    wed ? setWed(false) : setWed(true);
  };
  const handleThu = () => {
    thu ? setThu(false) : setThu(true);
  };
  const handleFri = () => {
    fri ? setFri(false) : setFri(true);
  };
  const handleSat = () => {
    sat ? setSat(false) : setSat(true);
  };
  const handleSun = () => {
    sun ? setSun(false) : setSun(true);
  };

  const handleTerminate = () => {
    breach ? setTerminateOnBreach(0) : setTerminateOnBreach(1);
    breach ? setBreach(false) : setBreach(true);
  };

  // const handleUpload = async ({
  //   target: {
  //     validity,
  //     files: [file],
  //   },
  // }) => {
  //   console.log("upload here");
  //   if (validity.valid) {
  //     if (file) {
  //       const fileExtension = file.name.substring(
  //         file.name.lastIndexOf(".") + 1
  //       );
  //       const response = await client2.mutate({
  //         mutation: GET_UPLOAD_URL,
  //         variables: {
  //           fileExtension,
  //         },
  //       });
  //       if (response.data && response.data.getPublicUploadURL) {
  //         console.log("response----", response.data);
  //         const url = response.data.getPublicUploadURL.publicUploadURL;

  //         await axios.put(url, file);
  //         setFileName(response.data.getPublicUploadURL.filename);
  //         setBucketName(response.data.getPublicUploadURL.bucketName);
  //         setPublicUploadURL(response.data.getPublicUploadURL.publicUploadURL);
  //         // handleUploadUrl();
  //         setEnableButton(true);
  //       }
  //     }
  //   }
  // };

  // const handleUploadUrl = async () => {
  //   console.log(bucketName, fileName);
  //   console.log("handleUrl");
  //   try {
  //     const response = await client2.query({
  //       query: GET_FILE_FORMAT,
  //       variables: {
  //         bucketname: bucketName,
  //         filename: fileName,
  //       },
  //     });
  //     console.log(response.data.getPublicDownloadURL, "response");
  //     setFileURL(response.data.getPublicDownloadURL);
  //   } catch (error) {
  //     console.log(error, "error");
  //   }
  // };
  // const handleCommand = (e) => {
  //   console.log(e, "e");
  //   setCommands(e);
  // };

  const handleNext = () => {
    setCurrentStep(++currentStep);
    console.log(currentStep);
    console.log(campaignName, campaignType, groupId, ignitionOn, ignition);

    console.log(maxSpeed, minSpeed, terminateOnBreach);
  };

  const handleCancel = () => {
    setCurrentStep(0);
    setOpenDialogue(false);
    setSpeed(false);
    setIgnition(false);
    setTimeOfDay(false);
    setDayOfWeek(false);
    setcampaignName("");
    setEcuName("");
    setSerialNumber("");
    setcampaignType("");
    setGroupId(" ");
    setFileName(" ");
    setBucketName(" ");
    setPublicUploadURL(" ");
    setFileURL(" ");
    setEnableButton(false);
    setCode("commands here");
    setOpenDialogue(false);
    setUpload(false);
  };

  const handleSubmit = (e) => {
    // console.log(e, "list in parent component");
    // console.log(campaignName, "campaign name");
    // console.log(campaignType, "campaign type");
    // console.log(groupId, "tcuGRoupID");
    // console.log(fileURL, "fileURL");
    // console.log(ignitionOn, "ignitionStatus");
    // console.log(terminateOnBreach, "brach");
    // if (fromTime !== " " && toTime !== " ") {
    //   console.log("inside if", fromTime, toTime);
    //   var fromEpoch = fromTime.getTime() / 1000.0;
    //   console.log(fromEpoch, "Fromepoch");
    //   var toEpoch = toTime.getTime() / 1000.0;
    //   console.log(toEpoch, "toepoch");
    // }
    // // if (id) {
    // //   addEditTCUCampaignData({ variables: {} });
    // // } else {
    // //   addEditTCUCampaignData({ variables: {} });
    // // }
    // addEditTCUCampaignData({
    //   variables: {
    //     oemId: 1,
    //     campaignName: campaignName,
    //     campaignCategory: "TCU",
    //     campaignType: campaignType,
    //     tcuGroupId: groupId,
    //     command: e,
    //     fileUrl: fileURL,
    //     status: "PENDING",
    //     TCUCampaignConditionData: {
    //       igntionStatus: ignitionOn,
    //       fromTime: parseInt(fromEpoch ? fromEpoch : fromTime),
    //       toTime: parseInt(toEpoch ? toEpoch : toTime),
    //       terminateOnBreach: terminateOnBreach,
    //     },
    //   },
    // });
  };

  const uploadFile = async (file) => {
    var FormData = require("form-data");
    var data = new FormData();

    data.append("filedata", file, file.name);
    data.append("bucketName", "zel-fota");
    data.append("fileName", file.name);

    var config = {
      method: "post",
      url: process.env.REACT_APP_S3_BUCKET,
      data: data,
    };

    let response = await axios(config)
      .then(function (response) {
        console.log(response.data);
        props.setMessage("Uploaded Successfully!");
        props.setNetworkError(true);
        return response.data;
      })
      .catch(function (error) {
        return error;
      });

    return response;
  };

  const handleClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 2000);
  };

  const handleCloseSnackbar = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const onUpload = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    console.log("file----", file);
    console.log("validdiiitt---", validity);
    if (file) {
      if (validity.valid) {
        let fileExtension = file.name.substring(file.name.indexOf(".") + 1);

        if (!fileExtension.startsWith("tar")) {
          props.setMessage("Only tar files");
          props.setSnackbar(true);
          props.setNetworkError(true);
          handleCloseSnackbar();
        } else {
          props.setUpload(false);
          props.setMessage("Uploading....");
          props.setSnackbar(true);
          let bulkUpload = await uploadFile(file);

          if (bulkUpload.Code == 200) {
            props.setMessage(bulkUpload.Message);
            setUpload(true);
            setFileName(file.name);
            props.setUpload(false);
            handleCloseSnackbar();
          } else {
            props.setMessage("Failed to upload");
            props.setNetworkError(true);

            handleCloseSnackbar();
          }
        }

        // if()
        // const fileExtension = file.name.substring(
        //   file.name.lastIndexOf(".") + 1
        // );
        // console.log(fileExtension, "fileExtension");
        // console.log(file.name, "file");
        // setFileName(file.name);
        // const reader = new FileReader();
        // reader.onload = () => {
        //   console.log(reader.result, "reader");
        // };
        // reader.readAsDataURL(file);
        // await axios.put(url, file, {
        //   onUploadProgress: (data) => {
        //     //Set the progress value to show the progress bar
        //     let percentage = Math.round((100 * data.loaded) / data.total);
        //     props.setMessage(`Uploading ${percentage} %`);
        //     props.setSnackbar(true);
        //   },
        // });
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  const handleDone = () => {
    setCurrentStep(0);
    setOpenSubmitDialogue(false);
    setOpenDialogue(false);
    setSpeed(false);
    setIgnition(false);
    setTimeOfDay(false);
    setDayOfWeek(false);
    setcampaignName(" ");
    setEcuName("");
    setSerialNumber("");
    setcampaignType(" ");
    setGroupId(" ");
    setFileName(" ");
    setBucketName(" ");
    setPublicUploadURL(" ");
    setFileURL(" ");
  };

  const handleSnack = () => {
    setSnackOpen(false);
  };

  const handleConfirmSnack = () => {
    setConfirmSnackBar(false);
  };

  const handleOTP = (e) => {
    console.log(e, "otp");
    setOtp(e);
  };

  // const handleResendOTP = () => {
  //   // setMsg("Resent");
  //   // console.log("resend otp");
  //   console.log(campaignId);
  //   getResendOTP({
  //     variables: {
  //       campaignId: parseInt(campaignId, 10),
  //     },
  //   });
  // };

  // const handleOTPConfirm = () => {
  //   // console.log("conffirm");
  //   // console.log(otp, campaignId, "idhh");
  //   getValidateOTP({
  //     variables: {
  //       otp: parseInt(otp, 10),
  //       campaignId: parseInt(campaignId, 10),
  //     },
  //   });
  // };
  // const handleOTPDialogue = (e) => {
  //   setcampaignId(e);
  //   setOTPDialogue(true);
  // };

  // const handleOTPCancel = () => {
  //   setOTPDialogue(false);
  //   setOtp(" ");
  //   setMsg("Resend");
  // };

  // const handleEdit = (rowID, oemID) => {
  //   // console.log(rowID, oemID, "edit variables");
  //   setOpenDialogue(true);
  //   setEdit(true);
  //   setEditId(rowID);
  //   getEditTCUCampaign({
  //     variables: { id: rowID, oemId: props.oem, limit: 5, offset: 1 },
  //   });
  // };

  const addDownloadCampaign = async () => {
    console.log(
      campaignName,
      serialNumber,
      ecuName,
      fileName,
      ecuName,
      "addCampaign"
    );
    await createOtaCampaign({
      variables: {
        oemId: props.oem,
        campaignName: campaignName,
        serialNumber: serialNumber,
        fileName: fileName,
        ecuName: ecuName,
      },
    });
    // console.log("eota fileDownload");
  };

  // const [
  //   getEditTCUCampaign,
  //   { editLOading, editError, editData },
  // ] = useLazyQuery(getTCU, {
  //   fetchPolicy: "network-only",
  //   onCompleted: (result) => {
  //     console.log(result, "result");
  //     // console.log(tcuData.getTCUCampaign, "data");

  //     setGroupId(4);
  //     setcampaignType("FOTA");
  //     setIgnitionOn(true);
  //   },
  // });
  const [getAccess, { loading, error, data }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });

  // const [
  //   addEditTCUCampaignData,
  //   { loading: tcuAddLoading, error: tcuAddError, data: tcuAddData },
  // ] = useMutation(Add_Edit_campaign, {
  //   fetchPolicy: "network-only",

  //   onCompleted: (result) => {
  //     console.log("result---", result);
  //     if (result) {
  //       setOpenDialogue(false);
  //       setOpenSubmitDialogue(true);
  //     }
  //   },
  // });

  // const [
  //   getTCUCampaign,
  //   { loading: tcuLoading, error: tcuError, data: tcuDatas },
  // ] = useLazyQuery(getTCU, {
  //   fetchPolicy: "network-only",
  //   onCompleted: (result) => {
  //     console.log(tcuData, "result------");
  //   },
  // });

  const [
    getTcuGroup,
    { loading: tcuGroupLoading, error: tcuGroupError, data: tcuGroupData },
  ] = useLazyQuery(GET_TCU_GROUP, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log(tcuGroupData.getTcuGroup, "group");
      let list = tcuGroupData.getTcuGroup;
      console.log(list, "list");
      setDeviceGroup(list);
    },
  });

  // const [
  //   getResendOTP,
  //   { loading: otpLoading, error: otpError, data: resendOTP },
  // ] = useMutation(RESEND_OTP, {
  //   fetchPolicy: "network-only",
  //   onCompleted: (result) => {
  //     console.log(result);
  //   },
  // });

  // const [
  //   getValidateOTP,
  //   {
  //     loading: ValidateOtpLoading,
  //     error: ValidateOtpError,
  //     data: ValiadteResendOTP,
  //   },
  // ] = useLazyQuery(VALIDATE_OTP, {
  //   fetchPolicy: "network-only",
  //   onCompleted: (result) => {
  //     console.log(result.validateTCUCampaignOtp, "otp result");
  //     if (result.validateTCUCampaignOtp === 0) {
  //       console.log("inside if");
  //       setSnackOpen(true);
  //     } else {
  //       setConfirmSnackBar(true);
  //       setOTPDialogue(false);
  //       setOtp(" ");
  //     }
  //   },
  // });

  const getData = async () => {
    getOTACampaign({
      variables: { oemId: props.oem, limit: 5, offset: page },
    });
  };

  const getPollingData = async () => {
    getOTACampaign1({
      variables: { oemId: props.oem, limit: 5, offset: page },
    });
  };

  //To disable the loading of data in polling
  const [
    getOTACampaign1,
    {
      error: otaPollingError,
      loading: otaPollingLoading,
      data: otaPollingDatas,
    },
  ] = useLazyQuery(GET_OTA_CAMPAIGN, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        // props.setSnackbar(true);
        handleClose();
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          // props.setSnackbar(true);
          handleClose();
        });
      }
    },
    onCompleted: (result) => {
      console.log(result, "ota result");
      setOtaData(otaPollingDatas);
    },
  });

  const [
    createOtaCampaign,
    { loading: campaingloading, error: campignLoading, data: campainData },
  ] = useMutation(CREATE_OTA_CAMPAIGN, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setSnackbar(true);
        handleClose();
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setSnackbar(true);
          handleClose();
        });
      }
    },
    onCompleted: (result) => {
      console.log(result, "result create");
      props.setMessage(result.createOtaCampaign.message);
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleClose();
      handleCancel();
      getData();
    },
  });

  const [
    getOTACampaign,
    { error: otaError, loading: otaLoading, data: otaDatas },
  ] = useLazyQuery(GET_OTA_CAMPAIGN, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        // props.setSnackbar(true);
        handleClose();
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          // props.setSnackbar(true);
          handleClose();
        });
      }
    },
    onCompleted: (result) => {
      console.log(result, "ota result");
      setOtaData(otaDatas);
    },
  });

  const [getAllImeis, { data: imei }] = useLazyQuery(GET_ALL_IMEI, {
    onCompleted: (res) => {
      console.log(res, "res");
      if (res.getAllImei.code == 200) {
        let allImies = res.getAllImei.data.map((imei) => imei.imei);
        console.log(allImies);
        setAllImei(allImies);
      } else {
        setAllImei([]);
      }
    },
  });

  const [getAllEcus, { data: ecus }] = useLazyQuery(GET_ALL_ECU, {
    onCompleted: (res) => {
      console.log(res);
      if (res.getOEMVendorECUs && res.getOEMVendorECUs.length > 0) {
        let allEcu = res.getOEMVendorECUs.map((ecu) => ecu.ecuName);
        console.log(allEcu);
        setAllEcu(allEcu);
      } else {
        setAllEcu([]);
      }
    },
  });
  useEffect(() => {
    if (props.oem) {
      // getTCUCampaign({
      //   variables: { oemId: props.oem, limit: 5, offset: page },
      // });
      // const timer = setInterval(getData, 1000);
      // return () => clearInterval(timer);
      getOTACampaign({
        variables: { oemId: props.oem, limit: 5, offset: page },
      });
    }
    getAllImeis({
      variables: {
        oemId: props.oem,
      },
    });
    getAllEcus({
      variables: {
        oemId: props.oem,
      },
    });
    getAccess({
      variables: {
        featureId: id,
      },
    });
  }, [props.oem]);

  // useEffect(() => {
  //   // console.log(props.oem, "oem in efect");
  //   // getPolling();
  //   // getOTACampaign({
  //   //   variables: { oemId: props.oem, limit: 5, offset: page },
  //   // });
  //   // getTcuGroup({ variables: { oemId: 1 } });
  //   // getTCUCampaign({
  //   //   variables: { oemId: props.oem, limit: 5, offset: page },
  //   // });
  // }, []);

  useEffect(() => {
    const timer = setInterval(getPollingData, 2000);
    return () => clearInterval(timer);
  }, [props.oem, page]);

  const handleDownloadFlash = async (
    scope,
    fileName,
    serialNumber,
    ecuName
  ) => {
    await eotaCreation({
      variables: {
        serialNumber: serialNumber,
        fileName: fileName,
        scope: scope,
        ecuName: ecuName,
      },
    });
    console.log("download/flash");
  };

  const [
    eotaCreation,
    { error: eotaError, loading: eotaLoading, data: eotaData },
  ] = useMutation(EOTA, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleClose();
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleClose();
        });
      }
    },
    onCompleted: (result) => {
      console.log(result.eota.message, "result in ota");
      props.setMessage(result.eota.message);
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleClose();
      getPollingData();
    },
  });
  return (
    <div class-name="landing">
      <Dialog open={openSubmitDialogue}>
        <DialogContent>
          <Typography
            style={{
              font: "normal normal 600 26px/34px Nunito Sans",
              letterSpacing: "0px",
              padding: "30px 50px 30px 50px",
            }}
          >
            campaign has successfully created!
          </Typography>
          <DialogActions>
            <Button
              className={classes.button}
              component="span"
              onClick={handleDone}
            >
              Done
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/* <OTP
        otpDialogue={otpDialogue}
        handleOTPCancel={handleOTPCancel}
        handleOTP={handleOTP}
        otp={otp}
        handleOTPConfirm={handleOTPConfirm}
        handleResendOTP={handleResendOTP}
        msg={msg}
      /> */}
      <AddEditCampaign
        openDialogue={openDialogue}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        handleFlashCampaign={addDownloadCampaign}
        upload={onUpload}
        handleNext={handleNext}
        currentStep={currentStep}
        campaignType={campaignType}
        campaignName={campaignName}
        ecuName={ecuName}
        serialNumber={serialNumber}
        allEcu={allEcu}
        allImei={allImei}
        groupId={groupId}
        ignitionOn={ignitionOn}
        handlecampaignName={handlecampaignName}
        handleEcuName={handleEcuName}
        handleSerialNumber={handleSerialNumber}
        handlecampaignType={handlecampaignType}
        handleSingleCategory={handleSingleCategory}
        handleGroupCategory={handleGroupCategory}
        handleTerminate={handleTerminate}
        handleSpeed={handleSpeed}
        handleMinSpeed={handleMinSpeed}
        handleMaxSpeed={handleMaxSpeed}
        handleIgnition={handleIgnition}
        handleToTime={handleToTime}
        handleFromTime={handleFromTime}
        handleTime={handleTime}
        handleDay={handleDay}
        handleMon={handleMon}
        handleTue={handleTue}
        handleWed={handleWed}
        handleThu={handleThu}
        handleFri={handleFri}
        handleSat={handleSat}
        handleSun={handleSun}
        speed={speed}
        ignition={ignition}
        timeOfDay={timeOfDay}
        dayOfWeek={dayOfWeek}
        toTime={toTime}
        fromTime={fromTime}
        deviceGroup={deviceGroup}
        handleGroup={handleGroup}
        // handleUpload={handleUpload}
        enableButton={enableButton}
        // handleUploadUrl={handleUploadUrl}
        handleCopy={handleCopy}
        fileURL={fileURL}
        disabled={
          campaignName === " " || campaignType === " " || groupId === " "
        }
        disabledForm2={
          ((speed === false && maxSpeed === " ") || minSpeed === " ") &&
          ignition === false &&
          ((timeOfDay === false && fromTime === " ") || toTime === " ") &&
          dayOfWeek === false &&
          breach === false
        }
        disabledForm3={fileURL === " "}
        edit={edit}
        editId={editId}
        snackbarOpen={props.open}
        uploadResponse={upload}
      ></AddEditCampaign>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ backgroundColor: "#F3F3F3 " }}>
          <Typography
            style={{ marginTop: "10px", marginBottom: "25px" }}
            variant="h4"
          >
            OTA campaign Manager
          </Typography>
        </Grid>
        {/* <Grid container spacing={2} > */}
        <Grid
          container
          style={{ backgroundColor: "#FFFFFF", marginLeft: "10px" }}
        >
          <Grid item xs={6} style={{ padding: "30px" }}>
            <TextField
              id="search_imei"
              placeholder="Search campiagn name"
              variant="outlined"
              style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
              size="small"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ paddingLeft: "3px" }} />
                  </InputAdornment>
                ),
              }}
              className={classes.textfield}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ paddingTop: "30px", paddingRight: "20px" }}
          >
            <Grid container justifyContent="flex-end">
              <Grid item xs={3}>
                {createPermission ? (
                  <ColorButton onClick={handleAddEdit}>
                    <Typography> Create Campaign</Typography>
                  </ColorButton>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          {/* </Grid> */}
          <Grid item xs={12}>
            {otaLoading ? (
              <CustomCircularProgress size={20} />
            ) : (
              <React.Fragment>
                {otaData && otaData.getOtaCampaign?.totalCount > 0 ? (
                  <React.Fragment>
                    <TableContainer
                      style={{
                        maxHeight: 440,
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <>
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    font:
                                      "normal normal 600 15px/20px Nunito Sans",
                                    letterSpacing: "0px",
                                    color: "#212121",
                                    borderRightWidth: 1,
                                    borderColor: "#E8E8E8",
                                    borderTop: "1px solid #E8E8E8",
                                    backgroundColor: "#faf8f7",
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              </>
                            ))}
                            {/* {!updatePermission || !deletePermission ? (
                    <TableCell
                      align="center"
                      style={{
                        font: "normal normal 600 15px/20px Nunito Sans",
                        letterSpacing: "0px",
                        color: "#212121",
                        borderRightWidth: 1,
                        borderColor: "#E8E8E8",
                        borderTop: "1px solid #E8E8E8",
                      }}
                    >
                      Action
                    </TableCell>
                  ) : null} */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {otaData && otaData.getOtaCampaign?.totalCount > 0 ? (
                            otaData.getOtaCampaign.data.map((row, index) => {
                              let createdDate = moment
                                .unix(row.createdOn / 1000)
                                .format("DD-MM-YYYY HH:mm:ss");
                              let fileDwldStart = row.fileDwldStart
                                ? moment
                                    .unix(row.fileDwldStart)
                                    .format("DD-MM-YYYY HH:mm:ss")
                                : null;
                              let fileDwldFail = row.fileDwldFail
                                ? moment
                                    .unix(row.fileDwldFail)
                                    .format("DD-MM-YYYY HH:mm:ss")
                                : null;
                              let fileDwldSuccess = row.fileDwldSuccess
                                ? moment
                                    .unix(row.fileDwldSuccess)
                                    .format("DD-MM-YYYY HH:mm:ss")
                                : null;
                              let flashStart = row.flashStart
                                ? moment
                                    .unix(row.flashStart)
                                    .format("DD-MM-YYYY HH:mm:ss")
                                : null;

                              let flashFail = row.flashFail
                                ? moment
                                    .unix(row.flashFail)
                                    .format("DD-MM-YYYY HH:mm:ss")
                                : null;

                              let flashSuccess = row.flashSuccess
                                ? moment
                                    .unix(row.flashSuccess)
                                    .format("DD-MM-YYYY HH:mm:ss")
                                : null;

                              return (
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {next + (index + 1)}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.campaignName}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {createdDate}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {" "}
                                    {/* <LinearProgress variant="buffer" /> */}
                                    <CProgress className="mb-3">
                                      <CProgressBar
                                        color="success"
                                        variant="striped"
                                        animated
                                        value={
                                          row.status == "FLASH_COMPLETED"
                                            ? 100
                                            : row.campaignProgress
                                            ? row.campaignProgress
                                            : 0
                                        }
                                      >
                                        {row.status == "FLASH_COMPLETED"
                                          ? 100
                                          : row.campaignProgress
                                          ? row.campaignProgress
                                          : 0}
                                        %
                                      </CProgressBar>
                                    </CProgress>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.serialNumber}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.ecuName}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {fileDwldStart}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {fileDwldFail}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {fileDwldSuccess}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {flashStart}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {flashFail}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {flashSuccess}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.status}
                                  </TableCell>
                                  {/* {row.status === "PENDING" && (
                          <TableCell
                            align="center"
                            style={{ backgroundColor: "#F5C84E" }}
                            className={classes.tableContent}
                          >
                            Pending for Approval
                          </TableCell>
                        )}
                        {row.status === "APPROVED" && (
                          <TableCell
                            align="center"
                            style={{ backgroundColor: "#80C8AD" }}
                            className={classes.tableContent}
                          >
                            Completed
                          </TableCell>
                        )}
                        {row.status === "FAILED" && (
                          <TableCell
                            align="center"
                            style={{ backgroundColor: "#FF7E80" }}
                            className={classes.tableContent}
                          >
                            Failed
                          </TableCell>
                        )}
                        {row.status === "INACTIVE" && (
                          <TableCell
                            align="center"
                            style={{ backgroundColor: "#FF7E80" }}
                            className={classes.tableContent}
                          >
                            Failed
                          </TableCell>
                        )}

                        {row.status === "INPROGRESS" && (
                          <TableCell
                            align="center"
                            style={{ backgroundColor: "#F5C84E" }}
                            className={classes.tableContent}
                          >
                            In progress
                          </TableCell>
                        )} */}
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    <Grid
                                      container
                                      alignContent="center"
                                      alignItems="center"
                                      style={{ height: "5px" }}
                                    >
                                      <Grid item style={{ margin: "auto" }}>
                                        <Tooltip title="Download">
                                          <Button
                                            onClick={() =>
                                              handleDownloadFlash(
                                                "fileDownload",
                                                row.fileName,
                                                row.serialNumber,
                                                row.ecuName
                                              )
                                            }
                                            disabled={row.status != "PENDING"}
                                          >
                                            <CloudDownloadIcon
                                              style={
                                                row.status != "PENDING"
                                                  ? { fill: "grey" }
                                                  : { fill: "#2b2924" }
                                              }
                                            />
                                          </Button>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    <Grid
                                      container
                                      alignContent="center"
                                      alignItems="center"
                                      style={{ height: "5px" }}
                                    >
                                      <Grid item style={{ margin: "auto" }}>
                                        <Tooltip title="Flash">
                                          <Button
                                            onClick={() =>
                                              handleDownloadFlash(
                                                "flashEcu",
                                                row.fileName,
                                                row.serialNumber,
                                                row.ecuName
                                              )
                                            }
                                            disabled={
                                              row.status !==
                                              "FILE_DWLD_COMPLETED"
                                            }
                                          >
                                            <OfflineBoltIcon
                                              style={
                                                row.status !==
                                                "FILE_DWLD_COMPLETED"
                                                  ? { fill: "grey" }
                                                  : {
                                                      fill: "red",
                                                    }
                                              }
                                            />
                                          </Button>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                  {/* <TableCell
                          align="center"
                          className={classes.tableContent}
                        >
                         
                          <Button>
                            <PrintIcon />
                            {deletePermission ? (
                              <>
                                <Button>
                                  <DeleteIcon />
                                </Button>
                              </>
                            ) : null}
                          </Button>
                        </TableCell> */}
                                </TableRow>
                              );
                            })
                          ) : (
                            <>No Records found!</>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      component="div"
                      count={
                        otaData
                          ? otaData.getOtaCampaign
                            ? otaData.getOtaCampaign.totalCount
                            : 0
                          : 0
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </React.Fragment>
                ) : (
                  <NoData />
                )}
              </React.Fragment>
            )}
          </Grid>
          {/* {snackOpen && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={snackOpen}
              autoHideDuration={6000}
              onClose={handleSnack}
              message="Invalid Otp"
              action={
                <React.Fragment>
                 
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleSnack}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          )} */}
          {/* {confirmSnackBar && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={confirmSnackBar}
              autoHideDuration={6000}
              onClose={handleConfirmSnack}
              message="Otp validation Done"
              action={
                <React.Fragment>
                 
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleConfirmSnack}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          )} */}
        </Grid>
      </Grid>
    </div>
  );
}

export default withSnackbar(ManagerTable);
