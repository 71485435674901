import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  title: {
    marginTop: "20px",
    marginBottom: "25px",
    paddingBottom: "25px",
  },
  textfield: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#5CB593",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5CB593",
      },
    },
  },
  buttonText: {
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  buttonOutlined: {
    width: "150px",
    height: "40px",
  },
  tableHead: {
    font: "normal normal 600 15px/20px Nunito Sans",
    letterSpacing: "0px",
    color: "#212121",
    borderRightWidth: 1,
    borderColor: "#E8E8E8",
    borderTop: "1px solid #E8E8E8",
  },
  tableContent: {
    // font: "normal normal normal 27px/38px Nunito Sans ",
    letterSpacing: "0px",

    borderColor: "#E8E8E8",
    borderRightWidth: 1,
    padding: "20px",
  },
}));
