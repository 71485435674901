import {
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import axios from "axios";
import {
  gql,
  useLazyQuery,
  useApolloClient,
  useMutation,
  useQuery,
} from "@apollo/client";
import { ColorButton, CustomButton } from "../../hoc/PaletteComponent";
import { AddCampaign, UploadFTPFile } from "./AddCampaign";
import { NoData, ValidateButton } from "../../hoc/CustomComponents";

import WifiTetheringSharpIcon from "@mui/icons-material/WifiTetheringSharp";

import moment from "moment";
const getFeatureAccess = gql`
  query($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const CREATE_CAMPAIGN = gql`
  mutation(
    $oemId: Int!
    $campaignName: String!
    $imei: String!
    $controller: String!
    $fileName: String!
  ) {
    createFotaCampaign(
      oemId: $oemId
      campaignName: $campaignName
      imei: $imei
      controller: $controller
      fileName: $fileName
    ) {
      code
      message
    }
  }
`;

const GET_ALL_CAMPAIGN = gql`
  query($oemId: Int!, $limit: Int!, $offset: Int!) {
    getAllFotaCampaign(oemId: $oemId, limit: $limit, offset: $offset) {
      code
      message
      totalCount
      rows {
        id
        campaignName
        imei
        controller
        fileName
        status
        createdOn
      }
    }
  }
`;

const GET_FTP_FILES = gql`
  query($controller: String!) {
    getFTPFiles(controller: $controller) {
      code
      message
      fileList
    }
  }
`;

const TRIGGER_FOTA = gql`
  mutation(
    $oem: Int
    $imei: String!
    $controller: String!
    $fileName: String!
  ) {
    triggerFotaCampaign(
      oem: $oem
      imei: $imei
      controller: $controller
      fileName: $fileName
    ) {
      code
      message
    }
  }
`;

const columns = [
  "Serial Number",
  "Campaign Name",
  "IMEI",
  "Controller",
  "Status",
  "CreatedOn",
];

const allController = ["WP", "NXP", "Renesas"];
const fileList = ["wefwe", "wefdew"];
function FotaManager(props) {
  const [readPermission, setReadPermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [openCampaign, setopenCampaign] = useState(false);
  const [campaignName, setCampaignName] = useState();
  const [imei, setImei] = useState();
  const [controller, setController] = useState();
  const [file, setFile] = useState();
  const [enableFileList, setenableFileList] = useState(true);
  const [openUpload, setOpenUpload] = useState(false);
  const [allCampaign, setAllCampaign] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [fileList, setFileList] = useState();
  const [upload, setUpload] = useState(false);
  const [uploading, setUploading] = useState(false);
  let [next, setNext] = useState(0);
  let id;
  console.log(props.featureId, "props.featureId ");
  if (typeof props.featureId != "undefined") {
    localStorage.setItem("featureId", props.featureId);
    id = props.featureId;
  } else if (localStorage.getItem("featureId")) {
    id = parseInt(localStorage.getItem("featureId"));
  } else {
    id = 13;
  }

  /**
   * @summary This handles the page change in pagination
   */
  const handleChangePage = async (event, newPage) => {
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    await getAllCamapaign({
      variables: { oemId: props.oem, limit: rowsPerPage, offset: newPage },
    });
  };

  /**
   * @summary This handles the row change in pagination
   */
  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    await getAllCamapaign({
      variables: { oemId: props.oem, limit: event.target.value, offset: page },
    });
  };

  /**
   * @summary This handles campaign name value
   */
  const handleCampaignName = (e) => {
    setCampaignName(e);
  };

  /**
   * @summary This handles Imei value
   */
  const handleIMEI = (e) => {
    setImei(e);
  };

  /**
   * @summary This handles Imei value
   */
  const handleContoller = (e, select) => {
    if (select) {
      setController(select);
      setenableFileList(false);
      getFTPfiles({
        variables: {
          controller: select,
        },
      });
    } else {
      setController(null);
      setenableFileList(true);
    }
  };

  /**
   * @summary This handles File value
   */
  const handleFile = (e, select) => {
    setFile(select);
  };

  /**
   * @summary This opens model to create campaign
   */
  const handleAddCampaign = () => {
    setopenCampaign(true);
  };

  /**
   * @summary This closes the create campaign model
   */
  const handleCampaignCancel = () => {
    setopenCampaign(false);
    setFile(null);
    setController(null);
    setImei(null);
    setCampaignName(null);
    setOpenUpload(false);
    setenableFileList(true);
  };

  /**
   * @summary This calls the api to create campaign
   */
  const addCampaign = () => {
    createCampaign({
      variables: {
        oemId: props.oem,
        campaignName: campaignName,
        imei: imei,
        controller: controller,
        fileName: file,
      },
    });
    console.log(campaignName, imei, controller, file, "values");
  };

  /**
   * @summary This opens the model to upload FTP files
   */
  const handleUploadFTPFile = () => {
    setOpenUpload(true);
  };

  /**
   * @summary This opens the model to upload FTP files
   */
  const handleUpload = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (file) {
      setFile(file);
    } else {
    }
  };

  /**
   * @summary This closes the snackbar
   */
  const handleSnackbarClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  const uploadFile = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    var FormData = require("form-data");
    var data = new FormData();
    setUploading(true);
    data.append("filedata", file, file.name);
    data.append("controller", controller);

    try {
      var config = {
        method: "post",
        url: process.env.REACT_APP_FTP_FILE_URL,
        data: data,
        headers: {
          Authorization: process.env.REACT_APP_ZELIOT_TOKEN,
        },
      };
      console.log(config, "config");
      let response = await axios(config);
      console.log(response.data);
      if (response.data?.code == 201) {
        setUpload(true);
        setUploading(false);
        props.setMessage(response.data?.remarks);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    } catch (err) {
      console.log(err, "errr");
      props.setMessage(err);
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
    }
  };

  /**
   * @summary This fetches the FTP files
   */
  const [getFTPfiles, { data: files, loading: uploadLoading }] = useLazyQuery(
    GET_FTP_FILES,
    {
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
      },
      onCompleted: async (res) => {
        console.log(res, "res");
        if (res.getFTPFiles.code == 200) {
          setFileList(res.getFTPFiles.fileList);
        }
      },
    }
  );

  /**
   * @summary This creates the FOTA campaign
   */
  const [createCampaign, { data: res }] = useMutation(CREATE_CAMPAIGN, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
    onCompleted: async (res) => {
      console.log(res, "res");
      if (res.createFotaCampaign.code) {
        props.setMessage(res.createFotaCampaign.message);
      } else {
        props.setMessage("Failed To Create");
      }
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleCampaignCancel();
      await getAllCamapaign({
        variables: {
          oemId: props.oem,
          limit: rowsPerPage,
          offset: page,
        },
      });
      handleSnackbarClose();
    },
  });

  /**
   * @summary handles the foota trigger
   */
  const handleTrigger = (row) => {
    triggerFota({
      variables: {
        oem: props.oem,
        imei: row.imei,
        controller: row.controller,
        fileName: row.fileName,
      },
    });
  };

  /**
   * @summary api call to trigger FOTA
   */
  const [triggerFota, { data: fota }] = useMutation(TRIGGER_FOTA, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
    onCompleted: async (res) => {
      console.log(res, "res");
      if (res.triggerFotaCampaign.message) {
        props.setMessage(res.triggerFotaCampaign.message);
      } else {
        props.setMessage("Failed To send Command");
      }
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
    },
  });
  /**
   * @summary This fetches all FOTA Campaign
   */
  const [
    getAllCamapaign,
    { loading: compaignLoading, data: campaign },
  ] = useLazyQuery(GET_ALL_CAMPAIGN, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
    onCompleted: async (res) => {
      console.log(res, "res");
      if (res.getAllFotaCampaign.code == 200) {
        setAllCampaign(res.getAllFotaCampaign.rows);
        setTotalCount(res.getAllFotaCampaign.totalCount);
      }
    },
  });

  /**
   * @summary This fetches the access of the user
   */
  const [getAccess, { loading, error, data }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });

  useEffect(() => {
    getAccess({
      variables: {
        featureId: id,
      },
    });
  }, []);

  useEffect(() => {
    getAllCamapaign({
      variables: {
        oemId: props.oem,
        limit: rowsPerPage,
        offset: page,
      },
    });
    //removed previous snackbar state
    props.setSnackbar(false);
  }, [props.oem]);
  const classes = useStyles();
  return (
    <React.Fragment>
      <AddCampaign
        open={openCampaign}
        handleCampaignName={handleCampaignName}
        handleIMEI={handleIMEI}
        handleContoller={handleContoller}
        handleFile={handleFile}
        fileList={fileList}
        allController={allController}
        addCampaign={addCampaign}
        handleCampaignCancel={handleCampaignCancel}
        controller={controller}
        enableFileList={enableFileList}
        campaignName={campaignName}
        imei={imei}
        file={file}
      />
      <UploadFTPFile
        open={openUpload}
        allController={allController}
        handleContoller={handleContoller}
        handleCampaignCancel={handleCampaignCancel}
        uploadFile={uploadFile}
        handleUpload={handleUpload}
        enableFileList={enableFileList}
        controller={controller}
        upload={upload}
        uploadLoading={uploading}
      />
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item>
              <Typography variant="h4">FOTA Manager</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                backgroundColor: "#FFFFFF",
                paddingTop: "30px",
                margin: "20px 0px 0px 10px",
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <TextField
                    id="search_imei"
                    placeholder="Search Imei Number"
                    variant="outlined"
                    style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                    size="small"
                    // onChange={(e) => handleSearchChange(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ paddingLeft: "3px" }} />
                        </InputAdornment>
                      ),
                    }}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={6}>
                  {createPermission ? (
                    <Grid
                      container
                      disaply="flex !important"
                      justify="flex-end !important"
                      spacing={3}
                    >
                      <Grid item>
                        <ColorButton onClick={handleAddCampaign}>
                          <Typography className={classes.buttonText}>
                            Add Campaign
                          </Typography>
                        </ColorButton>
                      </Grid>
                      <Grid item>
                        <ColorButton onClick={handleUploadFTPFile}>
                          <Typography className={classes.buttonText}>
                            Add File
                          </Typography>
                        </ColorButton>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 10px 0px 10px",
              }}
            >
              {totalCount > 0 ? (
                <React.Fragment>
                  <TableContainer
                    style={{
                      maxHeight: 440,
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              align="center"
                              style={{
                                minWidth: 200,
                                font: "normal normal 600 15px/20px Nunito Sans",
                                backgroundColor: "#faf8f7",
                              }}
                              className={classes.tableHead}
                            >
                              {column}
                            </TableCell>
                          ))}
                          {createPermission ? (
                            <TableCell
                              align="center"
                              style={{
                                minWidth: 200,
                                font: "normal normal 600 15px/20px Nunito Sans",
                              }}
                              className={classes.tableHead}
                            >
                              Action
                            </TableCell>
                          ) : null}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allCampaign.map((row, index) => {
                          let createdDate = moment
                            .unix(row.createdOn)
                            .format("DD-MM-YYYY");
                          return (
                            <TableRow>
                              <TableCell
                                align="center"
                                className={classes.tableContent}
                              >
                                {next + (index + 1)}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableContent}
                              >
                                {row.campaignName}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableContent}
                              >
                                {row.imei}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableContent}
                              >
                                {row.controller}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableContent}
                              >
                                {row.status}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableContent}
                              >
                                {createdDate}
                              </TableCell>
                              {createPermission ? (
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  <CustomButton
                                    onClick={() => handleTrigger(row)}
                                    variant="contained"
                                  >
                                    Trigger FOTA
                                  </CustomButton>
                                  {/* <IconButton>
                                    <WifiTetheringSharpIcon />
                                  </IconButton> */}
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={totalCount ? totalCount : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </React.Fragment>
              ) : (
                <NoData />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withSnackbar(FotaManager);
