import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  mapContainer: {
    height: "100%",
    margin: -theme.spacing(1) * 3,
  },
  button: {
    borderRadius: "20px !important",
    backgroundColor: "#E0FFF3",
    border: "1px solid #57B793",
    color: "#57B793",
    width: "50%",
  },
  tableContent: {
    font: "normal normal normal 27px/38px Nunito Sans ",
    letterSpacing: "0px",
    // color: "#212121",
    borderColor: "#E8E8E8",
    borderRightWidth: 1,
  },
  textfield: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#5CB593",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5CB593",
      },
    },
  },
  buttonStyle: {
    width: "180px",
    height: "48px",
  },
}));
